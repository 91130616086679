body.account.bexley-boutique {
    .bexley-header-wrapper,
    .page-footer,
    .page-main.banner-title,
    .account-nav-content .nav.item:not(.my_returns):not(.logout),
    .rma-reason:not(:has(input[id="first_reason"])),
    .aw-rma_field:not(:has(input[id="rma-option-1"])),
    .aw-rma-new__form .field.message,
    form[data-role="aw-rma-update-request-form"] > .rma-return-type-label,
    form[data-role="aw-rma-update-request-form"] > .actions {
        display: none !important;
    }

    .nav.item.my_returns a {
        pointer-events: none;
    }
}


.rma_boutique_message__container {
    margin-bottom: 15px;

    ul {
        list-style-type: disc;
        margin-left: 35px;
    }
}
