@charset "UTF-8";

/* ----------------------------------------------------------
  Oney popin
---------------------------------------------------------- */

.popin-open {
    cursor: pointer;
}

.oney-popin {
    .modal-inner-wrap {
        min-width: 900px;
        padding: 30px;
        border: 1px solid $color-oney;

        @include resp($mobile_only) {
            min-width: auto;
            padding: 20px;
        }
    }

    .modal-header {
        height: 0;

        .modal-title {
            @extend .visually-hidden;
        }

        // Close button
        .action-close {
            @include resp($mobile_only) {
                top: 10px;
                right: 10px;
            }
        }
    }

    .modal-content {
        padding: 20px 0 0;
        border: 0 none !important;
    }

    .modal-footer {
        display: none;
    }
}

/* Content
-------------------------- */

.oney-popin__content {
    margin-bottom: 30px;
}

.oney-popin__mentions {
    font-size: 12px;
    line-height: 1.2;

    a {
        font-weight: 600;
        color: $color-hl;
    }

    @include resp($mobile_only) {
        font-size: 11px;
    }
}