#stock-warehouse-modal {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(0,0,0,0.25);
}

.warehouse-modal-content {
    width: 50vw;
    height: 100vh;
    max-width: 960px;
    background: #fff;
    overflow: auto;

    header {
        background: $color-main;
        padding: 10px;
        position: relative;

        h3 {
            margin: 0;
            padding: 0;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
        }

        button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: transparent;
            border: none;
            color: #fff;
            font-weight: bold;
        }
    }

    .warehouse-modal-content-table {
        h3 {
            text-transform: uppercase;
            margin: 8px 10px 8px 25px;
        }

        h4 {
            padding-left: 25px;
        }

        hr {
            margin-top: 10px;
            border: none;
            height: 1px;
            background: #F6F6F6;
        }

        .warehouse-modal-bloc {
            background-color: #F6F6F6;
            background-position: 5px 5px;
            margin: 0 10px 4px 10px;
            padding: 10px;
        }

        .warehouse-modal-tarif {
            display: flex;
            gap: 4px;
            margin-bottom: 5px;
            margin-left: 26px;

            dt, dd {
                width: calc(50% - 4px);
            }
        }

        .warehouse-modal-mentions {
            font-size: 10px;
            margin: 15px 8px 8px 10px;
            color: #AFAFAF;
        }
    }
}

.warehouse-modal-tarif,
#stock-warehouse-container {
    dt {
        &:before {
            content: "\2022";
            color: $color-hl;
            font-size: 12px;
            margin-right: 2px;
            display: inline-block;
        }
    }
}


#stock-warehouse-container {
    background: #F6F6F6;
    padding: 10px;
    margin-top: 20px;
    position: relative;

    &.is-loading {
        display: block !important;

        &:before {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background: url("../images/loader.gif") 50% 50% no-repeat;
            background-color: rgba(255,255,255,.75);
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        background-position: left 2px;
        padding: 5px 0 5px 35px;

        &.ico-domicile {
            flex-direction: column;

            dl {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .is-unavialable {
        opacity: 0.5;
    }
}

#stock-warehouse-container li,
.warehouse-modal-bloc {
    background-repeat: no-repeat;
    background-size: 24px 24px;

    &.ico-boutique {
        background-image  : url("../images/clickandcollect/picto-store.svg");
    }

    &.ico-relais {
        background-image  : url("../images/clickandcollect/picto-box.svg");

        &.is-unavialable {
            background-image  : url("../images/clickandcollect/picto-box_OFF.svg");
        }
    }

    &.ico-domicile {
        background-image  : url("../images/clickandcollect/picto-home.svg");

        &.is-unavialable {
            background-image  : url("../images/clickandcollect/picto-home_OFF.svg");
        }
    }

    &.ico-world {
        background-image  : url("../images/clickandcollect/picto-world.svg");

        &.is-unavialable {
            background-image  : url("../images/clickandcollect/picto-world_OFF.svg");
        }
    }
}


.warehouse-modal-trigger {
    color: $color-hl;
    text-decoration: underline;
    display: block;
    background: url("../images/clickandcollect/picto-chevron.svg") right center no-repeat;
    background-size: 24px 24px;
}

.click_and_collect_mobile_swich {
    display: none;
}


@media($has_mobile_menu) {
    #stock-warehouse-modal {
        top: 0;
        height: 100vh;
        padding-top: 25px;
        background: rgba(255,255,255,0.5);
    }

    .warehouse-modal-content {
        width: 100vw;
    }

    .warehouse-modal-content {
        height: calc(100vh - 25px);

        .warehouse-modal-content-table {
            .warehouse-modal-tarif {
                margin-left: 5px;
                justify-content: space-between;

                dd, dt {
                    width: auto;
                }

                dt {
                    max-width: 75%;
                }

                &.is-mobile-columns {
                    flex-direction: column;
                    dd, dt {
                        width: 100%;
                    }
                }
            }
        }
    }

    #stock-warehouse-container {
        border: 1px solid $color-main;
        background: #fff;
    }

    .click_and_collect_mobile_swich {
        display: flex;
        flex-direction: column-reverse;
    }

    [data-js-selector="warehouse-aviability"] {
        font-size: 10px;
        font-weight: bold;
    }
}
