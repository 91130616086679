@charset "UTF-8";

/* ----------------------------------------------------------
  Slick
---------------------------------------------------------- */

/* Slider */

.slick-slider {
    display: block;
    position: relative;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.slick-track {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

.slick-track:before,
.slick-track:after {
    content: '';
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
    width: auto;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Theme
-------------------------- */

.slick-dots {
    display: block;
    position: absolute;
    bottom: -25px;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
}

.slick-dots li {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 0;
    font-size: 0;
    line-height: 0;
    outline: none;
    color: transparent;
    background: transparent;
    cursor: pointer;
}

.slick-dots li button:hover {
    outline: none;
}

.slick-dots li button:before {
    content: '•';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 30px;
    line-height: 20px;
    color: $color-hl;
    opacity: .25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    color: $color-hl;
    opacity: .75;
}

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.fakeselect-wrapper {
    z-index: 1;
    position: relative;
}

.fakeselect-wrapper[aria-expanded="true"] {
    z-index: 2;
}

.fakeselect-wrapper select {
    z-index: 1;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

/* Cover
-------------------------- */

.fakeselect-cover {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    text-align: left;
    text-decoration: none;
    font: inherit;
    color: inherit;
    background: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

/* List
-------------------------- */

.fakeselect-list {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

[aria-expanded="true"] .fakeselect-list {
    visibility: visible;
    opacity: 0.999;
}

/* Items
-------------------------- */

.fakeselect-list li[data-visible="0"] {
    display: none;
}

/* Noresults
-------------------------- */

.fakeselect-noresults {
    display: none;
    background: transparent;
}

.fakeselect-noresults[data-visible="1"] {
    display: block;
}

/* ----------------------------------------------------------
  Cloudzoom
---------------------------------------------------------- */

/* Hide license notice to avoid visual bugs on preproduction (cloudzoom has been paid for bexley.fr & bexley.com) */

.cloudzoom-blank > div:not([class]) {
    display: none!important;
}
