@charset "UTF-8";

/* ----------------------------------------------------------
  Marketing
---------------------------------------------------------- */

img.picto-produit {
    width       : 70px;
    height      : 80px;
    position    : absolute;
    top         : 20%;
    right       : 12.5%;
    z-index     : 2;
}
@media($mobile_only) {
    img.picto-produit {
        width       : 50px;
        height      : 60px;
    }
}
.pictos-marketing {
    position        : absolute;
    z-index         : 2;
    left            : 2px;
    right           : 2px;
    bottom          : 2px;
    pointer-events  : none;
    display         : flex;
    justify-content : flex-start;
    flex-wrap       : nowrap;

    strong {
        margin-left: 5px;
    }
}

.picto-marketing {
    text-transform   : uppercase;
    line-height      : 1.6;
    margin-right     : 10px;
    border           : 1px solid;
    background-color : #FFF;
    padding          : 0;
    font-size        : 13px;
    text-align       : center;
    min-width        : 150px;
    font-weight      : 500;
    height           : 18px;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    box-sizing       : border-box;

    &--new {
        background-color    : $color-hl;
        border-color        : $color-hl;
        color               : #fff;
    }


    &--best {
        color: $color-main;
    }

    &--comming-soon {
        color: $color-hl;
    }

    &--exclusive {
        background-color    : $color-main;
        border-color        : $color-main;
        color               : #fff;
    }
}

// Catégories
:root body.catalog-category-view .page-wrapper #maincontent .product-pictos-image-container {
    margin-bottom : 25px;

    .pictos-marketing {
        bottom : -17px
    }
}

@media($mobile_only) {
    :root body.catalog-category-view .page-wrapper #maincontent .picto-marketing {
        height    : 15px;
        max-width : 120px;
        min-width : 115px;
    }
}

// Fiche produit
.catalog-product-view .product.media {
    .product-pictos-image-container {
        position: relative;
    }
}

@media($mobile_only) {
    .catalog-product-view .page-wrapper #maincontent .product.media {
        .product-pictos-image-container {
            .pictos-marketing {
                bottom : 32px;

                .picto-marketing {
                    font-size : 12px;
                    width     : 135px;
                    height    : 18px;
                    max-width : 135px;
                }
            }

            img.picto-produit {
                pointer-events: none;
            }
        }
    }
}

@media screen and (max-width : 500px)  {
    .catalog-product-view .page-wrapper #maincontent .product.media .product-pictos-image-container img.picto-produit {
        right: 12%;
        top : 11.5%;
    }
}

@media screen and (min-width : 769px) and (max-width : 1025px) {
    .catalog-product-view .product.media {
        .product-pictos-image-container {
            .pictos-marketing {
                bottom: 82px;
            }
        }
    }
}

@media screen and (min-width : 1025px) {
    .catalog-product-view .product.media {
        .product-pictos-image-container {
            .pictos-marketing {
                left: 102px;
            }
        }
    }
}

@media screen and (min-width : 1440px) {
    .catalog-product-view .product.info.detailed {
       padding-top: 1px;
    }
}

// Générique
@media($mobile_only) {
    :root body .page-wrapper #maincontent .picto-marketing {
        font-size   : 9px;
        width       : auto;
        line-height : 1.2;
        height      : 15px;
        max-width   : 120px;
        min-width   : 115px;
    }
}

/* ----------------------------------------------------------
  Batches
---------------------------------------------------------- */

.batch-sale-price,
.batch-sale {
    [style="color:red"] {
        color: $color-red!important;
    }
}

.batch-buy {
    & {
        margin-bottom: $mobile-padding;
    }

    .batch-title {
        margin-bottom: 1em;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
    }

    .price-container {
        font-weight: 400;
        color: $color-red;
    }
}

@media($mobile_only) {
    .batch-buy .batch-title {
        font-size: 12px;
    }
}

/* ----------------------------------------------------------
  Item
---------------------------------------------------------- */

.product-item-content,
.product-items .product-item {
    & {
        text-align: left;
    }

    .product-item-info {
        z-index: 1;
        position: relative;
    }

    .product-pictos-image-container {
        position: relative;
        display: block;
        margin-bottom: $mobile-padding;
    }

    .pictos-marketing {
        margin          : auto;

        .picto-marketing {
            width: 135px;
            font-size: 12px;
        }
    }

    .product-selection-item {
        display: block;
        margin-bottom: $mobile-padding;
    }

    .product-item-photo img {
        width: 100%;
    }

    .product-item-photo {
        display: block;
        z-index: 1;
        position: relative;
        padding: 2px;
    }

    .product-item-photo [class*="bexley-button"] {
        visibility: hidden;
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%!important;
        height: 40px;
        margin: auto;
        padding-right: 0;
        padding-left: 0;
        line-height: 40px;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease,visibility 0.3s ease;
        transition: opacity 0.3s ease,visibility 0.3s ease;
    }

    .product-item-name {
        &{
            display: block;
            margin-bottom: 1em;
            min-height: 3.4em;
            font: inherit;
            font-size: 1.1em;
            font-weight: 300;
            line-height: 1.7;
            letter-spacing: 0;
        }
        & .product-name {
            display: inline;
            text-transform: uppercase;
            font-weight: 500;
            font-size: inherit;

        }
    }

    .batch-sale-price span,
    .product.batch-sale span,
    .price-box {
        font-size: 1.4em;
        font-weight: normal;
        line-height: $line-height;
    }

    .price-box:after {
        @extend .clearfix;
    }

    .price-box .new-price {
        float: left;
    }

    .product-item-inner {
        display: none;
    }

    .product-colors--wrap {
        z-index: 1;
        position: relative;
        font-size: $font-size;
    }

    .product-colors {
        display: block;
        z-index: 1;
        position: relative;
        margin-bottom: 1em;
        padding: 1px;
        overflow: hidden;
        line-height: 1;
        white-space: nowrap;
    }

    .product-colors a {
        display: inline-block;
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-right: 2%;
        margin-bottom: 2%;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: top;
        outline: 1px solid #e0e0e0;
        background-color: #F9F9F9;
    }

    .product-colors a.current:before {
        @extend %absolute-cover;
        content: '';
        z-index: 2;
        border: 2px solid #e0e0e0;
    }

    .product-colors button span,
    .product-colors a span {
        display: block;
        z-index: 1;
        position: relative;
        height: 0;
        padding-top: 100%;
        background-color: inherit;
    }

    .product-colors button {
        @extend %cssc-button;
        z-index: 1;
        position: absolute;
        top: 1px;
        right: 1px;
        border: 1px solid #FFF;
        overflow: hidden;
        background-color: #FFF;
    }

    .product-colors button span {
        box-shadow: inset 0 0 0 1px $color-border;
    }

    .product-colors button span:before,
    .product-colors button span:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 60%;
        margin: auto;
        background-color: #ccc;
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    .product-colors button span:before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .product-colors button,
    .product-colors a {
        width: 4.8%;
    }

    .product-colors a.product-colors-product-link {
        width       : auto;
        display     : inline;
        background  : transparent;
        outline     : none;

        span {
            display     : inline;
            position    : static;
            padding     : 0;
        }
    }

    // Payment info
    .product-info-payment {
        margin-left: 4px;

        img {
            height: 24px;
        }
    }

    .product.batch-sale {
        font-weight: 400;
        letter-spacing: 0;
    }
}

@media($mobile_only) {
    .product-items .product-item .product-item-photo [class*="bexley-button"],
    .product-items .product-item:not(.product-type-look) .product-item-name .desktop {
        display: none;
    }

    .product-items .product-item {
        font-size: 10px;
    }

    .product-item-content,
    .product-items .product-item {
        .pictos-marketing {
            .picto-marketing {
                font-size: 9px;
            }
        }
    }
}

/* ----------------------------------------------------------
  Colors
---------------------------------------------------------- */

.product-colors-toggle {
    & {
        z-index: 1;
        position: relative;
    }

    &.product-colors--displayall {
        white-space: normal!important;
    }

    &[data-over15="1"].product-colors--displayall button,
    button {
        display: none!important;
    }

    &[data-over15="1"] button {
        display: block!important;
    }
}

.modal-popup {
    &.modal__size_guide {
        .modal-footer {
            text-align: center;
        }
    }
}
