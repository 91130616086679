@charset "UTF-8";

$stock-modal-max-width:         1110px;

$stores-map-sidebar-width:      38%;
$stores-map-sidebar-margin:     5%;

@mixin show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

@mixin hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

/* ----------------------------------------------------------
  Open modal button
---------------------------------------------------------- */

.stock-by-shop-checkinventory {
    margin-top: 20px;
}

.check-inventory-action {
    cursor: pointer;

    &::before {
        content: url("../images/icon/store.svg");
        position: relative;
        top: 2px;
        vertical-align: middle;
        margin-right: 4px;
    }

    span {
        color: $color-hl;
        text-decoration: underline;
        font-weight: 500;
    }
}

/* ----------------------------------------------------------
  Modal
---------------------------------------------------------- */

.modal-stock-by-shop {

    .modal-inner-wrap {
        width: 90vw;
        max-width: $stock-modal-max-width;
    }

    .modal-header {
        height: 4.8em;
        line-height: 4.8em;
        padding-right: 60px;
        padding-left: 60px;
        text-align: center;
        background-color: $color-main;

        @include resp($desktop_excluded){
            height: 4em;
            line-height: 4em;
        }

        .modal-title {
            font-family: $font-main;
            font-weight: bold;
            color: white;
            font-size: 18px;
            text-transform: uppercase;

            @include resp($desktop_excluded){
                font-size: 14px;
            }

            @include resp($tiny_only){
                font-size: 12px;
            }
        }

        .action-close {
            height: auto;
            font-size: 5px;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);

            &, &:hover {
                &::before {
                    font-size: 26px;
                    opacity: 1;
                    color: white;

                    @include resp($desktop_excluded){
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .modal-content {
        padding: 30px 40px 40px;

        @include resp($desktop_excluded){
            padding: 0 20px 20px;
        }
    }
}

/* ----------------------------------------------------------
  Modal main content
---------------------------------------------------------- */

.stock-by-shop {
    min-height: 70vh;

    @include resp($mobile_only){
        min-height: 75vh;
    }
}

.stock-by-shop__product {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-border;

    // Description
    .product-image {
        max-width: 220px;

        @include resp($desktop_excluded){
            width: 30%;
        }
    }

    // Description
    .product-info-main {
        float: none;
        width: 100%;
        flex: 1;
        padding: 0 0 0 15px !important;

        .banner-title {
            @include resp($mobile_only){
                font-size: 14px;
            }
        }

        .product-info-price {
            .price-box {
                @include resp($mobile_only){
                    font-size: 18px;
                    margin-bottom: 0;
                }
            }
        }

        .product-info-payment {
            img {
                @include resp($mobile_only){
                    height: 22px;
                }
            }
        }

        .product {
            &.batch-sale {
                @include resp($mobile_only){
                    display: none;
                }
            }
        }
    }
}

.stock-by-shop__step {
    display: none;
}

/* ----------------------------------------------------------
  Step 1 - Options form
---------------------------------------------------------- */

.stock-by-shop-form {
    margin-top: 30px;

    .label-like {
        font-size: 14px;
        margin-bottom: 10px;
    }

    // Fake radio button
    input[type="checkbox"] {
        @extend .visually-hidden;
        transition: none !important;

        & + label {
            min-width: 50px;
            padding: 10px;
            border: 2px solid $color-border;
            border-radius: 20px;
            margin: 2% 1%;
            text-align: center;
            cursor: pointer;

            @include resp($mobile_only){
                min-width: 40px;
                padding: 5px;
            }

            @include resp($tiny_only){
                width: 17%;
                min-width: 0;
            }
        }

        &:checked {
            & + label {
                border-color: $color-hl;
            }
        }
    }
}

.stock-by-shop-form__entry {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1%;
    margin-right: 1%;
    margin-bottom: 20px;

    @include resp($tiny_only){
        margin-bottom: 15px;
    }
}

.stock-by-shop-form__actions {
    text-align: center;

    > input {
        @include resp($tiny_only){
            width: 100%;
        }

        &:disabled {
            cursor: default;
            border: 1px solid #ccc;
            color: #333;
            opacity: 0.66;
            background: #e0e0e0;
        }
    }
}

/* ----------------------------------------------------------
  Step 2 - Stores map
---------------------------------------------------------- */

.stock-by-shop-sizes {
    display: flex;
    padding: 16px 0;
    border-bottom: 1px solid $color-border;
    margin-bottom: 30px;
}

.stock-by-shop-sizes__values {
    font-weight: 500;
    color: $color-hl;
    margin-left: 10px;
}

.stock-by-shop-sizes__action {
    margin-left: auto;
    text-decoration: underline;
}

// Map
#stores-map {
    position: relative;
    height: 60vh;

    @include resp($desktop_excluded){
        height: 76vh;
    }
}

#stock-by-shop-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 40px;
    left: 42%;
    width: 57%;

    @include resp($desktop_excluded){
        width: 100%;
        top: 160px;
        left: 0;
        bottom: 0;
        @include hide;

        &.show {
            @include show;
        }
    }
}

// Search
.stores-map-form {
    .label-like {
        @extend %project--label;
        font-weight: 500;
    }

    .control {
        display: flex;

        input {
            flex: 1;
        }
    }
}

.stores-map__search {
    width: $stores-map-sidebar-width;

    @include resp($desktop_excluded){
        width: 100%;
        margin-bottom: 30px;
    }
}

.stores-map-form {
    position: relative;
}

.stores-map-form__input {
    @extend %project--field;
    text-transform: none;
    font-style: italic;
    min-width: 0;
}

.stores-map-form__error {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    @extend %integento-field-error !optional;
}

#stores-map {
    .stores-map-form__submit {
        width: auto;
        min-width: auto;
        background-color: $color-main;
        line-height: 3.5;
    }
}

// Navigation (only on mobile/tablets)
.stores-map__navigation {
    display: none;

    @include resp($desktop_excluded){
        display: block;
    }

    .stores-map__tabs {
        display: flex;

        li {
            flex: 1;

            > a {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                padding: 6px 10px;
                background-color: $color-bg;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
            }

            &.active {
                > a {
                    color: white;
                    background-color: $color-hl;
                }
            }
        }
    }
}

// Sidebar
.stores-map__sidebar {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    width: $stores-map-sidebar-width;

    @include resp($desktop_excluded){
        width: 100%;
        top: 150px;
        @include hide;

        &.show {
            @include show;
        }
    }
}

.stores-map__listing {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
}

.stores-map-item {
    position: relative;
    padding: 20px 20px 20px 30px;
    cursor: pointer;

    & + .stores-map-item {
        border-top: 1px solid #dadada;
    }
}

.stores-map-item__mentions {
    text-align: right;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 6px;
}

.stores-map-item__title {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    &::before {
        content: url("../Bexley_StockByShop/images/store-pin.svg");
        position: absolute;
        left: -30px;
        top: 0;
    }
}

.stores-map-item__details {
    margin-bottom: 14px;
}

.stores-map-item__sizes {
    margin-bottom: 14px;
    font-weight: 500;

    &.available {
        color: $color-hl;
    }

    &.unavailable {
        color: $color-red;
    }
}

.stores-map-item__distance {
    position: absolute;
    top: 20px;
    left: 0;
    font-weight: 700;
    color: #b49770;
}

.stores-map-item__opening {
    display: flex;
    flex-wrap: wrap;

    .opening-title {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 0;
        cursor: pointer;

        // Toggle arrow
        &::after {
            position: relative;
            top: -2px;
            @extend .icon;
            content:"\f106";
            margin-left: 12px;
            transform: scaleY(1);
            transition: transform .2s;
        }
    }

    .hours {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height .2s;

        p {
            font-size: 12px;
            margin-top: 10px;
        }
    }

    &.active {
        .opening-title {
            color: $color-hl;
            font-weight: 500;

            &::after {
                top: -1px;
                transform: scaleY(-1);
                transition: transform .2s;
            }
        }

        .hours {
            max-height: 100px;
            transition: max-height .2s;
        }
    }
}

.stores-map-item__link {
    display: inline-block;
    text-decoration: underline;
    font-weight: 500;
    margin-left: auto;
    cursor: pointer;
}

// Popup
#stock-by-shop-map {
    .mapboxgl-popup {
        top: 25px;
    }

    .mapboxgl-popup-content {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
        border-top: 5px solid $color-hl;
        border-radius: 0;
    }

    // Arrow
    .mapboxgl-popup-tip {
        border-bottom-color: $color-hl;
    }

    // Close button
    .mapboxgl-popup-close-button {
        top: 1px;
        right: 2px;
        line-height: 1;
        font-size: 25px;
    }
}

.map-popup__title,
.map-popup__details {
    font-size: 12px;
    line-height: 1.4;
}

.map-popup__title {
    margin-bottom: 0;
    padding-right: 20px;
}

.map-popup__sizes {
    color: $color-hl;
    font-weight: 500;

    &.available {
        color: $color-hl;
    }

    &.unavailable {
        color: $color-red;
    }
}

// Désactivation de la dispo dans le panier / tunnel
.checkout-index-index,
.checkout-cart-index {
    .map-popup__sizes {
        display: none !important;
    }
}

// Loader
.stock-by-shop-loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 10;

    img {
        padding: 30px;
    }
}
