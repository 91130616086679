@charset "UTF-8";

/* ----------------------------------------------------------
  Mirasvit Search Autocomplete
---------------------------------------------------------- */

$autocomplete-container-desktop-width: 500px;
$autocomplete-container-min-height: 52px;

// Result
.mst-searchautocomplete__autocomplete {
    display: none;
    width: $autocomplete-container-desktop-width;
    min-height: $autocomplete-container-min-height;
    margin-top: -1px;
    background-color: white;
    text-align: left;

    &._active {
        display: block;
        border: 1px solid $color-main;
    }

    // Spinner
    .mst-searchautocomplete__spinner {
        display: none;
        position: relative;
        width: 100%;
        height: $autocomplete-container-min-height;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &._loading {
        .mst-searchautocomplete__spinner {
            display: block;
        }
    }

    // Empty result
    .mst-searchautocomplete__empty-result {
        display: block;
        padding: 1.2rem .77rem;
    }

    // Title
    .mst-searchautocomplete__index-title {
        padding: .77rem 0;
        margin: 0 .77rem;
        font-weight: bolder;
    }
    // Item
    .mst-searchautocomplete__item {

        &.magento_catalog_product {
            display: flex;
            align-items: center;
            padding: .77rem 0;
            margin: 0 .77rem;
            border-bottom: 1px solid $color-border;

            &:last-child {
                border-bottom: 0;
            }

            img {
                width: 5rem;
                margin-right: .77rem;
            }

            .meta {
                flex-grow: 1;
                max-width: 190px;
                overflow: hidden;
            }

            .store {
                margin-left: auto;
            }

            .price-box {
                font-size: 1rem;
                font-weight: bolder;
                text-align: right;

                .sales-label {
                    display: block;
                    margin: 2px 0 0;
                    font-size: .8rem;
                    line-height: 1.2;
                }
            }
        }
    }

    // Category product
    .mst-searchautocomplete__index {

        &.magento_catalog_categoryproduct {

            ul {
                padding: .77rem 0;
                margin: 0 .77rem;
            }
        }
    }

    // Highlight
    .mst-searchautocomplete__highlight {
        color: $color-hl-light;
    }

    // Show all
    .mst-searchautocomplete__show-all {
        padding: .77rem;
        border-top: 1px solid $color-main;
        text-align: right;
    }
}

@media($mobile_only) {

    .mst-searchautocomplete__autocomplete {

        &._active {
            width: calc(100vw - 25px) !important;
            max-height: calc(100vh - 115px);
            overflow-y: auto;
        }

        // Title
        .mst-searchautocomplete__index-title {
            font-size: .7rem;
        }

        // Item
        .mst-searchautocomplete__item {

            &.magento_catalog_product {

                img {
                    width: 3rem;
                    margin-right: .5rem;
                }

                .meta {
                    max-width: 130px;
                    font-size: .7rem;
                }

                .price-box {
                    font-size: .75rem;

                    .sales-label {
                        width: 80px;
                        font-size: .57rem;
                    }
                }
            }
        }
    }
}
