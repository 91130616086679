@charset "UTF-8";

/* ----------------------------------------------------------
  Reset
---------------------------------------------------------- */

.checkout-onepage-success {
    .block-order-details-view,
    .order-details-items {
        display: none;
    }
}

.checkout-onepage-success .banner-title-wrapper {
    background: transparent url(../images/bg-success.jpg) no-repeat center center;
    background-size: cover;
}

.checkout-onepage-success .checkout-success {
    & {
        margin: auto;
        text-align: center;
    }

    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
    }

    p strong,
    p a {
        font-weight: 500;
        color: $color-hl;
    }
}
