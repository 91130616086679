@charset "UTF-8";

.bx-parsed-marketing-condition {
    p {
        font-size: 13px;
        line-height: inherit;
        letter-spacing: .08em;
        strong {
            font-size: 18px;
            font-weight: 400;
            color: $color-red;
        }
    }
}

@media($mobile_only) {

    .block.upsell,
    .block.related {
        .bx-parsed-marketing-condition {
            p {
                font-size: 8px;

                strong {
                    font-size: 10px;
                }
            }
        }
    }

    .bx-parsed-marketing-condition {
        p {
            font-size: 10px;
        }
    }
}