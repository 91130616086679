@charset "UTF-8";

$newsletter-block-width: 430px;

/* ----------------------------------------------------------
  Reinsurance
---------------------------------------------------------- */

.footer-top-reinsurance {
    & {
        @extend %responsive-centered-container;
        @extend %responsive-padding-tb;
        border-top: 1px solid #E6E6E6;
        background-color: #fff;
    }

    .footer & {
        @extend %responsive-margin-top;
    }

    .footer-top-reinsurance__inner {
        @extend %responsive-centered-container-child;
        z-index: 1;
        position: relative;
    }

    ul {
        @extend %justify-items;
        text-align: center;
    }

    li {
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.12em;
        vertical-align: top;
    }

    li img {
        display: block;
        margin: auto auto 2.5em;
    }

    li strong {
        display: block;
    }
}

@media($desktop_excluded) {
    .footer-top-reinsurance ul {
        display: block;
    }

    .footer-top-reinsurance li {
        width: 32%;
        margin-bottom: 3em;
    }

    .footer-top-reinsurance br {
        display: none;
    }

    .footer-top-reinsurance li:nth-last-child(-n+3) {
        margin-bottom: 0;
    }
}

@media($tiny_only) {
    .footer-top-reinsurance img {
        height: 30px;
    }

    .footer-top-reinsurance li {
        width: 45%;
        margin-bottom: 2em;
    }

    .footer-top-reinsurance li strong {
        font-size: 10px;
        line-height: 1.5;
    }

    .footer-top-reinsurance li:nth-last-child(-n+2) {
        margin-bottom: 0;
    }
}

/* ----------------------------------------------------------
  Footer main
---------------------------------------------------------- */

.footer-main-wrapper {
    & {
        @extend %responsive-centered-container;
        @extend %responsive-padding-tb;
        color: #fff;
        background-color: $color-main;
    }

    .footer-main {
        @extend %responsive-centered-container-child;
        z-index: 1;
        position: relative;
    }
}

/* Logo
-------------------------- */

.footer-logo {
    display: flex;
    align-items: center;

    @include resp($desktop_only) {
        padding-right: $newsletter-block-width + 20px;
    }

    & {
        height: 82px;
    }

    @include resp($desktop_excluded) {
        margin-bottom: $tablet-padding;
        text-align: center;
    }

    @include resp($mobile_only) {
        display: none;
    }
}

.footer-logo__payment {
    position: relative;
    bottom: -4px;
    max-width: 433px;

    @include resp($desktop_only) {
        bottom: -16px;
        width: 50%;
    }
}

/* Newsletter
-------------------------- */

.footer-main .block.newsletter {
    & {
        margin-bottom: 1em;
        text-align: center;
    }

    .title {
        margin-bottom: 1em;
        text-align: left;
        text-transform: uppercase;
        font-family: $font-second;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.2em;
    }

    .content {
        z-index: 1;
        position: relative;
        text-align: left;
    }

    .content input,
    .content button {
        height: 4em;
        font-size: $font-size;
        line-height: 4;
    }

    .content input {
        padding-right: 12em;
        border-color: $color-hl;
        color: #fff;
        background-color: transparent;
    }

    .content button {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 2em;
        min-width: 1em;
    }

    .label {
        display: none;
    }

    .message.message {
        padding: 0;
        border: 0;
        font-size: 0.9em;
        font-style: italic;
    }

    @include resp($desktop_only) {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: $newsletter-block-width;
        max-width: 100%;
    }
}

@media ($mobile_only) {
    .footer-main .block.newsletter .title {
        text-align: center;
    }

    .footer-main .block.newsletter .content input,
    .footer-main .block.newsletter .content button {
        height: 39px;
        font-size: 12px;
        line-height: 3;
    }

    .footer-main .block.newsletter .content button {
        padding: 0 1em;
    }
}

/* Links
-------------------------- */

.footer-links {
    & {
        margin-top: $desktop-padding;
        padding-right: 200px;
        line-height: 2.5;
    }

    a {
        display: inline-block;
        margin-right: $desktop-padding;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        vertical-align: top;
    }

    a:not(:hover):not(:focus) {
        color: inherit;
    }

    & > li {
        display: none;
    }

    @include resp($desktop_excluded) {
        padding-right: 0;
        text-align: center;
    }
}

@media($mobile_only) {
    .footer-links a {
        display: block;
        margin: 0;
        padding: 0 0 1em;
        text-align: center;
    }
}

/* Social
-------------------------- */

.footer-social {
    & {
        margin-top: 1em;
        text-align: center;
    }

    li {
        display: inline-block;
        font-size: 20px;
        vertical-align: top;
    }

    li + li {
        margin-left: 1em;
    }

    a {
        color: $color-hl;
    }

    a:hover,
    a:focus {
        color: #FFF;
    }

    @include resp($desktop_only) {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        text-align: right;
    }
}

/* ----------------------------------------------------------
  Footer - Bottom
---------------------------------------------------------- */

.footer-bottom {
    & {
        @extend %responsive-padding-tb;
        opacity: 0.50;
    }

    a:first-child {
        padding-left: 0;
    }

    a {
        display: inline-block;
        padding: 0 0.5em;
        font-size: 12px;
        font-weight: bold;
        vertical-align: top;
    }
}

@media ($mobile_only) {
    .footer-bottom {
        font-size: 0;
    }

    .footer-bottom a {
        display: block;
        padding: 0.5em;
        text-align: center;
        font-size: 12px;
    }
}

/* ----------------------------------------------------------
  Footer toggle
---------------------------------------------------------- */

.footer-toggle-button {
    & {
        display: block;
        width: 100%;
        height: 60px;
        text-transform: uppercase;
        font: inherit;
        font-size: 12px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0.1em;
        color: #fff;
        background-color: #002316;
    }

    span {
        display: inline-block;
        z-index: 1;
        position: relative;
        top: 0.1em;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 1em;
        height: 1em;
        margin-right: 0.3em;
        border-radius: 99em;
        font-size: 2em;
        font-weight: 300;
        line-height: 0.95;
        background-color: $color-hl;
    }

    @include resp($mobile_excluded) {
        display: none;
    }
}

@media ($mobile_only) {
    .footer-mobile-deployed .footer-toggle-button,
    body:not(.footer-mobile-deployed) .footer-main .footer-links,
    body:not(.footer-mobile-deployed) .footer-bottom-wrapper {
        display: none!important;
    }
}

/* ----------------------------------------------------------
  Tarte au citron manager
---------------------------------------------------------- */

@media($tiny_only) {
    #tarteaucitronAlertSmall {
        display: none!important;
    }
}
