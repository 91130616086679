@charset "UTF-8";

$mobile-max: 767px !default;
$mobile_only: "max-width: "+$mobile-max !default;
/* ----------------------------------------------------------
  Responsive Tables
---------------------------------------------------------- */

@media($mobile_only) {
    %responsive-table td,
    %responsive-table tr,
    %responsive-table tbody,
    %responsive-table {
        display: block;
        border: 0;
    }

    %responsive-table td {
        padding: 0;
        border: 0;
    }

    %responsive-table tfoot,
    %responsive-table thead {
        display: none;
    }

    %responsive-table tr {
        padding: 1em 0;
    }

    %responsive-table td {
        margin-bottom: 0.3em;
        padding: 0;
        border: 0!important;
        text-align: left!important;
    }

    %responsive-table td:before {
        content: attr(data-th);
        display: block;
        font-weight: bold;
    }
}
