@charset "UTF-8";

/* ----------------------------------------------------------
  Groups
---------------------------------------------------------- */

.faq-groups {
    & > * + * {
        margin-top: $desktop-padding;
        padding-top: $desktop-padding;
        border-top: 1px solid $color-border;
    }
}

.faq-group__title {
    @extend %project--h2;
    margin-bottom: $desktop-padding;
}

/* ----------------------------------------------------------
  Questions
---------------------------------------------------------- */

.question-item {
    & {
        z-index: 1;
        position: relative;
    }

    & + & {
        margin-top: $mobile-padding/2;
    }

    input[type="checkbox"] {
        z-index: 1;
        position: absolute;
        top: 0;
        left: -999px;
    }

    .question-item__content {
        padding-top: 0;
        padding-bottom: 0;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    input[type="checkbox"]:checked ~ .question-item__content {
        padding-top: 1em;
        padding-bottom: 1em;
        max-height: 2000px;
        opacity: 0.999;
    }

    input[type="checkbox"]:checked ~ .question-item__title label:before {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

.question-item__title {
    & {
        font-size: 1.1em;
        font-weight: bold;
    }

    label {
        display: block;
        padding-left: $tablet-padding;
        letter-spacing: 0.05em;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    label:before {
        @extend .icon;
        @extend .icon_down;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 0.2em;
        color: $color-hl;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

.question-item__content {
    & {
        padding-left: $tablet-padding;
        font-size: 300;
        letter-spacing: 0.05em;
    }

    a {
        border-bottom: 1px solid;
    }

    p span[style*="x-small"] {
        font-size: inherit!important;
    }
}
