@charset "UTF-8";

/* ----------------------------------------------------------
  Mini cart
---------------------------------------------------------- */

.page-header .minicart-wrapper .mage-dropdown-dialog {
    width: 350px;
    max-width: 90vw;
}

.header.content .minicart-wrapper.active,
.page-header .minicart-wrapper .block-minicart,
.page-header .minicart-wrapper .block-minicart .block-content {
    background-color: $color-bg;
}

.page-header .minicart-wrapper .block-minicart {
    padding: $mobile-padding;
}

.header .block-minicart {
    & {
        line-height: $line-height;
    }

    .subtitle.empty,
    .items-total {
        display: block;
        padding-bottom: $mobile-padding;
        font-size: 14px;
        font-weight: 500;
    }

    .items-total:empty {
        display: none;
    }
}

@media($mobile_only) {
    .page-header .minicart-wrapper .mage-dropdown-dialog {
        max-height: 70vh;
        overflow: auto;
    }

    .block-minicart .minicart-items-custom {
        max-height: 100000px;
    }
}

/* Items
-------------------------- */

.opc-block-summary .minicart-items-wrapper,
.minicart-items-custom {
    & {
        height: auto!important;
        margin-bottom: 20px;
        max-height: 330px;
        overflow: auto;
    }

    .product-item {
        margin-bottom: $mobile-padding;
        min-height: 80px;
    }

    .action.delete span,
    .details-qty label {
        display: none;
    }

    .option-item.option-color,
    .product-item-name {
        padding-right: 70px;
    }

    .options .label span + span,
    .option-item.option-color span + span {
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0.05em;
    }

    .product-item-name {
        display: block;
        font-size: 11px;
    }

    .subtotal,
    .price-container {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        letter-spacing: 0;
    }

    .batch-price-wrapper span[data-bind*="text"],
    .discount-label {
        max-width: 90px;
        font-size: 12px;
    }

    .batch-price-wrapper span,
    .batch-price-wrapper + span {
        display: block;
        text-align: right;
    }

    .price-wrapper-discount-price,
    .batch-price-wrapper span {
        font-weight: 600;
        color: $color-red;
    }

    .product-item-size,
    .product-item-color,
    .details-qty,
    .options .label,
    .option-item {
        margin-top: 0.5em;
        font-size: 11px;
    }

    .product-item-size .label,
    .product-item-color .label,
    .details-qty .label,
    .options .label > span:first-child,
    .option-item span {
        display: inline-block;
        min-width: 6em;
        text-transform: uppercase;
        font-weight: 300;
    }

    .details-qty {
        margin-top: 1em;
    }

    .action.delete {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        color: $color-hl;
    }

    .action.delete:hover,
    .action.delete:focus {
        color: $color-main;
    }

    .action.delete:before {
        @extend .icon;
        @extend .icon_trash;
    }
}

.opc-block-summary .minicart-items-wrapper {
    .option-item.option-color,
    .product-item-name {
        padding-right: 90px;
    }
}

/* Totals
-------------------------- */

.header .block-minicart .subtotal {
    & {
        z-index: 1;
        position: relative;
        padding-bottom: $mobile-padding;
        font-size: 14px;
        font-weight: 500;
    }

    .label {
        text-transform: uppercase;
    }

    .amount {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 16px;
    }
}

/* Offer
-------------------------- */

.header .block-minicart .promotion-offer {
    margin: 0 $mobile-padding-neg $mobile-padding;
    padding: $mobile-padding;
    text-align: center;
    background-color: $color-bg-dark;
}

/* Actions
-------------------------- */

.header .block-minicart .footer-actions {
    & {
        margin-bottom: $mobile-padding;
    }

    & > .actions {
        float: left;
        width: 50%;
    }

    & > .actions:only-child {
        float: none;
        width: 100%;
    }

    &:after {
        @extend .clearfix;
    }

    .action {
        @extend %bexley-button--small;
        display: block;
        width: 100%;
    }
}

/* Reinsurance
-------------------------- */
body #minicart-reinsurance {
    padding-top    : 20px;
    padding-bottom : 20px;
    background     : #fff;
    margin         : 0 -10px -10px;

    @media ($mobile_only) {
        padding-top    : 0;
        padding-bottom : 0;
    }

    & {
        text-align: center;
    }

    .opc-sidebar & {
        margin-top: $mobile-padding;
    }

    ul {
        display         : flex;
        justify-content : space-around;
        margin-left     : -5px;
        margin-right    : -5px;
    }

    li {
        margin: 0 5px;
        text-transform: uppercase;
        font-size: 10px;
        vertical-align: top;

        &.oney span {
            color : #80bb00;
        }
    }

    img {
        width: 2.4em;
        height: 2.4em;
    }

    strong {
        display: block;
        padding-top: 1em;
    }
}

body .opc-sidebar #minicart-reinsurance {
    @media ($desktop_excluded) {
        margin : 0 -30px -30px;
    }
    @media($desktop_only) {
        margin : 0 -40px -40px;
    }
    @media ($mobile_only) {
        margin         : 0 -20px -20px;
        padding-top    : 20px;
        padding-bottom : 20px;
    }
}

body .minicart-wrapper #minicart-reinsurance {
    margin         : 0 -20px -20px;
    padding-bottom : 20px;
    padding-top    : 20px;

    ul {
        margin-left  : 0;
        margin-right : 0;
    }
}

/* Payment banner
-------------------------- */

.payment-banner {
    text-align: center;
    margin-top: 20px;

    img {
        width: 100%;
        max-width: 400px;
    }
}

/* ----------------------------------------------------------
  Main page
---------------------------------------------------------- */

/* Layout
-------------------------- */

.checkout-cart-index {
    .page-main .columns {
        max-width : $desktop-mid;
    }

    .banner-title-wrapper {
        margin-bottom    : 0 !important;
        background-color : #FFF !important;
        text-align       : left !important;
        max-width        : $desktop-mid;
        margin-left      : auto;
        margin-right     : auto;

        .banner-title {
            font-size     : 24px !important;
            display       : inline-block;
            margin-bottom : 0;
            font-family   : $font-main !important;
        }

        .banner-title-cart {
            text-transform : uppercase;
        }
    }

    .message.warning {
        font-style: italic;
    }
}

/* Reset
-------------------------- */

.cart.item .action-towishlist,
.cart.item .action-edit,
.cart.main.actions {
    display: none;
}

/* Items
-------------------------- */

#shopping-cart-table {
    thead,
    caption {
        display: none;
    }

    tbody,
    tr,
    td {
        display: block;
    }

    tbody {
        margin-bottom: 5px;
        padding: $tablet-padding;
        background-color: $color-bg;
    }

    tr {
        z-index: 1;
        position: relative;
        padding-right: 300px;
    }
}

/* Main */

.col-item-main-wrapper {
    & {
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-bottom: 40px;
        padding-left: 130px;
        min-height: 160px;
    }

    .product-item-photo {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 110px;
    }

    .product-item-name {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
    }
}

.item-info .batch-sale {
    & {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 3em;
        text-align: right;
    }

    & > .tooltip {
        z-index: 1;
        position: absolute;
        top: 0.2em;
        right: 0;
        width: 1.5em;
        height: 1.5em;
        border: 1px solid;
        border-radius: 99em;
        text-align: center;
        line-height: 1.5;
    }
}

@media ($desktop_excluded) {
    .item-info .batch-sale {
        position: relative;
        margin-top: 1em;
        padding-right: 2.2em;
        text-align: left;
        width: max-content;
    }
}

@media ($mobile-only) {
    .item-info .batch-sale {
        p {
            max-width: 180px;
        }
    }
}

@media ($desktop-only) {
    .product-item-short-description {
        margin-bottom: 20px;
    }
}


.item-info .product-item-details {
    .options {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 130px;
    }

    .options > * {
        margin-top: 0.5em;
    }

    .options span:not([data-bind]) {
        display: inline-block;
        min-width: 6em;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: 300;
    }
}

/* Qty */

#shopping-cart-table .col.qty {
    & {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 100%;
        margin-left: -290px;
    }

    .label {
        display: none;
    }
}

/* Price */
#shopping-cart-table {
    .col.price {
        display : none;
    }

    .col.subtotal {
        & {
            z-index         : 1;
            top             : 0;
            right           : 1.3em;
            text-align      : center;
            font-size       : 16px;
            font-weight     : 500;
            letter-spacing  : 0;
            width           : 155px;
            box-sizing      : border-box;
            padding-left    : 10px;
            display         : flex;
            justify-content : center;
            align-items     : center;
            flex-wrap       : wrap;

            @media($desktop_only) {
                position : absolute;
                padding-right   : 10px;
            }
        }

        label[for="new-price"] {
            flex-basis  : 100%;
            font-weight : normal;
            font-size   : 0.9em;
        }

        label[for="new-price"],
        .new-price {
            color : $color-red;
        }

        .old-price {
            margin-right : 5px;
            font-size    : 0.9em;
            font-weight  : normal;
        }

        @include resp($desktop_excluded) {
            font-size : inherit;
        }
    }
}

/* Delete */

#shopping-cart-table .col-actions a.action-delete {
    & {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
    }

    span {
        display: none;
    }

    &:not(:hover) {
        color: $color-hl;
    }

    &:before {
        @extend .icon;
        @extend .icon_trash;
    }
}

/* Responsive */
@media($mobile_only) {
    #shopping-cart-table tbody{
        margin-right : $mobile-padding-neg;
        margin-left  : $mobile-padding-neg;
    }
}

@media($desktop_excluded) {
    #shopping-cart-table {
        tbody {
            z-index      : 1;
            position     : relative;
            padding      : 10px $mobile-padding;
        }

        tr {
            padding-right : 0;
            padding-left  : 90px;
            min-height    : 125px;

            &.item-info {
                display   : flex;
                flex-wrap : wrap;

                td {
                    flex-basis : 100%;

                    &.qty {
                        flex-basis  : max-content;
                        flex-shrink : 0;
                        position    : relative;
                        left        : 0;
                        margin-top  : 10px;
                        margin-left : 0;

                        .soon-qty-updater-wrap > * {
                            height      : 2.5em;
                            width       : 2.5em;
                            line-height : 2.5em;
                        }
                    }

                    &.col-actions {
                        padding-right : 30px;

                        a.action-delete {
                            top    : auto;
                            bottom : 0;
                        }
                    }

                    &.subtotal {
                        margin-right : auto;
                        flex-basis   : 121px;
                        margin-top   : 8px;
                    }
                }
            }
        }
    }

    .col-item-main-wrapper .product-item-photo {
        width : 85px;
    }

    .col-item-main-wrapper .product-item-name {
        font-size : 13px;
    }

    .col-item-main-wrapper {
        position       : static;
        padding-bottom : 0;
        padding-left   : 0;
        min-height     : 0;
    }

    .item-info .product-item-details .options {
        position      : static;
        margin-bottom : 10px;
    }
}

/* Sidebar
-------------------------- */

.cart-summary-wrapper {
    .summary.title {
        text-transform : uppercase;
        margin-bottom  : 20px;
    }
}

@media($mobile_only) {
    .cart-summary-wrapper {
        margin: 0 $mobile-padding-neg $mobile-padding;
    }

    .cart-summary._sticky {
        position: relative !important;
    }
}

@media($mobile_excluded) {
    /* @TODO supprimer la classe _sticky ajoutée en js*/
    .cart-summary {
        position: sticky;
        top: 0 !important;
        right: 0;
    }
}

/* Items various */

.block.items-in-cart {
    .title {
        z-index: 1;
        position: relative;
        top: -1em;
    }

    .actions-toolbar {
        margin-bottom: $fieldset-margin;
    }
}

/* Fidelity */

.loyalty-check-title {
    @extend %project--legend;
    margin-bottom: 0.5em;
    font-size: 14px;
    font-weight: normal;
}

.loyalty-login {
    & > a {
        display: block;
        width: 100%;
        margin-top: 10px!important;
    }
}

#block-loyalty {
    background-color : #fff;
    border           : 2px solid #b49770;
    box-sizing       : border-box;
    padding          : 20px;
    margin-top       : 20px;
    float            : left;

    @media($mobile_excluded) {
        width : calc(60% - 20px);
    }

    @media($desktop_only) {
        margin-top : 30px;
        padding    : 30px;
        width      : calc(66.7% - 20px);
    }

    .loyalty-title {
        text-transform : uppercase;
        margin-bottom  : 20px;
    }

    .loyalty-subtitle {
        display       : block;
        margin-bottom : 15px;
        color         : #b49770;
        font-weight   : 500;
    }

    .loyalty-list {
        color        : $color-main;
        font-weight  : 500;
        list-style   : disc;
        padding-left : 16px;

        li > span {
            letter-spacing  : 0.5px;
            display         : flex;
            justify-content : flex-start;
        }

        .price {
            color       : #b49770;
            margin-left : auto;
        }
    }

    .loyalty-list-title,
    .loyalty-text,
    .loyalty-mustbelogin {
        color       : $color-main;
        font-weight : 500;
    }

    .loyalty-text,
    .loyalty-mustbelogin {
        font-style : italic;
    }
}


/* Coupon */

.cart-summary .block.discount {
    & {
        margin-bottom: $fieldset-margin;
    }

    #block-discount-heading {
        display: block;
        z-index: 1;
        position: relative;
        padding-left: 1.5em;
        font-weight: normal;
        line-height: 15px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    #block-discount-heading i[class*="icon"] {
        color: $color-hl;
    }

    #block-discount-heading:before {
        content: '✓';
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        height: 1em;
        border: 1px solid $color-hl;
        line-height: 1;
        color: transparent;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }

    &.applied #block-discount-heading:before,
    &.active #block-discount-heading:before {
        color: $color-hl;
    }

    &.applied .content {
        display: block!important;
    }
}

#discount-coupon-form {
    .fieldset {
        z-index: 1;
        position: relative;
        margin-top: 10px;
    }

    button {
        @extend %bexley-button--secondary;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
    }

    input[type="text"],
    button {
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 36px;
        min-width: 0;
        font-size: 12px;
        line-height: 36px;
    }

    input[type="text"],
    input[type="text"]:focus {
        border-color: $color-border;
    }

    input[type="text"] {
        padding-right: 120px;
    }

    button:focus,
    button:hover {
        color: #fff;
        background-color: $color-hl;
    }
}

@media($mobile_only) {
    #discount-coupon-form input[type="text"] {
        font-size: 16px;
    }
}

/* Anchors (shipping & loyalty) */
.cart-anchors {
    margin-bottom: 0;
    li:not(:last-child) {
        margin-bottom: 10px;
    }

    a span {
        color           : $color-hl;
        font-weight     : 500;
        text-decoration : underline;
        display         : inline-block;

        &:first-letter {
            text-transform : uppercase;
        }
    }

    img {
        margin-right: 5px;
    }
}

/* Shipping */

#shipping-zip-form {
    .field {
        .label {
            strong {
                text-transform: uppercase;
                font-size: 20px;
            }
        }
    }
}

#block-shipping {
    box-sizing : border-box;
    margin-top : 20px;
    float      : left;

    @media($mobile_only) {
        margin-left  : $mobile-padding-neg;
        margin-right : $mobile-padding-neg;
        padding      : 10px 0;
    }

    @media($mobile_excluded) {
        padding : 10px 15px 10px 0;
        width   : 60%;
    }
    @media($desktop_only) {
        padding    : 10px 20px 10px 0;
        width      : 66.7%;
        margin-top : 30px;
    }

    .select {
        max-width: max-content;
    }

    fieldset > .field.note,
    fieldset > br,
    legend {
        display: none;
    }

    #block-summary:not(div:empty) {
        display          : block !important;
        background-color : $color-bg;
        padding          : 30px;

        @media($mobile_only) {
            padding : 20px;
        }

        .field label > span span {
            text-transform : none;
            font-size      : 0.85em;
        }
    }

    .items.methods,
    .field {
        margin-top: 1em;
    }

    ul.items.methods {
        .choice.item {
            padding-left : 20px;
            position: relative;

            &:before {
                content: "•";
                position: absolute;
                font-size: 1.5em;
                left: 2px;
                top: -5px;
                z-index: 1;
            }
        }
    }

    .field.choice label,
    .field.choice {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .item-title {
        font-size: 12px;
    }

    .field.choice label {
        text-transform : none;
        font-size      : 12px;
        font-weight    : 300;
        cursor         : auto;
        position       : relative;
        padding-right  : 70px;

        .name {
            font-weight : 500;
        }

        .price {
            position : absolute;
            right    : 0;
            top      : 0;
            padding  : 0;
        }
    }

    [name="shippingAddress.country_id"] {
        margin-top: 0;
    }
}

#shipping-description-for-specific-countries {
    & {
        @extend %responsive-padding-lr-inv;
        @extend %responsive-padding-lr;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: $color-bg-dark;
        text-align: center;
    }

    &:empty {
        display: none;
    }
}

/* Totals */

.opc-sidebar .table-totals,
#cart-totals {
    td,
    th {
        padding-bottom: 0.6em;
        font-size: 12px;
        font-weight: 300;
    }

    td[colspan="2"] {
        padding-bottom: 0;
    }

    & > tbody > .totals:first-child > td,
    & > tbody > .totals:first-child > th,
    .totals.sub td,
    .totals.sub th {
        font-size: 16px;
        font-weight: 500;
    }

    .price {
        white-space: nowrap;
    }

    .grandtotal-line td,
    .grandtotal-line th,
    .grand.totals:not(.excl) td,
    .grand.totals:not(.excl) th {
        padding: 0.5em 0;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 400;
    }

    .grand.totals:not(.excl) th span {
        text-transform: none;
        font-size: 13px;
    }

    .totals.sub td,
    .totals.sub th {
        padding: 1em 0 0.8em;
    }

    .additional-shipping td,
    .additional-shipping th {
        padding: 0.5em 0;
    }

    .grandtotal-line strong,
    .grand.totals strong {
        font: inherit;
    }

    .grand.totals.excl td,
    .grand.totals.excl th {
        padding-top: 0.6em;
    }

    .additional-shipping,
    .grandtotal-line,
    .grand.totals:not(.excl) {
        background-color: $color-bg-dark;
        box-shadow: $desktop-padding-neg 0 0 0 $color-bg-dark,$desktop-padding 0 0 0 $color-bg-dark;
    }
}

/* Order */

.cart-summary {;
    .checkout.methods {
        @extend %responsive-padding-lr-inv;
    }

    button[data-role="proceed-to-checkout"] {
        display: block;
        font-size: 13px;
        margin: 20px auto;
        width: 100%;

        @media($mobile_only) {
            width: auto;
        }

        // SPECIALS
        @media (min-width: 1025px) and (max-width: 1160px) {
            min-width: auto;
        }
        @media (min-width: 769px) and (max-width: 900px) {
            min-width: auto;
        }
    }
}

/* Savings */
.savings {
    padding     : 1em 0 0.8em;
    text-align  : center !important;
    font-weight : 500 !important;
    color       : $color-red;
    margin      : 0 -5px;

    .savings-price {
        font-size : 1.4em;
    }
}

.table-totals {
    .savings {
        td {
            padding: 1em 0 0.8em !important;
            text-align: center;
            text-align  : center;
        }
        span {
            font-weight: 500;
        }
    }
}

.header {
    h3 + .savings {
        letter-spacing : 0;
        padding-top    : 0;
        font-weight    : 500 !important;
        margin-top     : -10px;
    }
}


/* Reinsurance */

.opc-sidebar #additional-shipping-info {
    & {
        padding: $mobile-padding/2 0;
        text-align: center;
        font-weight : 400;
    }

    &:empty {
        display: none;
    }
}

/* Go to checkout
-------------------------- */

.checkout-onepage-link {
    text-align: center;
}

@media($mobile_only) {

    .sticky-mobile-cart {
        z-index          : 3;
        position         : fixed;
        bottom           : 0;
        left             : 0;
        right            : 0;
        background-color : #fff;
        box-shadow       : 0 0 4px rgba(0, 0, 0, 0.5);
        padding          : 10px 15px;

        &_content {
            display         : flex;
            justify-content : space-between;

            p {
                margin : 0;
            }
        }

        &_total {
            text-transform : uppercase;
            font-weight    : 500;
        }

        &_products {
            font-size : 0.9em;
        }

        &_amount {
            font-size   : 1em;
            font-weight : 500;
            color       : $color-red;
        }

        button {
            margin  : 15px auto 10px !important;
            display : block !important;
            width   : 280px;
        }
    }
}
.sticky-mobile-cart {
    display : none;
}


.loyalty-mustbelogin {
    span {
        text-decoration: underline;

        &:hover  {
            cursor: pointer;
        }
    }
}


// MOBILE CART CONTAINER
@media($mobile_only) {
    .cart-container {
        display: flex;
        flex-direction: column !important;

        #form-validate {
            order: 1;
        }
        .cart-summary {
            order:2;
        }

        #block-shipping {
            order: 3;
        }

        #block-loyalty {
            order: 4;
        }
    }
}





