@charset "UTF-8";

/* ----------------------------------------------------------
  Header
---------------------------------------------------------- */

%banner-title-wrapper {
    @extend %responsive-padding;
    @extend %responsive-margin-tb;
    margin-top: 0;
    text-align: center;
    background-color: $color-bg;
}

%banner-title-wrapper .banner-title {
    & {
        @extend %project--h1;
        letter-spacing: 0.15em;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

/* Description
-------------------------- */

.catalog-category-view .banner-title-wrapper {
    margin-bottom: 0!important;
}

.page-main.banner-description {
    & {
        @extend %responsive-padding;
        @extend %responsive-margin-tb;
        background-color: $color-bg;
    }

    &,
    .banner-description {
        @extend %responsive-padding-tb-inv;
        margin-bottom: 0;
    }

    .banner-description {
        margin-right: auto;
        margin-left: auto;
        max-width: $mobile-max;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        color: $color-hl;
    }
}

@media($mobile_only) {
    .page-main.banner-description .banner-description {
        font-size: 10px;
    }
}

/* ----------------------------------------------------------
  List
---------------------------------------------------------- */

/* Toolbar
-------------------------- */

#amasty-shopby-product-list .catalog-topnav {
    display: none;
}
.catalog-topnav {
    display: none;
}

/* Subtitle
-------------------------- */

.bexley-category-description {
    & {
        margin-right: $desktop-padding/2;
        margin-bottom: $mobile-padding;
        margin-left: $desktop-padding/2;
        border-bottom: 1px solid $color-border;
    }

    .details,
    .title {
        display: inline;
        margin: 0;
        vertical-align: middle;
    }

    .title {
        @extend %project--h3;
        letter-spacing: 0.15em;
    }

    .details {
        padding-left: 1em;
        text-transform: uppercase;
        font-size: 1.3em;
        color: $color-hl;
    }

    @include resp($desktop_excluded) {
        margin-right: $tablet-padding/2;
        margin-left: $tablet-padding/2;
        font-size: 10px;
    }
}

@media($mobile_only) {
    .bexley-category-description {
        margin-right: 5px;
        margin-left: 5px;
    }

    .bexley-category-description .details {
        display: block;
        padding-left: 0;
    }
}

@media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    .catalog-category-view .bexley-category-description h2.title {
        font-size: 14px;
    }

    .catalog-category-view .page-wrapper > .page-main.banner-title > .banner-title-wrapper h1.banner-title {
        font-size: 18px;
    }
}

/* Size
-------------------------- */

.product-items.list .product-item {
    width: 50%;
}

@media($mobile_excluded) {
    .product-items.list .product-item {
        width: 33.3%;
    }
}

@media($mobile_only) {
    .product-items.list .product-item {
        padding-right: 5px;
        padding-left: 5px;
    }

    .product-items.list {
        margin: 0 -5px;
    }
}

/* Block with a product
-------------------------- */

.block-content-products-list-image {
    &:after {
        @extend .clearfix;
    }

    .product-items.list .product-item {
        float: left;
        width: 25%;
    }
}

@media($mobile_only) {
    .block-content-products-list-image .product-items.list .product-item {
        width: 50%;
    }

    .block-content-products-list-image .product-items.list .product-item:nth-child(3n) {
        clear: left;
    }
}

/* ----------------------------------------------------------
  Search - No results
---------------------------------------------------------- */

.catalogsearch-result-index {
    .column.main > .message {
        @extend %integentostyles-message;
    }
}

/* ----------------------------------------------------------
  Product - batch-sale
---------------------------------------------------------- */

.catalog-category-view .product.batch-sale .bx-parsed-marketing-condition {
    p {
        font-size      : 13px;
        letter-spacing : 0;

        strong {
            color       : $color-red;
            font-size   : 18.2px;
            font-weight : 400;
        }
    }
}


@media($mobile_only) {
    .catalog-category-view .product.batch-sale .bx-parsed-marketing-condition {
        p {
            font-size : 10px;

            strong {
                font-size : 14px;
            }
        }
    }
}


/* ----------------------------------------------------------
  Evole listing catégorie
---------------------------------------------------------- */
.catalog-category-view {
    .bexley-category-description {
        .title {
            font-size: 22px;
        }
    }

    .page-wrapper > .page-main.banner-title > .banner-title-wrapper,
    .page-main.banner-description {
        background: transparent;
        margin: 0 auto;
        text-align: left;
        max-width: $content_width_wide;
        padding-bottom: 0;
    }

    .page-wrapper > .page-main.banner-title > .banner-title-wrapper {
        display: flex;
        justify-content: left;
        align-items: center;

        .banner-title {
            font-size: 24px;
            width: 28%;
        }
    }


    .page-main.banner-description {
        padding-top: 0;

        .banner-description {
            width: 28%;
            margin: 0;
            text-align: left;
            font-size: 14px;
        }
    }
}

@media($mobile-only) {
    .catalog-category-view {
        .bexley-category-description {
            h2.title {
                font-size: 14px;
            }
        }
    }
}

.category_top_filters {
    &__container {
        width: 72%;
        padding: 0 32px;
        box-sizing: border-box;
        overflow: hidden;

        ul {
            display: flex;
            li {
                margin: 0 5px;
            }

            a {
                display         : block;
                padding         : 5px;
                font-size       : 14px;
                font-weight     : 500;
                background      : #f5f5f5;
                white-space     : nowrap;
                text-transform  : uppercase;

                &.top_filters__is-current {
                    background-color    : $color-hl;
                    color               : #fff;
                }
            }
        }

        .slick-arrow {
            position: absolute;
            top: 0;
            background: #fff;
        }

        .slick-prev {
            left: -32px;
        }

        .slick-next {
            right: -32px;
        }

        .slick-disabled {
            display: none !important;
        }
    }
}



.nb-colors-product {
    color: $color-hl;
}

@media($desktop_excluded) {
    .catalog-category-view {
        .page-wrapper > .page-main.banner-title > .banner-title-wrapper {
            flex-direction: column;
            justify-content: left;
            align-items: start;

            .banner-title {
                font-size: 18px;
                width: 100%;
            }
        }

        .category_top_filters {
            &__container {
                width       : 100%;
                margin      : 9px 0;
                padding     : 0;
                overflow    : scroll;
                -webkit-overflow-scrolling: touch;
                ul {
                    a {
                        font-size: 12px;
                    }
                }
            }
        }

        .banner-title-wrapper .page-main.banner-description {
            width: 100%;
            padding: 0;

            .banner-description {
                width: 100%;
                font-size: 10px;
            }
        }
    }


    .listing_images_slider {
        .slick-arrow {
            display: none !important; // pour contrer de display flex appliqué en JS
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .catalog-category-view {
        .banner-title-wrapper .page-main.banner-description {
            .banner-description {
                font-size: 14px;
            }
        }
    }

    .am_shopby_apply_filters.visible {
        display: none !important;
    }
}

.listing_product_slider {
    position: relative;
}

.listing_images_slider {
    position: relative;

    .slick-arrow {
        position        : absolute;
        top             : 0;
        bottom          : 0;
        display         : flex;
        justify-content : center;
        align-items     : center;
        z-index         : 1;
        width           : 40px;
        font-size       : 10px;
        opacity         : 0;
        transition      : opacity ease-in-out 0.3s;

        &.slick-next {
            right       : 0;
        }

        &.slick-prev {
            left        : 0;
        }

        .icon {
            display         : flex;
            width           : 20px;
            height          : 20px;
            justify-content : center;
            align-items     : center;
            border          : 1px solid;
            border-radius   : 50%;
        }
    }

    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }

    .slick-prev {
        left : 10px;
    }

    .slick-next {
        right : 10px;
    }

    .slick-dots {
        z-index         : 1;
        top             : 5px;
        bottom          : auto;
        display         : flex;
        justify-content : flex-end;

        li {

            width       : 13px;

            button {
                display         : inline-flex;
                justify-content : center;
                align-items     : center;

                &::before {
                    content         : '';
                    display         : block;
                    width           : 6px;
                    height          : 6px;
                    background      : #fff;
                    border          : 1px solid $color-main; // $color-hl
                    border-radius   : 50%;
                    opacity         : 1;
                    transition      : background-color ease-in-out 0.3s;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        background: $color-main;
                    }
                }
            }
        }
    }
}


.link_over_video_player {
    position: absolute;
    inset: 0;
    z-index: 1;
}

.products-recentlyViewed{
    max-height: 515px;
}
