@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.contact-container {
    @extend %responsive-padding-tb;
    margin: auto;
    max-width: $tablet-max;
}

.contact-container-grid {
    @extend %desktop-grid-table;
}

/* ----------------------------------------------------------
  Title
---------------------------------------------------------- */

.contact-index-index .page-main.banner-description .banner-description {
    max-width: 500px;
}

/* ----------------------------------------------------------
  Elements
---------------------------------------------------------- */

.contact-right {
    .contact-right-content,
    .contact-image {
        @extend %responsive-margin-bottom;
    }

    .contact-image img {
        display: block;
        width: 100%;
        max-height: 470px;
        object-fit: cover;
    }
}

.contact-container-grid .legend {
    & {
        @extend %project--legend;
        font-size: 18px;
        font-weight: normal;
    }

    @include resp($mobile_only) {
        font-size: 16px;
    }
}

/* Text
-------------------------- */

.contact-right .sub-header-text,
.contact-left .field.note.no-label {
    margin-bottom: 3em;
    font-size: 12px;
    font-weight: 300;
}

.contact-right .sub-header-text {
    margin-bottom: 0;
}

/* Mobile
-------------------------- */

@media($mobile_excluded) {
    .contact-right,
    .contact-right .fieldset {
        height: 100%;
    }

    .contact-right .fieldset > * {
        width: 100%;
        text-align: left;
    }

    .contact-right .fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

@media($mobile_only) {
    .contact-container {
        text-align: center;
    }

    .contact-left form {
        text-align: left;
    }

    [lang="fr"] .contact-left form .field.comment.required label {
        letter-spacing: 0.01em;
    }

    .contact-right [class*="primary"] {
        width: 100%;
        margin: auto;
    }
}
