@charset "UTF-8";

/* ----------------------------------------------------------
  Content
---------------------------------------------------------- */

.cssc-content {
    font-size: $font-size;
}

.cssc-content {
}

/* ----------------------------------------------------------
  CMS
---------------------------------------------------------- */

.cms-page-view .page-main .columns {
    & {
        z-index: 0;
        position: relative;
        font-weight: 300;
    }

    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase;
        font-family: $font-second;
        font-weight: 500;
    }

    strong,
    b {
        font-weight: 500;
    }

    table,
    ul,
    blockquote,
    p:not(:last-child) {
        margin-bottom: 1em;
    }

    p,
    ul {
        line-height: 1.5;
    }

    li:before {
        content: '•';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.2em;
        color: $color-hl;
    }

    li {
        z-index: 1;
        position: relative;
        padding-left: 1em;
    }
}

/* ----------------------------------------------------------
  Heading
---------------------------------------------------------- */

.bexley-heading {
    @extend %responsive-padding-tb;
    text-align: center;
}

.bexley-heading__action {
    & {
        margin-top: $desktop-padding;
    }

    a[class*="button"] {
        min-width: 200px;
    }
}

.bexley-heading .wysiwyg {
    & {
        margin-top: 1em;
        text-transform: uppercase;
        font-size: 14px;
    }

    @include resp($mobile_only) {
        font-size: 12px;
    }
}

.bexley-heading .wysiwyg p {
    font: inherit;
}

/* ----------------------------------------------------------
  No route
---------------------------------------------------------- */

.content-noroute {
    & {
        @extend %responsive-padding;
        text-align: center;
    }

    .title {
        @extend %project--h1;
    }

    p.info {
        @extend %bexley-subtitle;
        text-transform: none;
    }

    .cta {
        margin-top: 1em;
    }
}

@include resp($mobile_only) {
    .content-noroute {
        padding: 20px 0 0 0;
    }
}

