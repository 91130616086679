@charset "UTF-8";

/* ----------------------------------------------------------
  Grids
---------------------------------------------------------- */

.press-release-articles__wrapper {
    & {
        @extend %responsive-margin-tb;
        margin-top: 0;
        margin-right: 0-$desktop-padding/2;
        margin-left: 0-$desktop-padding/2;
    }

    &[data-is-visible="0"] {
        display: none;
    }
}

.press-release-articles {
    & {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    & > * {
        @extend %responsive-padding-tb;
        display: table-cell;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-right: $desktop-padding/2;
        padding-left: $desktop-padding/2;
        vertical-align: top;
    }
}

@media($desktop_excluded) {
    .press-release-articles__wrapper {
        margin-right: 0-$tablet-padding/2;
        margin-left: 0-$tablet-padding/2;
    }

    .press-release-articles__wrapper > * {
        padding-right: $tablet-padding/2;
        padding-left: $tablet-padding/2;
    }
}

@media($mobile_only) {
    .press-release-articles__wrapper {
        margin-right: 0-$mobile-padding/2;
        margin-left: 0-$mobile-padding/2;
    }

    .press-release-articles__wrapper > * {
        padding-right: $mobile-padding/2;
        padding-left: $mobile-padding/2;
    }

    .press-release-articles.width-25,
    .press-release-articles.width-50,
    .press-release-articles.width-50 > * {
        display: block;
        width: auto;
    }

    .press-release-articles.width-25 > * {
        display: inline-block;
        width: 50%;
        padding-right: $mobile-padding/2;
        padding-left: $mobile-padding/2;
        vertical-align: top;
    }
}

/* ----------------------------------------------------------
  Blocks
---------------------------------------------------------- */

.bxl-press-release {
    & {
        text-align: center;
    }

    .bxl-press-cta,
    .bxl-press-title,
    .bxl-press-legend {
        margin: $mobile-padding auto 0;
        max-width: 640px;
    }

    img {
        display: block;
        width: 100%;
    }

    /* Title */

    .bxl-press-title {
        @extend %project--h3;
        margin-bottom: 0.5em;
    }

    .width-25 > & .bxl-press-title {
        @extend %project--h4;
        margin-bottom: 0.7em;
    }

    /* Text */

    .bxl-press-legend p {
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0;
    }

    /* CTA */

    .bxl-press-cta a {
        border-bottom: 2px solid;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-hl;
    }

    .bxl-press-cta a:hover {
        color: $color-main;
    }
}

@media($mobile_only) {
    .width-25 > .bxl-press-release .bxl-press-title {
        font-size: 1em;
        letter-spacing: 0.1em;
    }
}

/* ----------------------------------------------------------
  Load more
---------------------------------------------------------- */

.press-load-more {
    @extend %responsive-margin-tb;
    text-align: center;
}

.press-load-more a {
    @extend %bexley-button;
    @extend %bexley-button--secondary;
}
