@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

/* JS Perf
-------------------------- */

.product-gallery__image + .product-gallery__image {
    & {
        display: none;
    }

    .slick-track & {
        display: block;

        &.has_video {
            display: flex;
        }
    }
}

/* Main image
-------------------------- */

.product-gallery__images {
    & {
        z-index: 1;
        position: relative;
    }

    .slick-dots li button:before,
    .slick-dots li button,
    .slick-dots li {
        width: 30px;
        height: 30px;
    }

    .slick-dots li button:before {
        font-size: 40px;
        line-height: 20px;
    }

    .slick-arrow {
        z-index: 1;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        bottom: 5px;
    }
}

.product-gallery__images {
    .slick-slide:not(.slick-active) {
        pointer-events: none;
    }
}


@media($mobile_only) {
    .product-gallery__images {
        border-bottom: 30px solid transparent;

        body.has-popin-slideshow & {
            margin-top: 50px;
            border-bottom: 50px solid transparent;
        }
    }

    .product-gallery__images .slick-dots {
        bottom: -40px;
    }

    // Block cloudzoom on mobile
    .product-gallery__image {
        /*pointer-events: none;*/

        // Active cloudzoom in the fake popin
        body.has-popin-slideshow & {
            pointer-events: auto;
        }
    }
}

/* Thumbs
-------------------------- */

@media($desktop_only) {
    .product-gallery--slider {
        z-index: 1;
        position: relative;
        padding-left: 100px;
    }

    .product-gallery__thumbs {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 90px;
        overflow: auto;
    }
}

@media($desktop_excluded) {
    .product-gallery__thumbs {
        display: flex;
        overflow-x: auto;

        .product-gallery__thumb {
            flex: 0 0 80px;
            width: 80px;

            + .product-gallery__thumb {
                margin-left: 10px;
            }
        }
    }
}

@media($mobile_only) {
    .product-gallery__thumbs {
        display: none;

        // Display thumbs in the fake popin
        body.has-popin-slideshow & {
            display: flex;
            justify-content: center;
        }
    }
}

@media ($tiny_only) {
    .product-gallery__thumbs {

        body.has-popin-slideshow & {
            justify-content: flex-start;
        }

        .product-gallery__thumb {

            + .product-gallery__thumb {
                margin-left: 5px;
            }
        }
    }
}

.product-gallery-thumb__btn {
    @extend .buttonreset;

    &:before {
        @extend %absolute-cover;
        @extend %csscommon-visibility-hidden;
        content: '';
        background-color: rgba($color-main,0.05);
        box-shadow: inset 0 -3px 0 0 $color-hl;
    }

    &.current:before {
        @extend %csscommon-visibility-visible;
    }
}

.is_video {
    .product-gallery-thumb__btn {
        &:after {
            display: block;
            content: '';
            position: absolute;
            inset: 0;
            background  : url("../images/icon/play_video.svg") 50% 50% no-repeat;
            background-size: 35%;
        }
    }
}

/* Main
-------------------------- */

.product-gallery {
    .slick-arrow {
        @extend %parent-hover-visibility;
        padding: 0.25em;
        font-size: 2em;
        color: $color-hl;

        @media($mobile_only) {
            display: none;
        }
    }
}

/* Popin Slideshow
-------------------------- */

body.has-popin-slideshow {
    padding-top: 0;

    &.page-has-cookie-notice {
        padding-bottom: 0;
    }

    // Remove everything
    .cookie-notice,
    .breadcrumbs,
    .page.messages,
    .product-info-main,
    .product.info.detailed,
    .delivery-and-returns,
    .block.related,
    .block.upsell,
    .page-bottom,
    .page-footer,
    .modals-wrapper,
    .slick-dots,
    .product-gallery .slick-arrow {
        display: none !important;
    }

    // Slideshow
    .product.media {
        top: 75px;
        height: calc(85vh - 80px);
    }
}

/* Popin Btns
-------------------------- */

.product-gallery__btn {
    z-index: 2;
    @extend .buttonreset;

    // Close
    &.product-gallery__btn-close {
        position: fixed;
        display: none;
        top: 85px;
        right: 10px;
        font-size: 1.4rem;

        body.has-popin-slideshow & {
            display: inline-block;
        }
    }

    // Open
    &.product-gallery__btn-open {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 20px;
        background: url('../images/zoom.svg') center no-repeat;
        background-size: cover;

        @media($mobile_only) {
            display: inline-block;

            body.has-popin-slideshow & {
                display: none;
            }
        }
    }
}

/* PinchZoom
-------------------------- */
#pinchZoomContainer {
    position            : fixed;
    top                 : 80px;
    bottom              : 0;
    left                : 0;
    right               : 0;
    background          : #fff;
    z-index             : 4;
    height              : 100vh;

    .pinch-zoom-container {
        margin-bottom   : 72px;
    }

    .thumbs__container {
        position    : fixed;
        bottom      : 0;
        left        : 0;
        right       : 0;
        overflow    : scroll;
        display     : flex;
        z-index     : 1;
        background  : #fff;
        padding     : 15px 0;

        img {
            display         : block;
            margin          : 0;
            width           : 80px;
            height          : 80px;
            border-bottom   : 3px solid #fff;

            &.active {
                border-color: #b49770;
            }
        }
    }

    .close-pinch {
        display     : inline-block;
        position    : absolute;
        top         : 15px;
        right       : 15px;
        font-size   : 30px;
        z-index     : 1;
    }
}

$bottomControlsHeight : 70px;

.product-gallery__image.has_video {
    position: relative;
    aspect-ratio: 900 / 737;

    @media($mobile_only) {
        /* Div au dessus du player vidéo pour détecter le swipe en mobile */
        .video_gallery_mobile_swipe {
            position    : absolute;
            inset       : 0;
            z-index     : 2;
            pointer-events: none;

            span {
                display: block;
                position: absolute;
                pointer-events: all;

                /* Play - pause button : 65px x 30px */

                &:nth-child(1) {
                    /* Partie supérieur */
                    left: 0;
                    right: 0;
                    top: 0;
                    height: calc(50% - (30px / 2));
                }

                &:nth-child(2) {
                    /* Partie Gauche */
                    left: 0;
                    top: 0;
                    bottom: $bottomControlsHeight;
                    width: calc(50% - (65px / 2));
                }

                &:nth-child(3) {
                    /* Partie Droite */
                    right: 0;
                    top: 0;
                    bottom: $bottomControlsHeight;
                    width: calc(50% - (65px / 2));
                }

                &:nth-child(4) {
                    /* Partie inférieur */
                    bottom: $bottomControlsHeight;
                    left: 0;
                    right: 0;
                    height: calc((50% - ( 30px / 2)) - 70px);
                }
            }
        }
    }

    @media($mobile_excluded) {
            .video_gallery_mobile_swipe {
                display: none;
            }
        }
}
