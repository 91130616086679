@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.product-look-wrapper {
    & {
        margin: 0 $desktop-padding-neg/2 $desktop-padding;
    }

    & > * {
        float: left;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        padding: 0 $desktop-padding/2;
    }

    &:after {
        @extend .clearfix;
    }
}

@media($desktop_excluded) {
    .product-look-wrapper {
        margin: 0 $tablet-padding-neg/2 $tablet-padding;
    }

    .product-look-wrapper > * {
        padding: 0 $tablet-padding/2;
    }

    .product-look-wrapper > .product-look-media {
        width: 40%;
    }

    .product-look-wrapper > .product-look-main {
        width: 60%;
    }
}

@media($mobile_only) {
    .product-look-wrapper {
        margin: 0 0 $mobile-padding;
    }

    .product-look-wrapper > * {
        padding: 0 0 $mobile-padding;
    }

    .product-look-wrapper > .product-look-media,
    .product-look-wrapper > .product-look-main {
        float: none;
        width: 100%;
    }
}

/* ----------------------------------------------------------
  Media
---------------------------------------------------------- */

.product-look-media {
    & {
        text-align: center;
    }

    img {
        display: block;
        margin: auto;
        max-height: 90vh;
        max-width: 100%;
    }
}

/* ----------------------------------------------------------
  Header
---------------------------------------------------------- */

.product-look-header {
    @extend %responsive-margin-tb;
    margin-top: 0;
    text-align: center;
}

.product-look-name {
    @extend %project--h2;
    margin-bottom: 0;
}

.product-look-price {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
}

.product-look-price .batch-sale-price {
    font-size: 1.2em;
}

.product-look-price .batch-sale-price span {
    margin-right: 0.2em;
    font-size: 1.3em;
}

.product-look-price .product-price .price-box {
    margin-bottom: $mobile-padding;
    font-size: 2.57em;
}

.product-look-price .batch-sale {
    margin-bottom: $mobile-padding;
}

@media($mobile_only) {
    .product-look-price {
        font-size: 10px;
    }
}

/* ----------------------------------------------------------
  Items
---------------------------------------------------------- */

.look-product {
    & {
        @extend .desktop-grid-table;
        @extend %responsive-margin-tb;
        margin-top: 0;
    }

    .product-image img {
        display: block;
        width: 100%;
    }

    .product-name,
    .field.configurable,
    .product-info-price > :last-child {
        margin-bottom: 10px;
    }

    .product-name {
        font-size: $font-size;
        letter-spacing: 0;
    }

    .product-name a {
        text-transform: uppercase;
        font-weight: 500;
    }

    .product-info-price {
        font-size: 14px;
        letter-spacing: 0;
    }

    .product-info-price .product-price,
    .product-info-price .batch-sale span {
        margin-right: 0.3em;
        font-size: 18px;
        font-weight: 500;
    }

    // Payment info
    .product-info-payment {
        margin-left: 10px;

        img {
            height: 24px;
        }
    }

    .label {
        margin: 0;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 2;
    }

    .product-add-form {
        z-index: 1;
        position: relative;
    }

    .product-add-form .label {
        margin-bottom: 5px;
    }

    .product-add-form .size-guide {
        z-index: 1;
        position: absolute;
        top: 5px;
        right: 0;
        text-decoration: underline;
        font-size: 12px;
        letter-spacing: 0;
    }

    .box-tocart button {
        display: block;
        width: 100%;
        max-width: 400px;
    }
}

.look-related-products {
    & {
        margin-right: -10px;
    }

    .label {
        margin-bottom: 0.5em;
    }

    .related-product {
        display: inline-block;
        width: 60px;
        margin-right: 10px;
        margin-bottom: 10px;
        max-width: 22%;
        vertical-align: top;
        cursor: pointer;
    }

    .related-product.active img {
        border-bottom: 3px solid $color-hl;
        cursor: default;
    }
}
