@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.checkout-index-index {
    & {
        @extend %responsive-padding-tb;
        padding-top: 0;
    }

    .opc-estimated-wrapper {
        display: none;
    }

    .page-header {
        @extend %responsive-padding-lr;
    }

    .checkout_header {
        z-index: 1;
        position: relative;
        height: 100%;
    }

    .header.content {
        z-index: 1;
        position: relative;
        max-width: $content_width_wide;
        text-align: center;
    }

    .header.content .back,
    .header.content .secure-payment {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: 2em;
        margin: auto;
        max-width: 25%;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 2;
        letter-spacing: 0.1em;
    }

    .header.content .back {
        left: 0;
        text-align: left;
    }

    .header.content .secure-payment {
        right: 0;
        text-align: right;
    }

    .header.content .secure-payment img {
        z-index: 1;
        position: absolute;
        top: -0.2em;
        right: 100%;
        margin-right: 0.75em;
    }

    .no-quotes-block p.error {
        margin-bottom: 22px;
    }
    .fieldset.address{
        ._with-tooltip{
            .field-tooltip{
                span.label{
                    display: none;
                }
            }
        }
    }
}

#checkoutSteps,
.cart-summary-wrapper,
.opc-block-summary {
    @extend %responsive-padding;
    background-color: $color-bg;
}

.cart-summary-wrapper {
    padding-bottom: 0;
}

.cart-summary-wrapper,
.opc-block-summary {
    overflow: hidden;
}

@media($mobile_only) {
    .checkout-index-index .header.content .back,
    .checkout-index-index .header.content .secure-payment {
        top: -5px;
        font-size: 9px;
        line-height: 1;
    }

    .checkout-index-index .header.content .secure-payment {
        padding-left: 20px;
    }

    .checkout-index-index .header.content .secure-payment img {
        right: auto;
        left: 0;
        width: 15px;
        margin-right: auto;
    }

    .checkout-index-index .columns {
        padding-right: 0;
        padding-left: 0;
    }
}

/* Main
-------------------------- */

.opc-block-summary > .title,
.step-title {
    @extend %project--legend;
}

.pac-container:empty {
    display: none;
}

@media($desktop_only) {
    .step-content {
        padding-left: $desktop-padding;
    }

    #co-payment-form > .fieldset > .legend {
        z-index: 1;
        position: relative;
        left: $desktop-padding-neg;
    }
}

/* Header
-------------------------- */

.checkout-index-index {
    .page-header {
        margin-bottom: $desktop-padding;
        border-top: 1px solid transparent;
        border-bottom: 1px solid $color-border;
    }
}

/* Steps
-------------------------- */

.opc-progress-bar {
    & {
        counter-reset: section;
        padding-bottom: $tablet-padding;
        text-align: center;
    }

    & > li {
        counter-increment: section;
        display: inline-block;
        min-width: 150px;
        font-size: 12px;
        vertical-align: top;
        color: $color-hl;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    & > li:before {
        @extend .icon;
        content: '';
        display: block;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 2.5em;
        height: 2.5em;
        margin: auto;
        margin-bottom: 0.45em;
        border: 1px solid $color-hl;
        border-radius: 99em;
        font-size: 2em;
        line-height: 2.5;
        color: $color-hl;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    & > li._active._complete:before,
    & > li._complete:before {
        border-color: $color-main;
        color: #fff;
        background-color: $color-main;
    }

    & > li._active._complete,
    & > li._complete {
        color: $color-main;
    }

    & > #opcprogress-step-shipping._complete {
        cursor: pointer;
    }

    & > #opcprogress-step-shipping._complete:hover:before,
    & > li._active:before {
        border-color: $color-hl;
        color: #fff;
        background-color: $color-hl;
    }

    & > #opcprogress-step-shipping._complete:hover,
    & > li._active {
        color: $color-hl;
    }

    #opcprogress-step-cart:before {
        @extend .icon_cart;
        padding-left: 0.3em;
        line-height: 2.3;
    }

    #opcprogress-step-shipping:before {
        @extend .icon_camion;
        padding-right: 0.2em;
    }

    #opcprogress-step-payment:before {
        @extend .icon_card;
        padding-left: 0.1em;
    }

    span {
        z-index: 1;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
    }

    span:after {
        @extend %absolute-cover;
        content: '';
        top: -7em;
    }

    span:before {
        content: counter(section) '. ';
        margin: auto;
    }
}

@media($mobile_only) {
    .opc-progress-bar {
        margin-top: $mobile-padding;
        padding-bottom: $mobile-padding;
    }

    .opc-progress-bar > li {
        width: 80px;
        min-width: 20px;
        font-size: 8px;
    }
}

/* Sidebar
-------------------------- */

/* Price */

/* Shipping */

#cart-totals,
#co-shipping-method-form {
    .loading-mask {
        opacity: 0.60;
        background-color: $color-bg;
    }
}

.opc-block-shipping-information {
    .ship-via,
    .ship-to {
        @extend %responsive-margin-tb;
        z-index: 1;
        position: relative;
        padding: $mobile-padding;
        border: 1px solid $color-hl;
        font-size: 12px;
        font-weight: 300;
    }

    .ship-to {
        margin-bottom: 0;
    }

    .ship-via {
        margin-top: 0;
        border-top: 0;
    }
}

.shipping-information-title {
    & > span {
        @extend %project--legend;
    }

    button {
        @extend .buttonreset;
        z-index: 1;
        position: absolute;
        top: $mobile-padding*2.8;
        right: $mobile-padding;
        text-decoration: underline;
        font-weight: bold;
        color: $color-hl;
    }
}

.shipping-information-content {
    padding-right: 3em;
}

/* Selected
-------------------------- */

.shipping-address-items {
    display: block;
    z-index: 1;
    position: relative;
}

.shipping-address-item {
    & {
        display: inline-block;
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 30%;
        margin-right: 2.5%;
        padding: 0 $mobile-padding $mobile-padding 0;
        vertical-align: top;
        -webkit-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
    }

    &:not(:hover) {
        opacity: 0.50;
    }

    &.selected-item {
        opacity: 0.9999;
    }

    &.selected-item:before {
        content: '✓';
        z-index: 1;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 1.5em;
        height: 1.5em;
        border-radius: 99em;
        text-align: center;
        line-height: 1.5;
        color: #fff;
        background-color: $color-hl;
    }

    button.action {
        @extend %bexley-button--small;
    }
}

.shipping-address-item.selected-item {
    & {
        padding: $mobile-padding;
        font-weight: 300;
        background-color: $color-bg-dark;
    }
}

#checkout-step-shipping .field.addresses ~ button {
    @extend %bexley-button;
    margin-top: $mobile-padding;
}

@media($desktop_excluded) and ($mobile_excluded) ,
($tiny_only) {
    .shipping-address-item {
        width: 47%;
    }
}

/* Buttons
-------------------------- */

#shipping-method-buttons-container {
    margin-bottom: 0;
}

/* Checkout
-------------------------- */

.totals.aw-giftcard-totals .action-delete,
.totals.extra-total .action-delete {
    & {
        @extend %cssc-button;
        line-height: 1;
    }

    .delete-icon:before {
        @extend .icon;
        @extend .icon_trash;
        z-index: 1;
        position: relative;
        top: -1px;
    }
}

.aw-giftcard-codes-block {
    @extend %responsive-table;
}

@media($mobile_only) {
    .aw-giftcard-codes-block tr + tr {
        border-top: 1px solid $color-border;
    }

    .aw-giftcard-codes-block .action-apply a {
        text-decoration: underline;
    }

    .aw-giftcard-codes-block .block-content .box {
        width: auto;
    }
}

/* ----------------------------------------------------------
  Identification
---------------------------------------------------------- */

/* ----------------------------------------------------------
  Addresses
---------------------------------------------------------- */

/* ----------------------------------------------------------
  Shipping
---------------------------------------------------------- */

#opc-shipping_method {
    @extend %responsive-margin-tb;
    margin-bottom: 0;
}

.table-checkout-shipping-method {
    & {
        @extend %reset-table-div;
    }

    tr {
        z-index: 1;
        position: relative;
        padding: $mobile-padding 100px $mobile-padding $mobile-padding*3;
        background-color: $color-bg-dark;
        -webkit-transition: background-color 0.3s ease;
        transition: background-color 0.3s ease;
        cursor: pointer;
    }

    tr + tr {
        margin-top: 10px;
    }

    td {
        display: inline;
        font-size: 16px;
        font-weight: 300;
    }

    td a {
        border-bottom: 1px solid;
    }

    td + td.col-method {
        font-weight: 500;
    }

    td + td.col-method:after {
        content: ' - ';
    }

    .store-pickup-modal .pickup-button {
        z-index: 2;
        position: relative;
    }

    td.col-price {
        z-index: 1;
        position: absolute;
        top: $mobile-padding;
        right: $mobile-padding;
    }
}

.modal-stock-by-shop,
.table-checkout-shipping-method {
    input[type="radio"] + label:before {
        z-index: 1;
        position: absolute;
        top: $mobile-padding;
        left: $mobile-padding;
        margin-top: 0.2em;
    }

    input[type="radio"] + label {
        position: static!important;
        text-transform: uppercase;

        span {
            font-weight: 500;
        }
    }

    input[type="radio"] + label:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border: 1px solid transparent;
        -webkit-transition: border 0.3s ease;
        transition: border 0.3s ease;
        cursor: pointer;
    }

    tr:hover input[type="radio"] + label:after {
        border-color: $color-border;
    }

    input[type="radio"]:checked + label:after {
        border-color: $color-hl!important;
    }

    input[type="radio"] {
        @extend %fake-radio-ghost;
    }

    input[type="radio"] + label:before {
        background-color: #fff;
    }
}

/* Error
-------------------------- */

.methods-shipping > .message {
    @extend %integentostyles-message;
    margin-top: $fieldset-margin;
}

/* ----------------------------------------------------------
  Billing
---------------------------------------------------------- */

.checkout-billing-address {

    button.action {
        @extend %bexley-button--small;
    }

    .billing-address-form {
        margin-top: $fieldset-margin;
    }

    label[for*="billing-address-same-as-shipping-"] {
        margin-bottom: 0;
        padding-left: 2em!important;
        text-transform: none;
        letter-spacing: 0;
    }
}

.billing-address-details {
    & {
        padding-bottom: 1em;
        padding-left: 2em;
        font-weight: 300;
    }

    br {
        display: none;
    }

    button.action-edit-address {
        display: block;
        margin-top: 1em;
    }
}

/* ----------------------------------------------------------
  Payment
---------------------------------------------------------- */

.payment-group .step-title {
    display: none;
}

.payment-group .payment-method {
    margin-bottom: $tablet-padding;
}

.payment-method-content {
    & {
        padding-left: 2em;
    }

    .items.check.payable {
        font-weight: 300;
    }

    .items.check.payable .title {
        margin-top: 1em;
        font-weight: 500;
    }
}

.payment-method-title {
    margin-bottom: 20px;

    label {
        margin-bottom: 0;

        // Credit cards visuals
        div.adyen-sprite {
            height: 28px !important;
            width: 227px !important;
            background: url(../images/credit-cards@x2.png) no-repeat !important;
            background-size: 227px auto !important;
        }

        span {
            display: block;
            text-transform: none;
            margin-top: 10px;
        }
    }

    input[type="radio"] {
        & {
            @extend %fake-radio-ghost;
        }

        & + label:before {
            top: 7px !important;
        }

        &:not(:checked) + label:before {
            border-color: $color-border!important;
        }
    }
}

// Action
.payment-method {
    .actions-toolbar {
        .action {
            box-shadow: none;

            &:disabled {
                cursor: default;
                border: 1px solid #ccc;
                color: #333;
                opacity: 0.66;
                background: #e0e0e0;
            }
        }
    }
}


/* Bank transfert
-------------------------- */

#banktransfer {
    & + label {
        span {
            margin-top: 5px;
        }
    }
}

/* Additional methods
-------------------------- */

.opc-payment-additional,
.opc-payment {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid $color-border;
}

.opc-payment-additional + .opc-payment-additional {
    margin-top: 30px;
    padding-top: 0;
    border-top: 0 solid;
}

.opc-payment-additional {
    .payment-option-title {
        min-height: 30px;
    }
}

.opc-payment-additional._collapsible {
    .actions-toolbar .primary,
    .actions-toolbar {
        margin: 0;
    }

    .payment-option-inner {
        margin-bottom: 1em;
    }

    .payment-option-content {
        padding-top: 1em;
        padding-left: 2em;
    }

    .form-store-credit .label {
        display: block;
        margin-bottom: 1em;
    }

    /* Check */

    .action-toggle {
        display: block;
        z-index: 1;
        position: relative;
        padding-left: 6em;
        text-transform: uppercase;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .action-toggle:before {
        @extend .icon;
        @extend .icon_card;
        z-index: 1;
        position: absolute;
        top: -0.3em;
        left: 0.7em;
        font-size: 3em;
    }

    &.aw-giftcard-code .action-toggle:before {
        @extend .icon_gift;
    }

    .action-toggle:after {
        @extend %fake-checkbox-skin;
        content: '✓';
        z-index: 1;
        position: absolute;
        left: 0;
        width: 1em;
        height: 1em;
        text-align: center;
        line-height: 1;
    }

    &._active .action-toggle:after {
        @extend %fake-checkbox-skin-checked;
    }
}

/* Adyen credit card
-------------------------- */

fieldset#payment_form_adyen_cc,
fieldset.adyen_oneclick {
    max-width: 600px;

    legend {
        @extend .visually-hidden;
    }

    // Label
    .adyen-checkout__label {
        @extend %project--label;
    }

    .adyen-checkout__label__text {
        font-size: 12px;
        color: $color-main;
        margin-bottom: 10px;

        // Required
        &::after {
            content: '*';
            margin-left: 0.3em;
            font-weight: bold;
        }
    }

    // Input
    .adyen-checkout__input {
        @extend %project--field;
        max-width: none;

        &--focus,
        &:focus {
            box-shadow: none;
            border-color: $color-main;
        }
    }

    // Focused
    .adyen-checkout__label {

        &--focused {
            .adyen-checkout__label__text {
                color: $color-main;
            }

            .adyen-checkout__input {
                box-shadow: none;
                border-color: $color-main;
            }
        }
    }

    .checkout-component-dock {
        max-width: none;
    }

    // Errors
    .adyen-checkout-input__inline-validation {
        display: none;
    }

    .adyen-checkout__input--error {
        border-color: $integento_error_color;
    }

    .adyen-checkout__error-text {
        @extend %integento-field-error;
        text-transform: none;
        margin-top: 0;
        font-weight: 300;
    }
}

fieldset#payment_form_adyen_cc {

    // Checkbox
    .adyen-checkout__checkbox__label {
        padding-left: 26px;
        font-size: 13px;
        text-transform: none;
        color: #000;

        &::after {
            @extend %fake-checkbox-skin;
            content: '✓';
            z-index: 1;
            position: absolute;
            left: 0;
            width: 15px;
            height: 15px;
            text-align: center;
            line-height: 1;
            border-radius: 0;
            box-shadow: none !important;
        }
    }

    input[type="checkbox"]:checked {
        & + .adyen-checkout__checkbox__label {
            &::after {
                @extend %fake-checkbox-skin-checked;
            }
        }
    }
}

// Saved credit card
img.adyen_oneclick {
    max-height: 28px;
    width: auto;
}

fieldset.adyen_oneclick {
    .number {
        .control {
            padding: 10px 0 30px;

            span {
                display: block;
                width: 100%;
                @extend %project--field;
                background-color: #e6e9eb;
            }
        }
    }

    .checkout-component-dock {
        margin-bottom: 20px;
    }
}


@media($mobile_only) {
    .fieldset .adyen-checkout__applepay__button,
    .adyen-checkout__paywithgoogle > div > button,
    .adyen-checkout__paywithgoogle > div >button.long {
        width: 100%;
    }
}

.fieldset .adyen-checkout__applepay__button {
    border-radius: 0;
    margin-top: 26px;
}

.adyen-checkout__paywithgoogle > div > button,
.adyen-checkout__paywithgoogle > div >button.long {
    border-radius: 0;
}

#adyen-alternative-payment-container-googlepay {
    margin-top: 26px;
}

#payment_form_adyen_hpp_googlepay .actions-toolbar {
    display: none;
}

.adyen-checkout__paywithgoogle .gpay-card-info-iframe {
    margin-top: 4px;
}

/* Oney
-------------------------- */

.checkout-payment-method {
    .payment-method-title.load-simulation {
        flex-direction: column;
        align-items: flex-start;
    }
}

label.loan-simulation__label {
    span {
        display: inline-block;
        margin-top: 0;
        text-transform: uppercase;
    }

    // Oney logo
    img {
        margin-left: 10px;
        max-width: 110px;
    }
}

.loan-simulation__desc {
    text-transform: none;
    padding-left: 26px;
    margin-top: 10px;

    strong {
        color: $color-oney;
    }
}

/* ----------------------------------------------------------
  Agreements
---------------------------------------------------------- */

.checkout-agreement {
    input[type="checkbox"] {
        @extend %fake-check-ghost;
    }

    button.action-show {
        @extend .buttonreset;
        letter-spacing: inherit;
        text-align: left;
        white-space: normal;
    }
}


//
//  Cheques fidélités
//  _____________________________________________

.loyalty_check_tunnel {
    .control {
        label {
            text-transform: none;
        }
    }
}

.opc-payment-additional._collapsible.loyalty-program .action-toggle:before {
    content           : '' !important;
    background-image  : url("../images/icon/icone-cheque-fidelite.svg");
    background-size   : 47px;
    background-repeat : no-repeat;
    display           : inline-block;
    width             : 46px;
    height            : 40px;
    margin            : -3px 0 0 -5px;
    vertical-align    : unset !important;
}
/* -----------------------------------------


 */

#sw-table-horaire {
    tr > td {
        &:nth-child(2) {
            &:after {
                content: ' ; ';
            }
        }
    }
}


.adyen-checkout__card__form {
    .adyen-checkout__card__brands {
        display: none;
    }
}

.payment-method-title label[for="adyen_cc"] img {
    max-width: 227px;
}
