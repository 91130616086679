@charset "UTF-8";

/* ----------------------------------------------------------
  Store Selector
---------------------------------------------------------- */

/* Store popin
-------------------------- */

.modal-popup .checkout-storelist {
    #sl-list {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-right: $mobile-padding;
        max-width: 100%;
        border: 1px solid $color-hl;
    }

    #sl-list li {
        padding: $mobile-padding;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    #sl-list li.active {
        background-color: $color-bg;
    }
}

@media($mobile_only) {
    .modal-popup #checkout-storelist-wrapper {
        display: block!important;
    }

    .modal-popup .checkout-storelist #sl-map-container,
    .modal-popup .checkout-storelist #sl-list {
        width: 100%!important;
        height: 250px!important;
        margin-right: 0;
        max-height: 33vh;
    }
}

/* Item
-------------------------- */

.sl-list-item,
.sl-info-window {
    & {
        font-size: $font-size;
        font-weight: 300;
    }

    h2,
    p {
        margin: 0;
        font: inherit;
    }

    & > :first-child {
        text-transform: uppercase;
        font-weight: normal;
    }

    a {
        text-decoration: underline;
        color: $color-hl;
    }

    .item-url a,
    & > a {
        display: block;
        margin-top: 1em;
    }
}

/* Map info */

.sl-info-window {
    width: 200px;
}

@media($mobile_only) {
    .sl-info-window {
        width: 100px;
        font-size: 8px;
    }
}

/* ----------------------------------------------------------
  Store Locator - List
---------------------------------------------------------- */

/* Layout
-------------------------- */

.soon-storelocator-store-index .banner-title-wrapper {
    @extend %banner-title-wrapper;
    background-color: #FFF!important;
}

.bexley-store-container-wrapper {
    z-index: 1;
    position: relative;
}

#store-city-select {
    @extend %project--field;
    @extend %project--select;
    @extend %responsive-margin-bottom;
}

.store-city-list__wrapper {
    display: none;
    z-index: 1;
    position: absolute;
    top: 0;
}

@media ($mobile_only) {
    .soon-storelocator-store-index .banner-title-wrapper {
        margin-bottom: 0;
    }

    .soon-storelocator-store-index .breadcrumbs {
        display: none;
    }
}

@media($mobile_excluded) {
    .has-floating-menu {
        .bexley-store-container {
            padding-top: 150px;

            .city-list {
                top: 140px;
            }
        }
    }

    .store-city-list__wrapper {
        position: static;
    }

    .bexley-store-container .city-list {
        position: sticky;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
    }

    .store-city-list {
        position: relative;
        padding: 15px 35px 15px 15px;

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }

    .store-city-list:not(.slick-initialized) {
        display: none;
    }

    .store-item-block {
        margin-bottom: 0;
    }

    .store-list__title {
        padding-top: 40px;
    }

    .store-city-list__wrapper {
        display: block;
        background: #fff;
    }

    .city-list[data-position="fixed"] .store-city-list__wrapper {
        position: fixed;
    }

    .city-list[data-position="bottom"] .store-city-list__wrapper {
        top: auto;
        bottom: 0;
    }

    #store-city-select {
        display: none;
    }
}

@media(max-width: 1780px ) {
    .has-floating-menu {
        .bexley-store-container {
            .city-list {
                top: 156px;
            }
        }
    }
}

/* List
-------------------------- */

.store-city-list__wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
}

.store-city-list {

    li {
        z-index: 1;
        position: relative;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: normal;
    }

    li a {
        display: block;
        padding-left: 20px;
        outline: 0 solid transparent!important;
    }

    li:before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: -1px;
        width: 0;
        height: 100%;
        background: transparent;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    li.current a,
    li:hover a {
        color: $color-hl;
    }

    .city-item--authorized {
        margin-bottom: 0;
        font-size: 14px;
    }
}

/* Title
-------------------------- */

.store-list__title {
    @extend %project--h3;
    border-bottom: 1px solid $color-border;
    line-height: 1.5;
}

/* Item
-------------------------- */

.bexley-store-container-wrapper {
    margin-bottom: $desktop-padding;
}

.storelocator-stores:last-child .store-item-block:last-child {
    margin-bottom: 0;
}

.store-item-block {
    & {
        @extend %responsive-margin-tb;
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-left: 50%;
        background-color: $color-bg;
    }

    .store-img {
        @extend .absolute-cover;
        right: 50%;
        overflow: hidden;
    }

    .store-img a {
        @extend .absolute-cover;
        background: $color-bg-dark no-repeat center center;
        background-size: cover;
        -webkit-transition: all 5s ease;
        transition: all 5s ease;
    }

    .store-img a:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .store-img .store-city-box {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 0.75em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 2.5;
        letter-spacing: 0.2em;
        color: #fff;
        background-color: $color-hl;
    }

    .store-info {
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: $tablet-padding;
        min-height: 300px;
    }

    .store-info_title {
        @extend %project--h4;
    }

    .store-info_content p,
    .store-info_content {
        font-size: 14px;
        font-weight: 300;
    }

    .store-info_content .item {
        display: block;
    }

    /* Timetable */

    .item.timetable {
        margin: $mobile-padding 0;
    }

    /* Contact */

    .item.phone a,
    .item.email a {
        font-weight: 500;
    }

    .item.phone:before,
    .item.email:before {
        @extend .icon;
        margin-right: 1em;
        color: $color-hl;
    }

    .item.phone:before {
        @extend .icon_phone;
    }

    .item.email:before {
        @extend .icon_mail;
    }

    /* More */

    .learn-more a {
        display: inline-block;
        z-index: 1;
        position: relative;
        margin-top: $mobile-padding;
        text-transform: uppercase;
        font-weight: 500;
        color: $color-hl;
    }

    .learn-more a:hover {
        color: $color-main;
    }

    .learn-more a:before {
        @extend .icon;
        @extend .icon_plus;
        z-index: 1;
        float: right;
        position: relative;
        top: 0.05em;
        margin-left: 0.3em;
        font-size: 1.3em;
    }
}

@media($desktop_only) {
    .store-item-block .store-info {
        padding-bottom: 90px;
    }

    .store-item-block .store-contact {
        z-index: 1;
        position: absolute;
        bottom: $tablet-padding;
        left: $tablet-padding;
        padding-right: 180px;
    }

    .store-item-block .learn-more {
        z-index: 1;
        position: absolute;
        right: $tablet-padding;
        bottom: $tablet-padding;
    }
}

@media($mobile_only) {
    .store-item-block {
        padding-left: 0;
    }

    .store-item-block .store-info {
        padding: $mobile-padding;
    }

    .store-item-block .store-img {
        z-index: 1;
        position: relative;
        right: 0;
        height: 0;
        padding-top: 60%;
    }

    .store-item-block .store-info {
        min-height: 0;
    }
}

/* ----------------------------------------------------------
  Store locator - View
---------------------------------------------------------- */

/* Grid
-------------------------- */

.store-view__grid {
    & {
        @extend %responsive-margin-tb;
        z-index: 1;
        position: relative;
        padding-right: 66%;
    }

    .store-view__map {
        @extend .absolute-cover;
        left: 34%;
    }

    .store-view__content {
        padding-right: $desktop-padding;
    }

    #sl-map-container {
        @extend .absolute-cover;
        width: 100%!important;
        height: 100%!important;
    }
}

@media($desktop_excluded) {
    .store-view__grid {
        padding-right: 50%;
    }

    .store-view__grid .store-view__content {
        padding-right: $tablet-padding;
    }

    .store-view__grid .store-view__map {
        left: 50%;
    }
}

@media($mobile_only) {
    .store-view__grid {
        padding-right: 0%;
    }

    .store-view__grid .store-view__map {
        z-index: 1;
        position: relative;
        left: 0;
        height: 300px;
        margin-top: $mobile-padding;
        max-height: 50vh;
    }

    .store-view__grid .store-view__content {
        padding-right: 0;
    }
}

/* Content
-------------------------- */

.store-view__content {
    .back {
        display: none;
    }

    .storelocator-city {
        display: inline-block;
        margin-bottom: 2em;
        padding: 0 0.75em;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 2.5;
        letter-spacing: 0.2em;
        vertical-align: top;
        color: #fff;
        background-color: $color-main;
    }

    .storelocator-name {
        @extend %project--h2;
    }

    h2.title {
        @extend %project--title;
        margin-bottom: 1em;
        font-size: 15px;
    }

    & {
        font-size: 14px;
        font-weight: 300;
    }

    p,
    ul,
    li {
        font: inherit;
        line-height: 1.5;
    }

    .storelocator-address-items li {
        font-size: 1.3em;
    }
}

.storelocator-public-transport,
.storelocator-address-items {
    margin-bottom: $tablet-padding;
}

.storelocator-contact {
    & {
        margin-bottom: $tablet-padding;
    }

    & > * {
        z-index: 1;
        position: relative;
        margin-bottom: 0.3em;
        padding-left: 2em;
    }

    & > *:before {
        @extend .icon;
        z-index: 1;
        position: absolute;
        bottom: 0.1em;
        left: 0;
        font-size: 1.1em;
        color: $color-hl;
    }

    a {
        font-weight: 500;
    }

    .storelocator-contact-phone-number:before {
        @extend .icon_phone;
    }

    .storelocator-contact-email:before {
        @extend .icon_mail;
    }
}

.storelocator-timetable {
    margin-bottom: $desktop-padding;
}

.horaires > br {
    display: none;
}

.storelocator-available-product-list li {
    & {
        z-index: 1;
        position: relative;
        padding-left: 2em;
        font-size: 15px;
        font-weight: 300;
    }

    &:before {
        content: '•';
        z-index: 1;
        position: absolute;
        top: -0.35em;
        left: 0;
        font-size: 2em;
        color: $color-hl;
    }
}

/* Images
-------------------------- */

.storelocator-images__wrapper {
    & {
        padding-left: 34%;
    }

    @include resp($desktop_excluded) {
        padding-left: 50%;
    }

    @include resp($mobile_only) {
        padding-left: 0;
    }
}

.storelocator-images {
    & {
        margin-top: $desktop-padding;
    }

    .secondary:after,
    &:after {
        @extend .clearfix;
    }

    & > * {
        z-index: 1;
        float: left;
        position: relative;
        width: 50%;
    }

    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }

    .storelocator-main-image img {
        height: 350px;
    }

    .secondary img {
        height: 175px - $desktop-padding/2;
        margin-bottom: $desktop-padding;
    }

    .secondary li {
        float: left;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        padding-left: $desktop-padding;
    }
}

@media ($desktop_excluded) {
    .storelocator-images {
        margin-top: $tablet-padding;
    }

    .storelocator-images > * {
        float: none;
        width: auto;
    }

    .storelocator-images .storelocator-main-image img {
        height: 250px;
    }

    .storelocator-images .secondary {
        margin-top: $tablet-padding;
        margin-left: $tablet-padding-neg;
    }

    .storelocator-images .secondary li {
        padding-left: $tablet-padding;
    }

    .storelocator-images .secondary img {
        height: 125px - $tablet-padding/2;
        margin-bottom: $tablet-padding;
    }
}

@media ($desktop_excluded) {
    .storelocator-images {
        margin-top: $mobile-padding;
    }

    .storelocator-images .storelocator-main-image img {
        height: 200px;
    }

    .storelocator-images .secondary {
        margin-top: $mobile-padding;
        margin-left: $mobile-padding-neg;
    }

    .storelocator-images .secondary li {
        padding-left: $mobile-padding;
    }

    .storelocator-images .secondary img {
        height: 100px - $mobile-padding/2;
        margin-bottom: $mobile-padding;
    }
}

/* Slider
-------------------------- */

.storelocator-main-image__slider {
    .slick-arrow {
        z-index: 1;
        position: absolute;
        top: 50%;
        text-shadow: 0 0 10px #000;
        font-size: 20px;
        color: #fff;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .slick-arrow:hover {
        color: $color-hl;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}
