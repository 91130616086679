// Generated by grunt-webfont


@font-face {
	font-family:"icons";
	src:url("../fonts/icons/icons.eot?dc52ecf5e1ba109bd0a304419e6dc769");
	src:url("../fonts/icons/icons.eot?#iefix") format("embedded-opentype"),
		url("../fonts/icons/icons.woff2?dc52ecf5e1ba109bd0a304419e6dc769") format("woff2"),
		url("../fonts/icons/icons.woff?dc52ecf5e1ba109bd0a304419e6dc769") format("woff"),
		url("../fonts/icons/icons.ttf?dc52ecf5e1ba109bd0a304419e6dc769") format("truetype");
	font-weight:normal;
	font-style:normal;
	font-display: block;
}

.icon {

		font-family:"icons";

	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


// Icons


.icon_calendar:before {
	content:"\f101";
}


.icon_camion:before {
	content:"\f102";
}


.icon_card:before {
	content:"\f103";
}


.icon_cart:before {
	content:"\f104";
}


.icon_dashboard:before {
	content:"\f105";
}


.icon_down:before {
	content:"\f106";
}


.icon_earth:before {
	content:"\f107";
}


.icon_edit:before {
	content:"\f108";
}


.icon_exchanges:before {
	content:"\f109";
}


.icon_eye:before {
	content:"\f10a";
}


.icon_facebook:before {
	content:"\f10b";
}


.icon_fid:before {
	content:"\f10c";
}


.icon_gift:before {
	content:"\f10d";
}


.icon_heart_f:before {
	content:"\f10e";
}


.icon_heart:before {
	content:"\f10f";
}


.icon_home:before {
	content:"\f110";
}


.icon_i:before {
	content:"\f111";
}


.icon_instagram:before {
	content:"\f112";
}


.icon_linkedin:before {
	content:"\f113";
}


.icon_mail:before {
	content:"\f114";
}


.icon_next:before {
	content:"\f115";
}


.icon_order:before {
	content:"\f116";
}


.icon_phone:before {
	content:"\f117";
}


.icon_pin:before {
	content:"\f118";
}


.icon_pinterest:before {
	content:"\f119";
}


.icon_plus:before {
	content:"\f11a";
}


.icon_prev:before {
	content:"\f11b";
}


.icon_refresh:before {
	content:"\f11c";
}


.icon_search:before {
	content:"\f11d";
}


.icon_secure:before {
	content:"\f11e";
}


.icon_time:before {
	content:"\f11f";
}


.icon_toggle:before {
	content:"\f120";
}


.icon_trash:before {
	content:"\f121";
}


.icon_twitter:before {
	content:"\f122";
}


.icon_up:before {
	content:"\f123";
}


.icon_user:before {
	content:"\f124";
}


.icon_userthin:before {
	content:"\f125";
}


.icon_x:before {
	content:"\f126";
}

.icon_expedition {
	display    : inline-block;
	margin-top : 5px;

	&:before {
		content           : '' !important;
		background-image  : url("../images/icon/expedition.svg");
		background-size   : contain;
		background-repeat : no-repeat;
		position          : relative;
		display           : inline-block;
		width             : 22px;
		height            : 22px;
		margin            : 0 4px -5px 0 !important;
		vertical-align    : unset !important;
	}
}
