.checkout-index-index {
    .stock-by-shop__step {
        display: block;
    }

    .stores-map-item__title {
        &:before {
            display: none;
        }
    }

    .stores-map-item__radio {
        position: absolute;
        top: 20px;
        left: 0;
    }

    .stock-by-shop__checkout_actions {
        text-align: center;

        button {
            @extend %bexley-button;
            margin-top: $mobile-padding;
        }
    }

    .modal-stock-by-shop {
        input[type="radio"] {
            & + label {
                padding-left: 0;

                &:before {
                    left: 5px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .stores-map-item__distance {
        left: auto;
        right: 0;
    }

    .clickandcollect-shipping-information-content {
        font-size: 16px;
        font-weight: 300;
    }

    #stores-map {
        @include resp($desktop_excluded){
            height: calc(76vh - 80px);
        }
    }
}

.shipping-information-summary {
    margin-top: 40px;
    padding: 20px;
    border: 1px solid $color-hl;
}

.clickandcollect-shipping-information-content ~ button {
    @extend %bexley-button;
    margin-top: $mobile-padding;
}


.checkout-shipping-method {
    #stock-by-shop-modal {
        display: none;
    }
}
