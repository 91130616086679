@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.customer-account-logoutsuccess,
.customer-account-create,
.quick-registration-customer-create,
.customer-account-createpassword,
.customer-account-forgotpassword,
.customer-account-login {
    .banner-title-wrapper {
        margin-bottom: 0!important;
        background-color: #FFF!important;
    }

    .column.main {
        @extend %responsive-margin-bottom;
        @extend %responsive-padding;
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
        background-color: $color-bg;
    }
}

.customer-account-create,
.quick-registration-customer-create,
.customer-account-createpassword,
.customer-account-forgotpassword {
    .column.main {
        max-width: 100%;
    }
}

/* ----------------------------------------------------------
  Login
---------------------------------------------------------- */

.login-container {
    .block-title [role="heading"] {
        @extend %project--legend;
    }

    .block-customer-login .field.note,
    .block-new-customer p {
        font-size: 16px;
        font-weight: 300;
    }
}

.field.password .control,
.field.confirmation .control {
    & {
        z-index: 1;
        position: relative;
    }

    .toggle-pass-visibility {
        @extend %cssc-button;
        z-index: 1;
        position: absolute;
        top: 10px;
        right: 12px;
        color: $color-hl;
    }

    .password-constraint {
        margin-top: 5px;
        font-size: .65rem;

        .password-constraint-label {
            display: block;
            margin-bottom: 5px;
            font-style: italic;
        }

        .constraint-item {
            font-style: normal;
            text-transform: uppercase;

            &.is-true {
                color: $integento_valid_color;
            }

            &.is-false {
                color: $integento_error_color;
            }
        }
    }

    #password-error {
        display: none !important;
    }

    // Remove double error when submit
    .mage-error + .mobile-error-message {
        display: none;
    }
}

.form-create-account,
.form-edit-account {

    .field {

        &.field-name-firstname,
        &.field-name-lastname,
        &.date.field-dob,
        &.email-adress,
        &.password,
        &.confirmation {

            label.label {

                @media($mobile_only) {
                    display: none;
                }
            }
        }

        &.email-adress,
        &.confirmation {

            .mobile-error-message {
                display: none;
                margin-top: 5px;
                font-size: .65rem;
                font-style: italic;
                color: $integento_error_color;
            }

            &.is-false {

                input {
                    border: 1px solid $integento_error_color;
                    color: $integento_error_color;
                }

                .mobile-error-message {
                    display: block;
                }
            }

            .mage-error + .mobile-error-message {
                display: none;
            }
        }
    }

    input {

        &::placeholder {
            visibility: hidden;

            @media($mobile_only) {
                visibility: visible;
                opacity: 1 !important;
                font-size: 14px;
                text-transform: uppercase;
            }
        }

        &.input-birthday::placeholder {
            visibility: visible;
        }
    }
}

#remember-me-box .tooltip {
    display: none;
}

/* ----------------------------------------------------------
  Prefix
---------------------------------------------------------- */

html[lang="fr"] {
    .user-prefix {
        &.MR:before {
            content: 'M.';
        }
        &.MME:before {
            content: 'MME';
        }
    }
}
html[lang="es"] {
    .user-prefix {
        &.MR:before {
            content: 'SR.';
        }
        &.MME:before {
            content: 'SRA.';
        }
    }
}
html[lang="en"] {
    .user-prefix {
        &.MR:before {
            content: 'MR.';
        }
        &.MME:before {
            content: 'MRS.';
        }
    }
}

@media($mobile_only) {
    .customer-account-login .page-main.banner-title {
        display: none;
    }

    .customer-account-login .columns {
        padding-right: 0;
        padding-left: 0;
    }

    .login-container .block-title {
        text-align: center;
        font-weight: normal;
        color: $color-hl;
    }
}

@media($mobile_excluded) {
    .login-container .block-new-customer {
        border-left: 1px solid $color-border;
    }

    .login-container .block-customer-login,
    .login-container .block-new-customer {
        padding: 0 $desktop-padding!important;
    }

    .login-container .block-new-customer {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -ms-box-orient: vertical;
    }
}

/* ----------------------------------------------------------
  Register
---------------------------------------------------------- */

.account-create-notice {
    & {
        z-index: 1;
        position: relative;
        margin-top: $mobile-padding;
        padding-left: 2em;
    }

    p {
        margin-bottom: 0;
        font-weight: 300;
    }

    p + p {
        margin-top: $line-height-em;
    }

    .newsletter-subscription {
        margin-top: 5px;
        margin-left: -2em;
    }


    input[name="subscribe-customer-to-newsletter-groups"] {
        @extend %fake-check-ghost;
    }

    /* Invisible overlay to set a correct cliquable area around the checkbox */

    label[for="subscribe-customer-to-newsletter-groups"]:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: -20px;
        right: -20px;
        bottom: -40px;
        left: -20px;
        margin: 0;
        background: transparent;
    }
}

.form-create-account {
    .fieldset .legend {
        display: none;
    }
}

@media($mobile_only) {
    .customer-account-create .page-main .columns {
        padding-right: 0;
        padding-left: 0;
    }

    .customer-account-create .banner-title-wrapper {
        margin-top: $mobile-padding;
        padding-bottom: 0!important;
        color: $color-hl;
        background-color: $color-bg!important;
    }

    .customer-account-create .footer .footer-top-reinsurance {
        margin-top: 0;
    }

    .customer-account-create .column.main {
        margin-bottom: 0;
    }
}

/* ----------------------------------------------------------
  Logout
---------------------------------------------------------- */

.customer-account-logoutsuccess {
    .column.main {
        max-width: $mobile-max;
        text-align: center;
    }

    .column.main p {
        margin-bottom: 0;
    }
}
