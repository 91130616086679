@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

body.account {
    #maincontent > .page-main > .banner-title-wrapper {
        margin-bottom: 0!important;
        background-color: #FFF!important;
    }

    .sidebar-main > .account-nav,
    .column-main-inner {
        @extend %responsive-padding;
        background-color: $color-bg;
    }

    .column-main-inner {
        font-weight: 300;
        line-height: $line-height;
    }
}

@media($desktop_only) and ($large_excluded) {
    .account.page-layout-2columns-left .column.main {
        width: 75%;
    }

    .page-layout-2columns-left .sidebar {
        width: 25%;
    }
}

@media($desktop_excluded) {
    body.account .sidebar-main {
        width: 100%;
        padding-top: 0;
        padding-right: 0;
    }

    body.account .columns {
        display: flex;
        flex-direction: column-reverse;
    }

    body.account .sidebar-main > .account-nav {
        margin-bottom: $mobile-padding;
        padding: 0;
        background-color: transparent;
    }
}

@media($mobile_only) {
    body.account .column-main-inner {
        margin-right: $mobile-padding-neg;
        margin-left: $mobile-padding-neg;
    }
}

/* Nav
-------------------------- */

.account-nav > .title {
    display: none;
}

.account-nav .item:not(.logout) {
    & {
        z-index: 1;
        position: relative;
        margin-bottom: $mobile-padding;
        padding-left: 3em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }

    &:before {
        @extend .icon;
        @extend .icon_home;
        z-index: 1;
        position: absolute;
        top: 0.1em;
        left: 0;
        font-size: 1.2em;
    }

    &[class="nav item"]:before {
        display: none;
    }

    &.account_dashboard:before {
        @extend .icon_dashboard;
    }

    &.my_orders:before {
        @extend .icon_order;
    }

    &.account_information:before {
        @extend .icon_user;
    }

    &.my_returns:before {
        @extend .icon_refresh;
    }

    &.loyalty_program:before {
        @extend .icon_fid;
    }

    &.wishlist:before {
        @extend .icon_heart;
    }

    &.newsletter_subscriptions:before {
        @extend .icon_mail;
    }

    &.stored_payment_methods:before {
        @extend .icon_card;
    }

    &.giftcard:before {
        @extend .icon_time;
    }

    &.delete_account:before {
        @extend .icon_i;
    }
}

.account-nav .item {
    & {
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }

    &:hover,
    &:hover a,
    &.current,
    &.current a {
        color: $color-hl;
    }

    &.logout a {
        @extend %bexley-button-secondary-action;
        display: block;
        width: 100%;
        min-width: auto;
    }

    &.logout a:before {
        @extend .icon;
        @extend .icon_toggle;
        font-size: 2em;
        vertical-align: -0.15em;
    }
}

.account-nav-select {
    @extend %project--field;
    @extend %project--select;
    border-color: $color-hl;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: $color-hl;
}

@media($desktop_only) {
    .account-nav-select {
        display: none!important;
    }
}

@media($desktop_excluded) {
    .nav.items {
        display: none!important;
    }
}

/* Content
-------------------------- */

body.account .column-main-inner {
    .banner-title {
        @extend %project--h3;

        @include resp($mobile_only){
            font-size: 18px;
        }
    }

    [class*="block-dashboard"] {
        margin-top: $desktop-padding;
        margin-bottom: $desktop-padding;
    }

    .box-title {
        display: block;
        margin-bottom: $mobile-padding/2;
    }

    strong.box-title {
        font-weight: normal;
    }

    #bexley-giftcard-title,
    .fidelity-bonus-title,
    .block .block-title strong {
        @extend %project--h5;
        display: block;
        margin-top: $mobile-padding;
        margin-bottom: $mobile-padding;
    }

    .block .action button {
        @extend %bexley-button;
        margin-top: $mobile-padding;
    }
}

/* ----------------------------------------------------------
  Dashboard
---------------------------------------------------------- */

#customer-account-description-welcome {
    @extend %project--h3;
    margin-bottom: 0.5em;
}

.customer-account-index .column-main-inner {
    .banner-title-wrapper {
        display: none;
    }
}

#bexley-customer-store-credit {
    margin: $mobile-padding 0;

    #bexley-store-credit-title {
        @extend %project--h5;
    }
}



.block-dashboard-info .box-newsletter {
    .box-content {
        flex-grow: 2;
    }
}

.block-dashboard-loyalty-info {
    .use-your-credit-info,
    .loyalty-info {
        margin-top: 1em;
    }
}

.use-your-credit-info {
    margin-top: 1em;
}

.block-simple-loyalty-info {
    margin: 1em 0;
}

/* ----------------------------------------------------------
  Fidelity
---------------------------------------------------------- */

.fidelity-bonus-progress-bar {
    & {
        z-index: 1;
        position: relative;
        margin: $mobile-padding 0;
        max-width: 400px;
        font-size: 14px;
        font-weight: 500;
        background-color: $color-bg;
    }

    .fidelity-bar {
        z-index: 1;
        position: relative;
        height: 5px;
        background-color: $color-bg-dark;
    }

    .fidelity-bar .fidelity-progress {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin: auto;
        background-color: $color-hl;
    }

    .fidelity-infos {
        z-index: 1;
        position: relative;
        height: 1em;
        margin-top: 10px;
    }

    .fidelity-infos > * {
        z-index: 1;
        position: absolute;
        top: 0;
    }

    .fidelity-bonus-progress-bar-min {
        left: 0;
    }

    &[data-percent="0"] .fidelity-bonus-progress-bar-current {
        display: none;
    }

    .fidelity-bonus-progress-bar-current {
        z-index: 2;
        color: $color-hl;
        background-color: $color-bg;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .fidelity-bonus-progress-bar-max {
        right: 0;
    }
}

.fidelity-dashboard-message {
    margin: $mobile-padding 0 $desktop-padding;
    padding: 1em;
    border: 1px solid;
}

/* ----------------------------------------------------------
  Orders
---------------------------------------------------------- */

/* Toolbar
-------------------------- */

.order-products-toolbar:not(.bottom) {
    display: none;
}

.aw-rma-list-toolbar.bottom,
.order-products-toolbar.bottom {
    & {
        padding-top: 2em;
        text-align: left;
    }

    .pager .pages-items {
        text-align: left;
    }

    .limiter {
        display: none;
    }
}

/* Single
-------------------------- */

%sales-order-page {
    .banner-title-wrapper > span,
    .banner-title-wrapper > div {
        margin: 0;
        text-transform: none;
        font-family: $font-main;
        font-size: $font-size;
        font-weight: normal;
        letter-spacing: 0.1em;
    }

    .column-main-inner .banner-title-wrapper {
        margin-bottom: 1em;
    }

    .items.order-links {
        @extend .desktop-grid-table;
        border-collapse: collapse;
    }

    .items.order-links li {
        border: 1px solid $color-bg-dark;
        background-color: #fff;
    }

    .items.order-links strong,
    .items.order-links a {
        display: block;
        padding: 0.5em;
        text-align: center;
    }

    .items.order-links li.current {
        background-color: transparent;
    }

    .items.order-links li:only-child {
        display: none!important;
    }

    .order-details-items .order-title {
        display: none;
    }

    .table tfoot th:first-child {
        text-align: right;
    }
}

%sales-order-page .order-details-items .actions-toolbar,
%sales-order-page .order-actions-toolbar .actions {
    & {
        margin-top: 1em!important;
    }

    & > a {
        width: auto;
        border-bottom: 1px solid;
        font-weight: 300;
    }

    & > a + a {
        margin-left: 1em;
    }
}

@media ($mobile_only) {
    %sales-order-page .items.order-links li {
        margin-bottom: 0!important;
    }

    %sales-order-page .items.order-links li + li {
        border-top: 0 solid;
    }

    %sales-order-page .order-actions-toolbar .actions > a {
        margin-bottom: 0.5em;
        border-bottom: 0 solid;
        text-decoration: underline;
    }
}

dl.order-tracking {
    & {
        margin: $mobile-padding 0;
    }

    dt,
    dd {
        display: inline-block;
        vertical-align: top;
    }

    dd {
        font-weight: bold;
    }
}

.block-order-details-view .block-content > .box {
    & {
        display: inline-block;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 49%;
        margin-bottom: 2em;
        padding-right: $mobile-padding;
        vertical-align: top;
    }

    @include resp($mobile_only) {
        width: 100%;
        padding-right: 0;
    }
}

/* Table
-------------------------- */

%bexley-table {
    & {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0;
    }

    caption {
        display: none;
    }

    .action.order {
        display: none;
    }

    .col.actions {
        text-align: right;
    }

    a {
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: 500;
        color: $color-hl;
    }

    td,
    th {
        padding: 0.75em 1em;
    }

    thead th {
        border-bottom: 1px solid $color-border;
    }

    td {
        font-weight: 300;
    }

    tbody tr:nth-child(even) td {
        background-color: $color-bg-dark;
    }
}

@media($mobile_only) {
    %bexley-table {
    tr {
        margin: 0;
        padding: 0;
        box-shadow: 0 0 0 1px $color-bg-dark;
    }

    td,
    th {
        margin-bottom: 0;
        padding: 0.5em;
    }
}
}

.table-wrapper > .table-tickets,
body:not(.page-print) .table-wrapper > .table-order-items,
.aw-rma-list,
.bexley-table,
[data-role="aw-rma-select-order"],
body:not(.page-print) #my-orders-table {
    @extend %bxl-responsive-table-selector!optional;
}

%bxl-responsive-table-selector {
    & {
        @extend %bexley-table;
        @extend %responsive-table;
    }

    .actions a {
        display: inline-block;
        padding: 0.3em;
        text-align: centers;
        background-color: lighten($color-hl,40);
    }

    .actions a:hover {
        color: #fff;
        background-color: $color-hl;
    }
}

@media ($mobile_only) {
    %bxl-responsive-table-selector td[data-th]:before {
        display: inline-block;
        margin-right: 0.3em;
    }

    %bxl-responsive-table-selector td[data-th]:not(:first-child) {
        padding-top: 0;
    }

    %bxl-responsive-table-selector td[data-th]:not(:last-child) {
        padding-bottom: 0;
    }
}

body.page-print .table-wrapper > .table-order-items,
body.page-print #my-orders-table {
    @extend %bexley-table;
}

/* ----------------------------------------------------------
  Addresses
---------------------------------------------------------- */

%address-item-actions a + a {
    margin-top: 0.5em;
}

%address-item-actions a {
    & {
        display: block;
        z-index: 1;
        position: relative;
        padding-left: 1.8em;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: $color-hl;
    }

    &:hover {
        color: $color-main;
    }

    &:before {
        z-index: 1;
        position: absolute;
        top: 0.1em;
        left: 0;
        font-size: 1.3em;
    }

    &.delete:before {
        @extend .icon;
        @extend .icon_x;
    }

    &.edit:before {
        @extend .icon;
        @extend .icon_edit;
    }

    span {
        border-bottom: 1px solid;
    }
}

/* Hide title on mobile */

@media($desktop_excluded) {
    .customer-address-index .banner-title-wrapper {
        display: none;
    }

    .customer-address-index .block-addresses-default strong {
        margin-top: 0!important;
    }
}

/* Form
-------------------------- */

.form-address-edit {
    .fieldset > .field.choice,
    .fieldset > .message.info {
        margin-top: $fieldset-margin;
    }

    .fieldset > .field.choice label {
        text-transform: none;
    }
}

.field.fax {
    display: none;
}

/* Book
-------------------------- */

.block.block-addresses-list {
    margin-top: $fieldset-margin;
}

/* ----------------------------------------------------------
  RMA
---------------------------------------------------------- */

/* Reset
-------------------------- */

[data-role="aw-rma-back-button"] {
    display: none;
}

/* Form
-------------------------- */

.item-return-details--view .fieldset {
    label {
        font-weight: bold;
    }

    .control span {
        text-transform: uppercase;
    }
}

.aw-rma__return-item-container .item-return-details .items-to-return .qty-total {
    float: none!important;
}

.aw-rma__return-massaction {
    & {
        margin-bottom: 1em;
    }

    .box-actions a {
        margin-right: 0.5em;
        border-bottom: 1px solid rgba($color-main,0.2);
    }
}

.aw-rma-new__form {
    .field.message.control {
        margin-bottom: $fieldset-margin;
    }
}

/* Upload
-------------------------- */

.aw-rma-file-uploader__container {
    margin-bottom: $fieldset-margin;
    padding: $mobile-padding;
    background-color: $color-bg-dark;
}

.aw-rma__field-note {
    margin: 1em 0;
    font-size: 0.9em;
}

.aw-rma__field + .aw-rma__field {
    margin-top: 1em;
}

.aw-rma__field[style*="display"][style*="none"] + .aw-rma__field {
    margin-top: 0;
}

.aw-rma__file-uploader-summary {
    & {
        display: inline-block;
        z-index: 1;
        position: relative;
        margin: 1em 1em 0 0;
        padding: 0.5em 0.5em 0.5em 2em;
        border: 1px solid $color-border;
        font-size: $font-size;
        font-weight: 300;
        vertical-align: top;
    }

    button {
        @extend .buttonreset;
        z-index: 1;
        position: absolute;
        top: 0.5em;
        left: 0.5em;
    }

    button span {
        display: none;
    }

    button:before {
        @extend .icon;
        @extend .icon_x;
    }
}

.rma-reason {
    margin-bottom: $fieldset-margin;
}

.rma-reason .label-main,
.aw-rma_field label[for*="rma-option-"] {
    & {
        text-transform: none;
        font-size: 1.2em;
        font-weight: 500;
    }

    &:before {
        top: 0.1em!important;
    }
}

#contact-rma-form {
    margin-bottom: $fieldset-margin;
    padding: $mobile-padding;
    background-color: $color-bg-dark;
}

#rma-store-credit-amount-message,
#rma-refund-amount-message {
    & {
        margin-bottom: $fieldset-margin;
        text-align: right;
    }

    p {
        font-size: 1.2em;
    }

    .refund-amount {
        font-weight: 500;
    }
}

.aw-rma__actions .radio_button {
    & {
        @extend %desktop-grid-table;
    }

    label {
        padding: 1em 1em 1em 3em!important;
        border: 1px solid $color-bg-dark;
        background-color: $color-bg-dark;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    label:before {
        top: 1.2em!important;
        left: 1em!important;
    }

    label:hover,
    input:checked + label {
        border-color: $color-hl;
        background-color: #FFF;
    }
}

/* View
-------------------------- */

.aw-rma__title {
    margin-bottom: 20px;
}

[data-role="aw-rma-cancel-button"] {
    @extend %bexley-button-secondary-action;
}

.aw-rma__block-thread .messages {
    margin-top: 20px;
}

.aw-rma-list-toolbar .limiter {
    display: none;
}

/* ----------------------------------------------------------
  Wishlist
---------------------------------------------------------- */

.wishlist-index-index .column {
    .product-item .actions-toolbar button + a {
        margin-top: 10px;
    }

    .product-items.list .product-item {
        padding-bottom: $mobile-padding;
    }

    #wishlist-view-form > .actions-toolbar {
        display: none;
    }

    .action.share,
    .action.update,
    .action.back {
        display: none;
    }
}

.form-wishlist-items .message.empty {
    & {
        padding-top: $mobile-padding;
    }

    & + .actions-toolbar {
        display: none;
    }
}

@media($mobile_only) {

    .wishlist-index-index .column {

        .product-item .actions-toolbar {

            button,
            a.btn-remove.action.delete {
                min-width: auto;
                padding: 1.5em 1em;
                font-size: 10px;
                line-height: 1;
                letter-spacing: 0.5px;
            }
        }
    }
}

/* ----------------------------------------------------------
  Loyalty
---------------------------------------------------------- */

.block-dashboard-loyalty-info {
    .box .box-title.fidelity-bonus-title {
        margin-bottom: 10px;
    }
}

/* ----------------------------------------------------------
  Newsletter
---------------------------------------------------------- */

.form-newsletter-manage {
    p.note {
        margin: $mobile-padding 0;
        max-width: 500px;
    }

    legend {
        margin-bottom: 0!important;
    }

    label {
        margin-top: 2em;
        margin-bottom: 0;
    }

    fieldset {
        margin-top: 2em;
    }

    #newsletter-stores {
        span{
            display: inline-block;
            z-index: 1;
            position: relative;
        }

        input[type="radio"] {
            @extend %fake-radio-ghost;
        }

        label {
            margin-top: 0;
            margin-bottom: 2em;
        }
    }
}

/* ----------------------------------------------------------
  Helpdesk
---------------------------------------------------------- */

.message.info.empty,
.table-wrapper > .table-tickets {
    margin-bottom: $mobile-padding;
}

.create_ticket_form_link,
.tickets-toolbar .limiter {
    display: none!important;
}

.ticket-close-link button {
    @extend %bexley-button;
    margin-bottom: $mobile-padding;
}

ul#aw-helpdesk-attachments-added {
    & {
        padding: 0 10px;
    }

    li a.action-item-remove:before {
        @extend .icon;
        @extend .icon_x;
        margin-left: 0.3em;
    }
}

/* ----------------------------------------------------------
  RMA
---------------------------------------------------------- */

.product-exchange-title {
    @extend %project--h5;
    margin: $mobile-padding 0 10px;
}

.aw-rma__block-general {
    .block-content {
        @extend .desktop-grid-table;
    }

    .box-title {
        text-transform: uppercase;
    }

    .shipping-address button {
        margin-top: 10px;
    }
}

.aw-rma__select-order-table {
    td button {
        min-width: 0!important;
    }

    .product.details.product-item-details {
        margin: 10px 0;
        text-transform: uppercase;
    }
}

.aw_rma-customer-selectorderstep,
.aw_rma-customer-createrequeststep {

    .product.product-item-image {
        max-width: 135px;
        max-height: 135px;
    }
}

.aw_rma-customer-view {

    .product.product-item-image {
        max-width: 75px;
        max-height: 75px;
    }
}

.aw-rma__return-item-container {
    .item-details {
        padding: 10px;
    }

    .product-details .aw-rma__field strong,
    .product-details .aw-rma__field {
        text-transform: uppercase;
        font-weight: 500;
    }

    .product-item-details {
        margin: 10px 0;
        text-transform: uppercase;
    }
}

.aw-rma__actions {
    button,
    a[class*="action"] {
        margin-bottom: 10px;
    }
}

@media($mobile_only) {
    body .aw-rma__return-item-container .item-details {
        margin-bottom: 0;
    }

    .aw-rma__return-item-container .item-details .product-details {
        z-index: 1;
        position: relative;
        padding-left: 85px;
        min-height: 75px;
    }

    .aw-rma__return-item-container .item-details .product-details .product.product-item-image {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        max-height: 75px;
        max-width: 75px;
    }

    .aw-rma__return-item-container .product-details .aw-rma__field + .aw-rma__field {
        margin-top: 5px;
    }

    .aw-rma__return-item-container .product-details .aw-rma__field,
    .aw-rma__return-item-container .product-item-details {
        margin-bottom: 5px;
        font-size: 11px;
    }

    .aw-rma__return-item-container .product-item-details {
        margin-top: 0;
    }

    .page-main .aw-rma__actions {
        display: block;
    }

    .page-main .aw-rma__actions a.action,
    .page-main .aw-rma__actions button,
    .page-main .aw-rma__view + .golden-border-container .action.primary {
        width: auto;
        margin: 0 5px 5px 0;
        padding: 1em;
        line-height: 1.2;
        white-space: normal;
    }

    .page-main .aw-rma__view + .golden-border-container .action.primary {
        margin-bottom: 10px;
    }
}

@media($tiny_only) {
    .page-main .aw-rma__actions a.action,
    .page-main .aw-rma__actions button {
        width: 100%;
        margin: 0 0 5px;
    }
}

.block.aw-rma__text-block {
    & {
        margin-bottom: $mobile-padding;
    }

    ol {
        list-style-position: inside;
    }

    li li {
        padding-left: $mobile-padding;
    }

    .aw-rma__view & {
        padding: $mobile-padding;
        background-color: #fff;
    }
}

.aw-rma__actions {
    input[type="radio"] {
        @extend %fake-radio-ghost;
    }
}

#product-exchange-list-items .product-items {
    & {
        margin: 0 $mobile-padding-neg/2;
    }

    & > * {
        display: inline-block;
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 32.6%;
        padding: 0 $mobile-padding/2 $mobile-padding;
        font-size: 12px;
        line-height: 1.4;
        vertical-align: top;
    }

    .product-item-name {
        line-height: inherit;
    }

    input[type="radio"] + label {
        z-index: 2;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
    }

    .product-size,
    .product-color {
        display: block;
        margin: 10px 0;
    }

    .product-size .label {
        display: block;
    }

    .label {
        text-transform: uppercase;
        font-weight: 300;
    }

    .product-color .label:after {
        content: ':';
    }

    .product-size .label {
        margin-bottom: 5px;
    }
}

.rma-return-type-label {
    & {
        text-align: center;
    }

    .title {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-main;
    }

    [class*="actions-toolbar"] {
        margin-top: 0!important;
        margin-bottom: 0.5em;
    }

    .action-btn {
        display: inline-block;
        padding: 0.5em;
        border: 1px solid currentColor;
        text-transform: uppercase;
        font-size: 15px;
        vertical-align: top;
        color: $color-hl;
        background-color: #fff;
    }
}

.label--refunded {
    & {
        margin-bottom: 1em!important;
    }

    strong {
        border-bottom: 1px solid;
    }
}

@media ($mobile_only) {
    #product-exchange-list-items .product-items > * {
        width: 49%;
    }
}

.change-request--mondial {
    margin-bottom: 1em;
}
