@charset "UTF-8";

/* ----------------------------------------------------------
  Home
---------------------------------------------------------- */

.cms-index-index {
    .columns,
    .column.main {
        width: 100%;
        padding: 0;
        max-width: 100%;
    }

    .product_selection {
        @extend %responsive-padding-lr;
        margin: auto;
        max-width: $content_width_wide;
    }

    .block.related.widget {
        margin: auto;
        max-width: $content_width_wide;
        margin-bottom: 60px;
    }
}

.homeblock-full {
    @extend %responsive-centered-container;
    background: transparent no-repeat center center;
    background-size: cover;
}

.homeblock-centered {
    @extend %responsive-centered-container-child;
    z-index: 1;
    position: relative;
}

.homeblock-full > .homeblock-centered {
    height: 100%;
}

.homeblock-background {
    @extend .absolute-cover;
    background: transparent no-repeat center center;
    background-size: cover;

    picture img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

@media ($mobile_only) {
    .is-letter-spacing-0-1-em-mob {
        letter-spacing: 0.1em !important;
    }
}
/* Common
-------------------------- */

.bexley-block {
    @extend %responsive-margin-tb;
    margin-top: 0;
}

.bexley-block__image img {
    width: 100%;
}

.bexley-block_cta {
    @extend %responsive-margin-tb;
    margin-top: 0;
    text-align: center;
}

/* Slide
-------------------------- */

.homeblock-full--slide {
    height: 600px;
}

@media($mobile_only) {
    .homeblock-full--slide {
        height: 320px;
        padding-right: 0;
        padding-left: 0;
    }

    .homeblock-full--slide.homeblock-full--responsive {
        height: 400px;
    }
}

.position__center,
.position__left,
.position__right {
    @extend %vertical-center-content;
    height: 100%;
}

.position__center {
    padding-right: 25%;
    padding-left: 25%;
}

.position__left {
    padding-right: 50%;
}

.position__right {
    padding-left: 50%;
}

@media($mobile_only) {
    .position__center,
    .position__left,
    .position__right {
        display: block;
        padding: $mobile-padding 0;
    }

    .position__top--mobile {
        padding-bottom: 50%;
    }

    .homeblock-full--slide > .homeblock-centered {
        padding-right: $mobile-padding;
        padding-left: $mobile-padding;
    }

    .bexley-heading__action {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .bexley-heading__action a[class*="button"] {
        width: 100%;
        max-width: 100%;
    }
}

/* Classic
-------------------------- */

.bexley-block__container {
    @extend %vertical-center-content;
    height: 100%;
}

.bexley-block--classic {
    & {
        @extend %responsive-margin-tb;
        z-index: 1;
        position: relative;
        height: 300px;
        margin-top: 0;
    }

    &.bexley-block--double {
        @extend %responsive-padding-tb;
        height: 600px;
        padding-top: 0;
    }

    &.bexley-block--triple {
        @extend %responsive-padding-tb;
        height: 900px;
    }

    .bexley-heading {
        z-index: 1;
        position: relative;
    }
}

.csblock-editor-container-wysiwyg {
    & {
        @extend %responsive-padding-tb;
        padding-top: 0;
    }

    .csblock-editor-container + .home-csblock-wysiwyg {
        @extend %responsive-padding-tb-inv;
        @extend %responsive-padding-tb-half;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

@media($desktop_excluded) {
    .bexley-block--classic {
        height: 200px;
    }

    .bexley-block--classic.bexley-block--double {
        height: 400px;
    }

    .bexley-block--classic.bexley-block--triple {
        height: 600px;
    }
}

@media($tiny_only) {
    .bexley-block--classic {
        height: 200px!important;
        padding-top: 0!important;
        padding-bottom: 0!important;
    }
}

/* Block looks
-------------------------- */

.csblock-looks__list {
    @extend %responsive-padding-lr-inv;
    @extend %responsive-padding-lr-half;
}

.csblock-looks__list .block-item {
    @extend %responsive-padding-lr-half;
    @extend %responsive-padding-tb;
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    padding-top: 0;
    vertical-align: top;
}

.csblock-looks__list .block-item__link {
    display: block;
}

.csblock-looks__list img {
    display: block;
}

.csblock-looks__list .block-item__title {
    margin-top: 1em;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.2em;
}

@media($mobile_only) {
    .csblock-looks__list .block-item {
        width: 50%;
    }

    .csblock-looks__list .block-item__title {
        font-size: 10px;
    }
}

/* ----------------------------------------------------------
  Slider
---------------------------------------------------------- */

.block__slider {
    &:not(.slick-slider) > div + div {
        display: none;
    }

    .slick-arrow {
        z-index: 1;
        position: absolute;
        top: 50%;
        font-size: 2em;
        -webkit-transform: translate3d(0,-50%,0);
        transform: translate3d(0,-50%,0);
    }

    .slick-arrow {
        text-shadow: 0 0 2px rgba(0,0,0,0.1);
        color: #FFF;
    }

    .slick-arrow:hover {
        color: $color-hl;
    }

    .slick-prev {
        left: 0.5em;
    }

    .slick-next {
        right: 0.5em;
    }
}

//
//  Slider derniers produits vus
//  _____________________________________________
.cms-index-index .admin__data-grid-outer-wrap {
    padding: 0 20px;
}

@media ($desktop-only) {
    .cms-index-index .admin__data-grid-outer-wrap {
        padding: 0 40px;
    }
}
