
@media only screen and (max-width:1024px){
    .rootmenu #rw-menutop .rootmenu-click {
        padding-left: 45px;
    }

    .rootmenu #rw-menutop .rootmenu-is-opened {
        .active_underline {
            text-decoration: underline;
        }
    }
}
