@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.press-index-index {
    .banner-title-wrapper {
        margin-bottom: 0!important;
        background-color: #FFF!important;
    }
}

/* ----------------------------------------------------------
  Grids
---------------------------------------------------------- */

.press-releases-wrapper,
.press-main__wrapper {
    z-index: 1;
    position: relative;
    margin: 0 $desktop-padding-neg/2;
}

.press-main-container {
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 32.9%;
    margin-top: 0;
    padding: 0 $desktop-padding/2 $desktop-padding;
    overflow: hidden;
    vertical-align: top;
}

@media($desktop_excluded) {
    .press-releases-wrapper,
    .press-main__wrapper {
        margin: 0 $tablet-padding-neg/2;
    }

    .press-main-container {
        width: 49%;
        padding: 0 $tablet-padding/2 $tablet-padding;
    }
}

@media($mobile_only) {
    .press-releases-wrapper,
    .press-main__wrapper {
        margin: 0;
    }

    .press-main-container {
        width: 100%;
        padding: 0 0 $mobile-padding;
    }
}

/* ----------------------------------------------------------
  Items
---------------------------------------------------------- */

.press-main-container__inner {
    & {
        text-align: center;
    }

    .date,
    .title,
    .logo {
        display: block;
        margin-top: $mobile-padding;
    }

    .image {
        min-height: 100px;
    }

    .image a {
        display: block;
        z-index: 1;
        position: relative;
        height: 0;
        padding-top: 100%;
        background-color: $color-bg;
    }

    .date {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        color: $color-hl;
    }

    .title {
        @extend %project--h4;
    }

    .logo img {
        width: 110px;
        max-width: 16%;
    }
}

.press-image-wrapper {
    & {
        z-index: 1;
        position: relative;
    }

    .press-product__inner .product-item-name {
        min-height: 0;
    }

    .press-product__inner {
        width: 100%;
    }
}

.press-product {
    & {
        @extend %parent-hover-visibility;
        z-index: 2;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: auto $mobile-padding;
        padding: $mobile-padding;
        max-height: 75%;
        overflow: auto;
        text-align: left;
        background-color: #fff;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .press-review.is-opened & {
        display: block;
        visibility: visible;
        opacity: 0.999;
    }

    @include resp($desktop_excluded) {
        display: none;
        margin: auto $mobile-padding/2;
        padding: $mobile-padding/2;
        max-height: 92%;
    }
}

.press-product__inner {
    .product-item-photo {
        margin-bottom: $mobile-padding;
    }

    button {
        width: 100%;
        margin-top: $mobile-padding;
        min-width: 0;
    }
}

.press-product__imgprice {
    & {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    & > * {
        display: table-cell;
        vertical-align: top;
    }

    & > *:first-child {
        width: 25%;
        padding-right: $mobile-padding;
    }
}
