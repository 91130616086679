@charset "UTF-8";

/* ----------------------------------------------------------
  Nav toggle
---------------------------------------------------------- */

.nav-toggle {
    & {
        display: inline-block;
        z-index: 1;
        position: relative;
        font-size: 5px;
        vertical-align: middle;
    }

    span,
    span:before,
    span:after {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
    }

    span {
        right: 0;
        bottom: 0;
        margin: auto;
    }

    span:before,
    span:after {
        content: '';
        -webkit-transform-origin: center center;
        -moz-transform-origin: center center;
        transform-origin: center center;
    }
}

/* Theme
-------------------------- */

%csscommon-navtoggle-bar {
    width: 6em;
    height: 1em;
    border-radius: 1px;
    background-color: currentColor;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
}

.nav-toggle {
    & {
        width: 6em;
        height: 5em;
        color: #000;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    span,
    span:before,
    span:after {
        @extend %csscommon-navtoggle-bar;
    }

    span:before {
        margin-top: -2em;
    }

    span:after {
        margin-top: 2em;
    }
}

/* Active
-------------------------- */

[data-bodypanel="has--opened-main-menu"] .nav-toggle span,
.has--opened-main-menu .nav-toggle span {
    & {
        height: 1px;
        background: transparent;
    }

    &:before {
        margin-top: 0;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    &:after {
        margin-top: 0;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
}
