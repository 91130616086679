@charset "UTF-8";

$has_filters_float: $desktop_excluded;
$has_filters_basic: $desktop_only;

/* ----------------------------------------------------------
  Current & reset
---------------------------------------------------------- */

.sidebar {
    .block-reorder,
    .block-wishlist,
    .filter-actions,
    .filter-subtitle {
        display: none;
    }
}

/* Title
-------------------------- */

.sidebar .filter-title {
    & {
        @extend %project--h4;
        z-index: 1;
        position: relative;
        margin-bottom: $mobile-padding;
    }

    strong {
        font-weight: inherit;
    }

    button {
        @extend %cssc-button;
        z-index: 1;
        position: absolute;
        top: -3px;
        right: 0;
    }
}

@media($has_filters_float) {
    .sidebar .filter-title {
        display: block;
    }
}

/* ----------------------------------------------------------
  Desc
---------------------------------------------------------- */

.bexley-additional-description {
    margin: 3em 0 0;
    padding-top: 2em;
    max-width: 250px;
    border-top: 1px solid $color-border;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
}

/* ----------------------------------------------------------
  List
---------------------------------------------------------- */

.filter-options-item {
    max-width: 250px;
}

@media($has_filters_basic) {
    .filter-options-item:first-child {
        padding-top: 10px;
    }
}

@media($has_filters_float) {
    .filter-options-item {
        margin: auto;
        max-width: 500px;
    }
}

.filter-options-title {
    .filter-options-item + .filter-options-item &,
    * + & {
        margin-top: $mobile-padding;
    }

    & {
        z-index: 1;
        position: relative;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    &:before,
    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 2px;
        margin: auto;
        background-color: #9C9C9C;
    }

    &:after {
        visibility: visible;
        opacity: 0.999;
        -webkit-transition: opacity 0.3s ease,visibility 0.3s ease;
        transition: opacity 0.3s ease,visibility 0.3s ease;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    .filter-options-item.active &:after {
        visibility: hidden;
        opacity: 0;
    }
}

.filter-options-content {
    & {
        padding: $mobile-padding $mobile-padding 0;
        font-weight: 300;
    }

    input[type="radio"] {
        @extend .cssc-remove-element;
    }

    .items-children {
        padding-left: 1em;
    }

    .items-children,
    .item + .item {
        padding-top: 1em;
    }

    .count {
        color: $color-hl;
    }

    .count:before {
        content: '(';
    }

    .count:after {
        content: ') ';
    }

    .am_shopby_link_selected {
        font-weight: bold;
        color: $color-hl;
    }

    .filter-count-label {
        display: none;
    }
}

/* Filters with massive categories
-------------------------- */

.has-items-level-4 {
    & {
        text-transform: uppercase;
        font-weight: bold;
    }

    span.count,
    .items-children {
        text-transform: none;
        font-weight: normal;
    }
}

/* ----------------------------------------------------------
  Sorter
---------------------------------------------------------- */

#amasty-shopby-product-list {
    position: relative;
}

#amasty-shopby-product-list .toolbar-sorter {
    & {
        z-index: 1;
        position: absolute;
        top: -60px;
        right: 0;
    }

    select {
        @extend %project--field;
        @extend %project--select;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
    }
}

.toolbar-sorter .desktop {
    display: none!important;
}


/* ----------------------------------------------------------
  Sort buttons
---------------------------------------------------------- */

#desktop-sorter {
    button {
        @extend %cssc-button;
        z-index: 1;
        float: left;
        position: relative;
        height: 40px;
        padding: 0 1.3em;
        border: 1px solid #E1E1E1;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 40px;
    }

    button.active,
    button:hover {
        z-index: 2;
        border-color: $color-main;
    }

    button.active {
        font-weight: 500;
    }

    button + button {
        left: -1px;
    }
}

/* ----------------------------------------------------------
  Button filters
---------------------------------------------------------- */

.toggle-filters-button {
    & {
        @extend %cssc-button;
        display: block;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        border-right: 1px solid $color-bg;
    }

    @include resp($desktop_only) {
        display: none;
    }
}

/* ----------------------------------------------------------
  Responsive layout
---------------------------------------------------------- */

.page-with-filter #maincontent {
    z-index: 1;
    position: relative;
}

#layered-filter-block {
    top: 0;
    right: 0;
    bottom: 130px;
    left: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: auto;
    background-color: #fff;
}

@media($has_filters_float) {
    #layered-filter-block {
        top: $mobile_menu_height;
    }
}

@media($has_filters_basic) {
    .page-with-filter .columns {
        z-index: 1;
        position: relative;
    }

    .page-with-filter .sidebar-main {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: $desktop-padding;
        width: 28%;
        margin: auto;
    }

    .page-with-filter .sidebar-main #layered-filter-block {
        z-index: 1;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
    }

    #layered-filter-block {
        max-height: 100vh;
        overflow: auto;
    }

    .has-floating-menu.page-with-filter .sidebar-main #layered-filter-block {
        max-height: calc(100vh - 165px);
        top: 165px;

    }
}

@media($has_filters_float) {
    .page-with-filter .breadcrumbs {
        display: none;
    }

    .page-with-filter #maincontent {
        z-index: 3;
        padding-top: 70px;
    }

    #layered-filter-block {
        display: none;
        z-index: 10;
        position: fixed;
        padding: $mobile-padding;
        overflow: auto;
    }

    .footer-filters-deployed #layered-filter-block {
        display: block;
    }
}

/* ----------------------------------------------------------
  Sort
---------------------------------------------------------- */

@media($has_filters_float) {
    .toggle-filters-button,
    .page-with-filter #amasty-shopby-product-list .toolbar-sorter select {
        height: 50px;
        padding: 0 $mobile-padding;
        border-bottom: 1px solid $color-bg!important;
        text-align: center;
        text-transform: uppercase;
        font: inherit;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 0.1em;
        background-color: #fff;
    }


    .page-with-filter #amasty-shopby-product-list .toolbar-sorter {
        top: -70px;
        right: $tablet-padding-neg;
        left: 50%;
    }

    .page-with-filter #amasty-shopby-product-list .toolbar-sorter select {
        padding-right: 2em!important;
        border-top: 0!important;
        border-right: 0!important;
        border-left: 0!important;
    }
}

.page.messages .messages:not(:empty) {
    margin-bottom: 0;
}

@media($mobile_only) {
    .page-with-filter #amasty-shopby-product-list .toolbar-sorter {
        right: $mobile-padding-neg;
    }
    .page-with-filter .csblock-editor-container-wysiwyg:last-child {
        margin-bottom: 70px;
    }
}

/* ----------------------------------------------------------
  Swatches
---------------------------------------------------------- */

.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option {
    float: left;
    position: relative;
    height: 20px;
    margin: 0 10px 5px 0;
    padding: 1px 2px;
    max-width: 90px;
    min-width: 30px;
    border: 1px solid #dadada;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    cursor: pointer;

    &.selected {
        outline: 2px solid $color-hl;
    }
}

.sidebar .filter-actions {
    display: block;
}

.amshopby-link-selected {
    color : $color-hl;
}

.am_shopby_apply_filters {

    background  : #fff;
    border-top  : 2px solid #E7E7E7;
    bottom      : -100vh !important;
    position: fixed;
    bottom: -80px;
    z-index: 9999;
    width: 100%;
    transition: left .2s ease;

    &.visible {
        z-index: 3;
        bottom: 0 !important;
    }

    .am-show-button {
        padding         : 17px;
        display         : flex;
        flex-direction  : column;
        background: rgb(238, 238, 238);
    }

    .reset-filter {
        padding: 0 0 18px 0;
        border: none;
        text-decoration: underline;
    }

    .amshopby-button {
        flex-basis      : 50%;
        border-radius   : 0;
        border          : none;
        padding         : 0 26px;
        height          : 60px;
        line-height     : 60px;
        font-size       : 15px;
        text-transform  : uppercase;
        text-decoration : underline;
        color           : #003224;

        &.apply-filter {
            color               : #FFF;
            text-decoration     : none;
            background          : $color-hl;

            &[disabled] {
                background:  #B8B8B8;
            }
        }
    }
}

#narrow-by-list {
    clear: left;
}

.block-actions.filter-actions,
.amshopby-filter-current {
    a {
        font-size           : 14px;
        color               : #003224;
        display             : inline-block;
        padding             : 3px;
    }
}

.block-actions.filter-actions {
    float                   : left;
    margin-right            : 10px;

    a {
        text-decoration     : underline;
        padding-left        : 0;
    }
}


.amshopby-filter-current {
    a {
        background-color    : #F5F5F5;
        position            : relative;
        margin-right        : 7px;
        margin-bottom       : 10px;
        padding-right       : 20px;
        white-space         : nowrap;

        &:before,
        &:after {
            content             : '';
            width               : 12px;
            height              : 2px;
            background-color    : #003224;
            position            : absolute;
            right               : 4px;
            top                 : 45%;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.filter-options-content {
    .items.-am-multiselect {
        .item {
            display         : flex;
            flex-direction  : row-reverse;
            justify-content : flex-end;
            align-items     : center;
        }

        input[type='checkbox'] {
            display: none;
        }

        .amshopby-choice {
            display         : inline-block;
            width           : 11px;
            height          : 11px;
            border          : 1px solid $color-hl;
            background      : #fff;
            margin-right    : 5px;
            position        : relative;
            transition      : background ease-in-out 0.3s;

            &:before,
            &:after {
                content         : '';
                height          : 2px;
                background      : #fff;
                position        : absolute;
            }
        }

        .amshopby-link-selected + input + .amshopby-choice {
            background-color: $color-hl;

            &:before {
                width       : 8px;
                transform   : rotate(-45deg);
                top         : 5px;
                right       : 0;
            }

            &:after {
                transform   : rotate(45deg);
                top         : 5px;
                width       : 5px;
                left        : 1px;
            }
        }
    }
}

#amasty-shopby-product-list .toolbar-sorter {
    z-index: 2;
}

#amasty-shopby-product-list .toolbar-sorter select {
    min-width: 250px;
}

.fake_select__container {
    position    : relative;
    z-index     : 10;
    min-width   : 250px;

    span, li {
        box-sizing          : border-box;
        width               : 100%;
        display             : block;
        padding             : 0 13px;
        height              : 39px;
        border              : 1px solid #DADADA;
        font-size           : 13px;
        line-height         : 39px;
        text-transform      : uppercase;
        font-weight         : 500;
        letter-spacing      : 0.1em;
    }
}

.fake_select__options_list {
    position    : absolute;
    right       : 0;
    top         : 39px;
    width       : calc(100% - 2px);
    background  : #fff;
    border      : 1px solid #003224;
    display     : none;
    box-shadow  : 0 0 4px 0 rgba(0,0,0,.61);

    li {

        border: none;

        &:not([data-disabled]) {
            &:hover {
                background-color: #F5F5F5;
                cursor: pointer;
            }
        }

        &.selected {
            background-color    : #F5F5F5;
            pointer-events      : none;
        }
    }

    &.is_visible {
        display: block;
    }
}

.fake_select__trigger {
    padding-right: 39px !important;
    background: #FFF no-repeat right 1em center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggZD0iTTguNTkgOS43MjVMNC44IDUuOTM2QS43MTYuNzE2IDAgMCAwIDMuNzkgNi45NDhsNC4yOTQgNC4yOTVjLjI4LjI4LjczMy4yOCAxLjAxMyAwbDQuMjk0LTQuMjk1YS43MTYuNzE2IDAgMSAwLTEuMDEyLTEuMDEyTDguNTkgOS43MjV6IiBpZD0iYSIvPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNSkiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNhIi8+PGcgbWFzaz0idXJsKCNiKSIgZmlsbD0iIzAwMkMxQyI+PHBhdGggZD0iTTAgMGgxNi43Mzh2MTYuNzM4SDB6Ii8+PC9nPjwvZz48L3N2Zz4K);

    &:hover {
        cursor: pointer;
    }
}

.filter-actions a.action.filter-clear {
    display: none;
}

@media($desktop_excluded) {

    #amasty-shopby-product-list .toolbar-sorter select {
        background-image: none;
        min-width: auto;
    }

    .amshopby-filter-current {
        display     : flex;
        overflow    : scroll;
    }

    #narrow-by-list {
        padding-top: 15px;
    }

    .fake_select__container {
        max-width       : 50vw;
        min-width       : auto;
        border-bottom   : 1px solid #F8F8F8 !important;

        .fake_select__trigger {
            width           : 50vw;
            border          : none;
            background      : none;
            height          : 50px;
            line-height     : 50px;
            text-align      : center;
            font-weight     : bold;
            padding-right   : 13px !important;
        }
    }

    .fake_select__options_list {
        top: 50px;
    }

    .sidebar-main {
        a.action.filter-clear {
            display: none !important;
        }
    }
}

#mobileFilterSummaryContainer {
    margin-bottom: 25px;

    a.filter-clear {
        background-color: transparent;
        text-decoration: underline;

        &:after,
        &:before {
            display: none;
        }
    }
}

.itemsSpinner,
.itemsSpinner:after {
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.itemsSpinner {
    display: none;
    position: relative;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    top: 7px;
}

body.ajax-loading {
    .itemsSpinner {
        display: inline-block;
    }

    .am-items {
        display: none;
    }
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media($desktop_only) {
    .am_shopby_apply_filters {
        display: none !important;
    }

    .sidebar {
        .filter-title .toggle-filters-button-off {
            display: none;
        }

        .filter-title {
            font-size       : 20px;
            letter-spacing  : 0.15em;
        }
    }
}

@media($mobile_only){
    body:not(.footer-filters-deployed){
        .am_shopby_apply_filters{
            bottom: -1000px !important;
        }
    }
}
