@charset "UTF-8";

/* ----------------------------------------------------------
  Elements visibility depending on used device
---------------------------------------------------------- */

.lt_ie9 .hidden-on-full,
.lt_ie9 .visible-only-tablet,
.lt_ie9 .visible-only-phone {
    display: none;
}

@media(min-width:1024px) {
    .hidden-on-full,
    .visible-only-tablet,
    .visible-only-phone {
        display: none;
    }
}

@media(max-width:1023px) and (min-width:501px) {
    .hidden-on-tablet,
    .visible-only-full,
    .visible-only-phone {
        display: none;
    }
}

@media(max-width:500px) {
    .hidden-on-phone,
    .visible-only-full,
    .visible-only-tablet {
        display: none;
    }
}
