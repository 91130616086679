@charset "UTF-8";

/* ----------------------------------------------------------
  Fake Select
---------------------------------------------------------- */

.fakeselect--bxl {
    text-align: left;
    line-height: 2;
}

.fakeselect--bxl,
.fakeselect--bxl * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fakeselect--bxl .fakeselect-cover {
    @extend %project--field;
    @extend %project--select;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #fff;
    cursor: pointer;
}

.fakeselect--bxl .fakeselect-cover:after {
    display: none;
}

.fakeselect--bxl .fakeselect-list,
.fakeselect--bxl .fakeselect-list li {
    font: inherit;
}

.fakeselect--bxl .fakeselect-list {
    right: -1px;
    left: -1px;
    max-height: 200px;
    border: 1px solid #e0e0e0;
    border-top: 0;
    overflow: auto;
    background-color: #f0f0f0;
}

.fakeselect--bxl .fakeselect-list li {
    padding: 0 0.5em;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.fakeselect--bxl .fakeselect-list li[data-current="1"],
.fakeselect--bxl .fakeselect-list li:hover {
    background-color: #e0e0e0;
}

.fakeselect--bxl .fakeselect-list li[data-disabled="1"] {
    color: #777;
    background-color: transparent!important;
    cursor: default;
    cursor: disabled;
}

.fakeselect--bxl .fakeselect-list li.optgroup-label {
    font-style: italic;
    font-weight: bold;
}

.fakeselect--bxl .fakeselect-list li.optgroup-label:hover {
    background: none;
    cursor: default;
}

.fakeselect--bxl .fakeselect-list li[data-optgroup="1"] {
    padding-left: 2em;
}

/* Flags
-------------------------- */

.fakeselect--bxl .fakeselect-list li,
.fakeselect--bxl .fakeselect-cover {
    & {
        z-index: 1;
        position: relative;
        padding-left: $font-size*3.5;
        font-weight: normal;
        letter-spacing: 0;
    }

    span[class*="flag"] {
        z-index: 1;
        position: absolute;
        top: 7px;
        left: $font-size;
    }

    em {
        margin-left: 0.3em;
        font-style: normal;
        font-weight: 300;
        color: #999;
    }
}

.fakeselect--bxl .fakeselect-list li {
    span[class*="flag"] {
        top: 2px;
    }
}
