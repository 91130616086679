@charset "UTF-8";

/* ----------------------------------------------------------
  Config
---------------------------------------------------------- */

$content_width:0px;
$content_width_wide:1580px;

/* Fonts
-------------------------- */

$font-main: 'Roboto', "Arial", sans-serif;
$font-second: 'Montserrat', "Arial", sans-serif;
$font-size: 13px;
$line-height: 1.3;
$line-height-em: 1.3em;
$fieldset-margin: 2em;
$letter-spacing-base: 0.08em;
$letter-spacing-title: 0.25em;

/* Margins
-------------------------- */

$mobile-padding: 20px;
$mobile-padding-neg: -20px;
$tablet-padding: 30px;
$tablet-padding-neg: -30px;
$desktop-padding: 40px;
$desktop-padding-neg: -40px;
$border-radius: 3px;

/* Basic colors
-------------------------- */

$color-main: #003224;
$color-hl: #b49770;
$color-hl-dark: #896E49;
$color-hl-light: #957B29;
$color-border: #DADADA;
$color-bg: #F8F8F8;
$color-bg-dark: #F0F0F0;
$color-red: #9e3223;

/* Social colors */
@import "csscommon/components/vars/colors";

/* Vendor colors */
$color-oney: #81BC00;

/* Breakpoints
-------------------------- */

$mobile-max: 768px;
$tablet-max: 1024px;

@import "csscommon/components/vars/breakpoints";
@import "csscommon/components/vars/mediaqueries";

/* Custom breakpoints */
$has_mobile_menu: $desktop_excluded;
$has_desktop_menu: $desktop_only;

/* Sizes */
$mobile_menu_height:75px;

/* Compass values
-------------------------- */

$critical-usage-threshold: 2;
$graceful-usage-threshold: 2;

/* ----------------------------------------------------------
  CSSCommon
---------------------------------------------------------- */

@import "csscommon/components/default";
@import "csscommon/components/common";

/* Modules
-------------------------- */

/* Content */

@import "csscommon/components/content/text";

/* Buttons */

@import "csscommon/components/buttons/core";

/* Forms */

@import "csscommon/components/forms/core";
@import "csscommon/components/forms/fake-check";
@import "csscommon/components/forms/fake-check-ghost";

/* Various */

@import "csscommon/components/tables";
@import "csscommon/components/grid";
@import "csscommon/components/layouts/containers";
@import "csscommon/components/layouts/responsive-visibility";
@import "csscommon/components/print";
@import "csscommon/components/mixins/common";
@import "csscommon/components/common/navtoggle";

/* ----------------------------------------------------------
  Integento
---------------------------------------------------------- */

@import "integento/components/layouts/core";
@import "integento/components/forms/core";
@import "integento/components/tables/core";
@import "integento/components/common/core";
@import "integento/components/common/reinsurance";
@import "integento/components/common/modals";
@import "integento/components/catalog/core";
@import "integento/components/catalog/view";
@import "integento/components/checkout/core";
@import "integento/components/checkout/minicart";
@import "integento/components/customer/core";
@import "integento/components/navigation/core";

/* ----------------------------------------------------------
  Bexley
---------------------------------------------------------- */

/* Tools */
@import "bexley/icons";
@import "bexley/forms";
@import "bexley/plugins";
@import "bexley/layout/flags";
@import "bexley/layout/fakeselect";

/* Layout */
@import "bexley/base";
@import "bexley/header";
@import "bexley/footer";
@import "bexley/print";
@import "bexley/layout/rootway_megamenu";

/* Popins */
@import "bexley/popins/arrival";
@import "bexley/popins/oney";
@import "bexley/popins/abtasty";

/* Checkout */
@import "bexley/checkout/cart";
@import "bexley/checkout/checkout";
@import "bexley/checkout/chronorelais";
@import "bexley/checkout/success";

/* Catalog */
@import "bexley/catalog/toolbar";
@import "bexley/catalog/filters";
@import "bexley/catalog/grid";
@import "bexley/catalog/item";
@import "bexley/catalog/view";
@import "bexley/catalog/gallery";
@import "bexley/catalog/look";
@import "bexley/catalog/stock-by-shop";
@import "bexley/catalog/marketing-conditions";
@import "bexley/catalog/fibbl";

/* Customer */
@import "bexley/customer/public";
@import "bexley/customer/loggedin";

/* Content */
@import "bexley/content";
@import "bexley/content/home";
@import "bexley/content/storelocator";
@import "bexley/content/pressreleases";
@import "bexley/content/pressreviews";
@import "bexley/content/contact";
@import "bexley/content/faq";
@import "bexley/content/epoq";

/* Search */
@import "bexley/mirasvit-autocomplete";

/* Amastry_Promo*/
@import "bexley/ampromo/ampromo";

/* Bexley ClickAndCollect */
@import "bexley/clickandcollect/fiche_produit";
@import "bexley/clickandcollect/checkout";
@import "bexley/clickandcollect/login_as_customer";
