// PRODUCT PAGE
.column.main #epoq-backup-related {
  display : none;
}

// PRODUCT PAGE - POPIN
#epoq-widget-cartlayer {
  .products.list {
    margin : 0;

    &:not(.slick-initialized) .product-item:nth-child(1n+2),
    .product-item-link {
      display: none;
    }
  }
}

// ALL
.epoq-widget-slider {
  .bexley-title-lvl3,
  .epoq-rs-headline {
    font-family : $font-main;
  }

  .bxprice-mc {
    width : 100%;
  }
}

// CHECKOUT
#checkout-cart-epoq {

  // > 1500
  @media($large_only) {
    min-height : 571.81px;
    max-height : 571.81px;
  }

  // > 1300
  @media($desktopmid_only) {
    margin     : 40px 0 0;
    min-height : 555.14px;
    max-height : 555.14px;
  }

  // > 1024
  @media($desktop_only) {
    min-height : 565.75px;
    max-height : 565.75px;
  }

  // > 905
  @media(min-width : 905px) {
    min-height : 550px;
    max-height : 550px;
  }

  // > 768
  @media(min-width : 768px) {
    margin     : 30px 0 0 0;
    min-height : 605px;
    max-height : 605px;
  }

  //  768
  @media(min-width : 768px) and (max-width : 768px) {
    min-height : 460px;
    max-height : 460px;
  }

  // < 767
  @media(max-width : 767px) {
    margin     : 30px 0 0;
    min-height : 563px;
    max-height : 563px;
  }

  // < 630
  @media(max-width : 630px) {
    min-height : 477px;
    max-height : 477px;
  }

  // < 500
  @media($tiny_only) {
    min-height : 419.92px;
    max-height : 419.92px;
  }

  // 360
  @media (max-width : 360px) {
    min-height : 397px;
    max-height : 397px;
  }

  #epoq-widget-cart {
    margin : 0;
  }
}




