#fibblContainer {
    left: 0;
    transform: translateX(0);
    margin: 0;

    button {
        appearance: none;
        background: #fff;
        display: inline-block;
        padding: 5px 7px;
        font-weight: bold;
        font-size: 11px;
        border: 1px solid #FFF;
        border-radius: 3px;
        color: $color-hl;

        &.fibbl-active {
            background: $color-hl;
            border-color: $color-hl;
            color: #fff;
        }
    }

    .fibbl-toggle-buttons {
        display: flex;
        max-width: fit-content;
        border-radius: 5px;
        border: 1px solid $color-hl;
    }
}



@media($desktop_only) {
    fibbl-bar {
        display: block;
        float: right;
        width: auto;
        margin-top: -25px;
        z-index: 3;
        position: relative;
    }

    #fibblContainer {
        button {
            padding: 5px 10px;
            font-size: 17px;
        }
    }
}

@media($desktop_excluded) {
    fibbl-bar {
        display: none !important;
    }
}

.fibbl-try-virtualy {
    display: flex;
    button {
        appearance: none;
        background: transparent;
        border: none;
        color: $color-main;
    }
}
