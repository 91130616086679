.checkout-index-index {
    .page-header {
        z-index: 1;
    }
}

.col-carrier {
    img {
        display: none;
    }
}

.chrono_relais__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    z-index: 100;
    flex-direction: column;
}

.chrono_relais__content {
    padding: 20px;
    background: #fff;
    width: 920px;
    max-width: 100vw;
    overflow-y: auto;

    .actions {
        text-align: center;
    }
}

.chrono_relais__content .button,
.chrono_relais__content button.action,
#chrono_relais_open_modal {
    padding: 0 1em;
    min-width: 20em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 4.16;
    letter-spacing: 0.15em;
    color: #fff;
    background-color: #b49770;
    transition: all 0.3s ease;
    border: none;
}

#chrono_relais_open_modal {
    display: inline-block;
    margin: 15px auto;
}


.opc-block-summary {
    z-index: 0;
}

tr.chronomap_container {
    margin-top: 15px;
    z-index: 10;
    li {
        position: relative;
        padding: 8px 0;
    }

    input[type="radio"] {

        & + label {
            &:before {
                top: 4px;
                left: 0;
            }

            &:after {
                display: none;
            }
        }
    }
}

@media($mobile_only) {
    .chrono_relais__container {
        justify-content: flex-start;
    }

    .chrono_relais__content {
        display: flex;
        flex-direction: column;
        height: 100svh;
    }

    .sp-methods-chronorelais {
        overflow: auto;
    }
}

.chronorelaismap .chronomap {
    height: 320px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.mappostalcode {
    .input-text {
        padding     : 9.75px 13px;
        height      : 31px;
        border      : 1px solid #DADADA;
        font-size   : 13px;
        line-height : 19.5px;
    }


}

.leaflet-popup-content {
    h2 {
        display: none;
    }
}

.sw-map-adresse-wrp {
    padding: 0;

    h2 {
        display: block;
        font-size: 18px;
        font-weight: normal;
    }
}

#sw-table-horaire {
    tr {
        padding: 0;
        background:  #fff;


        & + tr {
            margin-top: 3px;
        }

        td {
            font-size: 10px;
        }
    }
}

.chronorelais_sumary {
    margin-top: 40px;
    padding: 20px;
    border: 1px solid #b49770;
}

.chronomap_container {
    z-index: 10;
}

@media($mobile_only) {
    .chronomap_container {
        padding: 10px;
    }

    .chronorelaismap .chronomap {
        display: none;
    }

    .mappostalcode{
        display         : flex;
        margin-bottom   : 15px;
        flex-direction  : column;

        .input-text  {
            width: auto;
        }
    }

    .chrono_relais__content {
        max-width  : 85vw;
    }
}

@media($desktop_excluded) {
    .chrono_relais__content {
        max-height : calc(100vh - 150px);
        margin     : auto;
    }
}
@media($tiny_only) {
    .chrono_relais__content {
        max-height : calc(100vh - 70px);
    }
}

.horaires_mobile {
    cursor : auto;

    &_content {
        position        : fixed;
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        background      : #fff;
        color           : #333;
        font-weight     : bold;
        text-align      : left;
        margin          : 0 0 0 -180px;
        width           : 350px;
        left            : 50%;
        top             : 0;
        border          : 1px solid #d4d4d4;
        border-top      : 0;
        box-shadow      : 0 0 6px #ccc;
        z-index         : 10000;
        transform       : translateY(-1000px);
        transition      : all ease-in-out 0.3s;

        @media($mobile_only) {
            width  : 314px;
            margin : 0 0 0 -158px;
        }

        #sw-table-horaire {
            width      : max-content;
            margin     : 0 auto;
            text-align : left;

            tr td {
                font-size  : 13px;
            }
        }

        .close {
            display          : inline-block;
            margin           : 15px auto 10px;
            background-color : #b49770;
            color            : #fff;
            padding          : 5px 10px;
            cursor           : pointer;
        }
    }

    &.is-open .horaires_mobile_content {
        transform  : translateY(0);
        transition : all ease-in-out 0.3s;
    }

    &_trigger {
        border           : none;
        padding          : 5px 10px;
        background-color : #b49770;
        color            : #fff;
        margin-top       : 5px;
        display          : inline-block;
        cursor           : pointer;
    }

    h3 {
        color            : #fff;
        background-color : #b49770;
        padding          : 10px;
        margin           : 0 -1px 15px;
    }
}

#chrono_relais_current_selection_none {
    color: #9e3223;
}
