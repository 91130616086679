@charset "UTF-8";

$content_width_wide: 940px!default;
$desktop-padding: 45px !default;
$desktop-padding-neg: -45px !default;
$tablet-padding: 30px !default;
$tablet-padding-neg: -30px !default;
$mobile-padding: 15px !default;
$mobile-padding-neg: -15px !default;

/* ----------------------------------------------------------
  Containers
---------------------------------------------------------- */

/* Container centré */

%responsive-centered-container,
.centered-container {
    @extend %responsive-padding-lr!optional;
    z-index: 1;
    clear: both;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

/* Contenu flottant */

%responsive-centered-container-child,
.centered-container > * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: auto;
    margin-right: auto;
    margin-left: auto;
    max-width: $content_width_wide;
    text-align: left;
}

/* Items
-------------------------- */

.centered-container .cc-full {
    width: auto;
    max-width: none;
}

.centered-container .cc-full-bg,
.centered-container .cc-full-image {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    background: transparent repeat top center;
}

/* Full image is a separate background with a cover image for animating or effects */

.centered-container .cc-full-image {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

/* Needed to be over a cc-full-image/bg background */

.centered-container .cc-content {
    z-index: 2;
    position: relative;
}

