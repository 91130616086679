@charset "UTF-8";

/* ----------------------------------------------------------
  Buttons
---------------------------------------------------------- */

%bexley-button {
    & {
        @extend %cssc-button;
        padding: 0 1em;
        min-width: 20em;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        line-height: 4.16;
        letter-spacing: 0.15em;
        color: #fff;
        background-color: $color-hl;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    &:focus,
    &:hover {
        color: #fff;
        background-color: $color-hl-dark;
        box-shadow: inset 0 0 0 1px $color-hl-dark;
    }
}

%bexley-button--secondary {
    & {
        color: $color-hl;
        background-color: transparent;
        box-shadow: inset 0 0 0 1px currentColor;
    }

    &:focus,
    &:hover {
        color: $color-hl-dark;
        background-color: $color-bg-dark;
        box-shadow: inset 0 0 0 1px $color-bg-dark;
    }
}

%bexley-button--third {
    & {
        color: $color-main;
    }
}

%bexley-button--fourth {
    & {
        color: #FFF;
        background-color: $color-hl;
        box-shadow: inset 0 0 0 1px #FFF;
    }

    &:focus,
    &:hover {
        color: $color-hl-dark;
        background-color: $color-bg-dark;
        box-shadow: inset 0 0 0 1px $color-bg-dark;
    }
}

/* Sizes
-------------------------- */

.actions-toolbar .primary .bexley-button--small,
.actions-toolbar .secondary .bexley-button--small,
.bexley-button--small,
%bexley-button--small {
    min-width: 10em;
    font-size: 10px;
}

/* Selectors
-------------------------- */

.bexley-button,
.action.update,
.action.tocart,
.action-accept,
.action.viewcart,
.action.action-gotocart,
.action.action-update,
.action.action-select-shipping-item,
.modal-popup.agreements-modal .action.action-hide-popup,
.action.action-apply,
.action.action-login,
.action.primary {
    @extend %bexley-button;
}

%bexley-button-secondary-action,
.form-wishlist-items .action.delete,
.action.share,
.action-dismiss,
.action.secondary.viewcart,
.action.action-edit-address,
.action.action-continueshopping,
.aw-gc-product-form-options__preview button,
.action.edit-address-link,
.modal-popup:not(.agreements-modal) .action.action-hide-popup,
.action.action-cancel,
.actions-toolbar .secondary .action,
.bexley-button--secondary.action {
    @extend %bexley-button;
    @extend %bexley-button--secondary;
}

%bexley-button-third-action {
    @extend %bexley-button;
    @extend %bexley-button--third;
}

.action-dismiss,
.action-accept {
    @extend %bexley-button--small;
}

/* Specific fixes
-------------------------- */

@media($mobile_only) {
    .homeblock-centered .bexley-button {
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        line-height: 1.5;
        white-space: normal;
    }
}

/* ----------------------------------------------------------
  Forms
---------------------------------------------------------- */

/* Form items */

%project--fieldgroup {
    & {
        margin-bottom: 2em;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

%project--legend {
    display: block;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-family: $font-second;
    font-size: 16px;
    font-weight: 600;
}

%project--label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

%project--field,
%project--select {
    @extend .inputreset;
}

%project--field {
    & {
        padding: $font-size*0.75 $font-size*1;
        min-height: $font-size*3;
        border: 1px solid $color-border;
        font-size: $font-size;
        line-height: $font-size*1.5;
    }

    &:focus {
        border-color: $color-main;
    }

    &.mage-error {
        border-color: $color-red;
    }
}

@media($mobile_only) {
    %project--field {
        font-size: 16px;
    }
}

%project--select {
    @extend .cssc-select;
    padding-right: 3*$font-size!important;
    background: #FFF no-repeat right 1em center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggZD0iTTguNTkgOS43MjVMNC44IDUuOTM2QS43MTYuNzE2IDAgMCAwIDMuNzkgNi45NDhsNC4yOTQgNC4yOTVjLjI4LjI4LjczMy4yOCAxLjAxMyAwbDQuMjk0LTQuMjk1YS43MTYuNzE2IDAgMSAwLTEuMDEyLTEuMDEyTDguNTkgOS43MjV6IiBpZD0iYSIvPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNSkiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNhIi8+PGcgbWFzaz0idXJsKCNiKSIgZmlsbD0iIzAwMkMxQyI+PHBhdGggZD0iTTAgMGgxNi43Mzh2MTYuNzM4SDB6Ii8+PC9nPjwvZz48L3N2Zz4K);
}

/* Placeholder */

@mixin project-placeholder() {
    color: inherit;
    opacity: 0.5;
}

%project--field {
    &::-webkit-input-placeholder {
        @include project-placeholder;
    }

    &:-moz-placeholder {
        @include project-placeholder;
    }

    &::-moz-placeholder {
        @include project-placeholder;
    }

    &:-ms-input-placeholder {
        @include project-placeholder;
    }

    &::placeholder {
        @include project-placeholder;
    }
}

/* Radio - Checkboxes */

%fake-checkbox-skin {
    top: 0.15em;
    border: 1px solid #C1C1C1!important;
    color: transparent;
    background-color: #fff;
}

%fake-checkbox-skin-checked {
    border-color: $color-hl!important;
    color: $color-hl!important;
}

%fake-check-ghost,
%fake-radio-ghost {
    & + label:before {
        @extend %fake-checkbox-skin;
    }

    &:checked + label:before {
        @extend %fake-checkbox-skin-checked;
    }

    & ~ .label-main {
        padding-left: 2em;
    }

    & ~ .label-main:before {
        @extend %fake-checkbox-skin;
        top: 0.05em;
    }

    &[type="checkbox"].mage-error:not(:checked) ~ .label-main:before {
        border-color: $color-red!important;
    }

    &:checked ~ .label-main:before {
        @extend %fake-checkbox-skin-checked;
    }
}

%fake-radio-ghost {
    & ~ .label-main:before,
    & + label:before {
        box-shadow: inset 0 0 0 3px rgba(#FFF,0);
    }

    &:checked .label-main:before,
    &:checked + label:before {
        box-shadow: inset 0 0 0 3px rgba(#FFF,0.999), inset 0 0 0 15px rgba($color-hl,0.999);
    }
}

.aw-rma_field,
.product-item-info,
.field-name-prefix {
    input[type="radio"] {
        @extend %fake-radio-ghost;
    }
}

.field-name-prefix .control {
    label {
        padding-right: 2em;
    }

    span {
        display: inline-block;
        z-index: 1;
        position: relative;
    }

    div.mage-error {
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -1em;
        white-space: nowrap;
    }
}

.cart-summary .item-options input[type="radio"] {
    & {
        @extend %fake-radio-ghost;
    }

    & + label:before {
        top: 0.2em!important;
    }
}

.am_shopby_filter_items_attr_bxl_size {
    input[type="checkbox"] {
        @extend %fake-check-ghost;
    }

    .label {
        display: inline-block!important;
    }

    .label:before {
        top: 0.2em!important;
    }
}

/* Selectors
-------------------------- */

fieldset.fieldset {
    legend {
        @extend %project--legend;
    }
}

.control,
.field {
    & + &,
    .nested {
        margin-top: 2em;
    }

    legend.label,
    label {
        @extend %project--label;
    }

    select,
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"] {
        @extend %project--field;
    }

    select {
        @extend %project--select;
    }
}

.field > .help {
    & {
        padding-top: 0.5em;
        text-align: right;
        font-weight: 300;
        letter-spacing: 0;
    }

    a {
        text-decoration: underline;
    }
}

.field.additional {
    margin-top: 1em;
}

.field._error {
    select,
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"] {
        border-color: $color-red;
    }
}

div.choice input[type="checkbox"] {
    @extend %fake-check-ghost;
}

div.control {
    overflow: visible;
}

/* Dob
-------------------------- */

.customer-dob {
    & {
        z-index: 1;
        position: relative;
    }

    button {
        @extend .buttonreset;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        padding: 0 1em;
        font-size: 16px;
        line-height: 2.2;
        color: $color-hl;
    }

    button:before {
        @extend .icon;
        @extend .icon_calendar;
    }

    button span {
        display: none;
    }
}

/* Fix for errors in choice
 * error message between input & label
-------------------------- */

.field.choice.required {
    & {
        z-index: 1;
        position: relative;
    }

    input + div.mage-error {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    input + div.mage-error + label {
        padding-bottom: 2em;
    }
}

/* Action toolbar +
-------------------------- */

.actions-toolbar--centered {
    margin-bottom: $fieldset-margin;
    text-align: center;
}


/* bugfix */
.form-shipping-address {
    .field.choice {
        margin-top: 2em;
    }
}

/* Fix iOS 15 blue color
-------------------------- */
select, button {
    color: $color-main;
}