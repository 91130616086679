@charset "UTF-8";

body {
    min-height: 100vh;
    font: $font-size $font-main;
    line-height: $line-height;
    letter-spacing: $letter-spacing-base;
    color: $color-main;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.centered-container {
    padding: 0 $desktop-padding;
}

.centered-container > * {
    max-width: $content_width_wide;
}

@media($mobile_only) {
    .centered-container {
        padding: 0 $mobile-padding;
    }
}

/* ----------------------------------------------------------
  Links
---------------------------------------------------------- */

%main-link,
a {
    color: $color-main;
}

%main-link:hover,
%main-link:focus,
a:focus,
a:hover {
    color: $color-hl;
}

%main-link:active,
a:active {
    color: darken($color-hl,20);
}

label a {
    border-bottom: 1px solid rgba($color-main,0.3);
}

/* ----------------------------------------------------------
  Selection style
---------------------------------------------------------- */

::-moz-selection {
    color: $color-main;
    background: rgba($color-hl,0.5);
}

::selection {
    color: $color-main;
    background: rgba($color-hl,0.5);
}

/* ----------------------------------------------------------
  Titles
---------------------------------------------------------- */

/* Common
-------------------------- */

%project--title {
    text-transform: uppercase;
    font-family: $font-second;
    font-weight: 500;
    letter-spacing: $letter-spacing-title;
}

// pr éviter un souci de compilation app/design/frontend/Bexley/default/styles/bexley/catalog/_view.scss:135
.project--title {
    @extend %project--title;
}

/* Native
-------------------------- */

%project--h1,
%project--h2,
%project--h3,
%project--h4,
%project--h5,
%project--h6 {
    @extend %project--title;
}

%project--h1 {
    & {
        font-size: 45px;
    }

    @include resp($desktop_excluded) {
        font-size: 30px;
    }

    @include resp($mobile_only) {
        font-size: 18px;
    }
}

%project--h2 {
    & {
        font-size: 30px;
    }

    @include resp($mobile_only) {
        font-size: 16px;
    }
}

.bexley-title-lvl3,
%project--h3 {
    & {
        font-size: 26px;
    }

    @include resp($mobile_only) {
        font-size: 14px;
    }
}

%project--h4 {
    font-size: 1.4em;
}

%project--h5 {
    font-size: 1.2em;
}

/* Header
-------------------------- */

.bexley-title-lvl2-bold,
.bexley-title-lvl2 {
    @extend %project--h2;
}

.bexley-title-lvl2-bold {
    font-weight: 600;
}

.bexley-title-lvl3 {
    @extend %project--h3;
}

.bexley-subtitle,
%bexley-subtitle,
%bexley-subtitle-lvl2,
.bexley-subtitle-lvl2 {
    & {
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15em;
        color: $color-hl;
    }

    @include resp($mobile_only) {
        font-size: 12px;
    }
}

@media($mobile_excluded) {
    %bexley-subtitle-lvl2,
    .bexley-subtitle-lvl2 {
        font-size: 14px;
    }

    %bexley-subtitle-lvl2 .price,
    .bexley-subtitle-lvl2 .price {
        font-size: 18px;
    }
}

/* ----------------------------------------------------------
  Text
---------------------------------------------------------- */

p,
ul {
    & {
        font-size: $font-size;
        line-height: $line-height;
    }

    &:not(:last-child) {
        margin-bottom: $line-height-em;
    }
}

/* ----------------------------------------------------------
  Project padding
---------------------------------------------------------- */

%project--padding {
    padding-top: 50px;
    padding-bottom: 50px;
}

@media($mobile_only) {
    %project--padding {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

/* ----------------------------------------------------------
  Icons
---------------------------------------------------------- */

.icon,
.icon:before {
    display: inline-block;
    text-align: center;
}

.icon_twitter:before {
    width: 1.3em;
}

/* ----------------------------------------------------------
  Breadcrumbs
---------------------------------------------------------- */

.breadcrumbs {
    & {
        @extend %responsive-padding-tb;
        font-weight: 300;
    }

    strong {
        color: $color-hl;
    }

    .item + .item:before {
        content: '|';
        margin: 0 0.3em 0 0.6em;
        opacity: 0.60;
    }
}

/* ----------------------------------------------------------
  Product list : slider
---------------------------------------------------------- */

.block.product_selection {
    & {
        @extend .bexley-heading;
    }

    .block-title [role="heading"] {
        @extend .bexley-title-lvl3;
    }

    .block-subtitle [role="label"] {
        @extend .bexley-subtitle-lvl2;
        margin-bottom: 2em;
    }
}

.product-items--slideme {
    .slick-arrow {
        z-index: 1;
        position: absolute;
        top: 20%;
        font-size: 20px;
    }

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }
}

/* ----------------------------------------------------------
  Prices
---------------------------------------------------------- */

.price-box {
    & {
        font-weight: 300;
        line-height: 1;
    }

    & > * {
        display: inline-block;
        margin-right: 0.3em;
        vertical-align: middle;
    }

    .old-price {
        font-size: 0.72em;
    }

    .new-price {
        font-weight: 400;
        color: $color-red;
    }

    .sales-label {
        padding: 0 0.3em;
        text-transform: uppercase;
        font-size: 0.5em;
        font-weight: 500;
        line-height: 1.6;
        color: #FFF;
        background-color: $color-red;
    }

    .special-banners {
        display: flex;
        width: 100%;
        margin-top: 10px;

        .product-info-payment {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}

/* ----------------------------------------------------------
  Qty
---------------------------------------------------------- */

.soon-qty-updater-wrap {
    display: flex;
    & {
        z-index: 1;
        position: relative;
        font-size: 12px;
    }

    div.mage-error {
        z-index: 1;
        position: absolute;
        top: 100%;
        left: 0;
    }

    .dec,
    .inc,
    input[type="number"] {
        width: 3em;
        height: 3em;
        text-align: center;
        font: inherit;
        line-height: 3;
        vertical-align: middle;
    }

    .dec,
    .inc {
        display: inline-block;
        font-weight: bold;
        color: $color-hl;
        box-shadow: inset 0 0 0 1px $color-border;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .dec:hover,
    .inc:hover {
        box-shadow: inset 0 0 0 1px $color-hl;
    }

    input[type="number"] {
        @extend .inputreset;
        line-height: 2.6;
        background-color: transparent;
        box-shadow: inset 0 2px 0 -1px $color-border,inset 0 -2px 0 -1px $color-border;
    }
}

/* ----------------------------------------------------------
  Modals
---------------------------------------------------------- */

.modals-wrapper .modal-header .modal-title {
    @extend %project--h3;
}

.modals-wrapper .modal-header {
    height: 6em;
    line-height: 6em;
}

.modals-wrapper * + .modal-content {
    border-top: 6em solid transparent;
}

.modals-wrapper .modals-overlay {
    background: rgba(#3F3F3F,0.5);
}

/* Items
-------------------------- */

.modals-wrapper .modal-header .action-close {
    & {
        height: 1.1em;
        font-size: 20px;
        line-height: 1;
        color: $color-hl;
    }

    &:hover {
        color: $color-main;
    }

    &:focus {
        color: $color-hl;
    }

    span {
        display: none;
    }

    &:before {
        @extend .icon;
        @extend .icon_x;
    }
}

.modal-footer {
    .action-accept {
        &:focus {
            background-color: $color-hl;
        }
    }
}


/* Confirm modals
-------------------------- */

.modal-popup.confirm {
    .modal-header {
        height: 3em;
        line-height: 3em;
    }

    * + .modal-content {
        border-top: 3em solid transparent;
    }

    .modal-content {
        padding-top: $mobile-padding;
        padding-bottom: $mobile-padding;
    }

    .modal-footer {
        text-align: center;
    }
}

/* Auth modal
-------------------------- */

.popup-authentication {
    .modal-content {
        background-color: #F9F9F9;
    }

    .modal-header {
        height: 1em;
        line-height: 1em;
        background-color: #F9F9F9;
    }

    * + .modal-content {
        border-top: 1em solid #F9F9F9;
    }

    .block-title {
        @extend %project--legend;
    }

    .block-title strong {
        font-weight: inherit;
    }

    .block-authentication {
        @extend %desktop-grid-table;
    }
}

@media($desktop_excluded) {
    .popup-authentication .block-authentication {
        display: flex;
        flex-direction: column-reverse;
    }

    .popup-authentication .block-authentication > * {
        display: inherit!important;
        flex-basis: auto;
    }

    .popup-authentication .block-new-customer {
        padding-top: $mobile-padding;
        border-top: 1px solid $color-border;
    }

    .popup-authentication .block-customer-login {
        padding-bottom: $mobile-padding;
        padding-left: 0!important;
    }

    .popup-authentication .block-title {
        text-align: center;
        font-weight: normal;
        color: $color-hl;
    }

    .popup-authentication .actions-toolbar .primary,
    .popup-authentication .actions-toolbar button {
        display: block;
        width: 100%;
    }
}

@media($desktop_only) {
    .popup-authentication .block-authentication {
        flex-direction: row-reverse;
        width: 800px;
        max-width: 90vw;
    }

    .popup-authentication .block-customer-login {
        padding-right: $desktop-padding;
        border-right: 1px solid $color-border;
    }

    .popup-authentication .block-new-customer {
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding-left: $desktop-padding;
        -ms-box-orient: vertical;
    }
}

/* Agreements modal
-------------------------- */

.agreements-modal {
    .modal-header {
        height: 0;
    }

    .modal-header .action-close {
        right: 1.5em;
        background-color: #fff;
    }

    .modal-content {
        border-top: 0;
    }

    .modal-footer {
        text-align: center;
    }
}


/* three DS2 Modal
   (checkout payment)
-------------------------- */

.threeDS2Modal {

    .modal-inner-wrap {
        width: 100%;
        max-width: 600px;
    }

    .modal-content {
        max-height: none;
    }

    #threeDS2Container {
        margin-bottom: 0 !important;
    }

    .modal-header {
        height: 3em;
        line-height: 3em;
    }

    * + .modal-content {
        border-top: 3em solid transparent;
    }

    .modal-content {
        padding-top: $mobile-padding;
        padding-bottom: $mobile-padding;
    }

    .modal-footer {
        text-align: center;
    }
}

/* ----------------------------------------------------------
  Pager
---------------------------------------------------------- */

.pages-items {
    a,
    strong {
        display: block;
        padding: 0.5em 0.7em;
        border: 1px solid $color-border;
        text-align: center;
    }

    strong {
        border-color: $color-hl;
        color: $color-hl;
    }
}

/* ----------------------------------------------------------
  Loaders
---------------------------------------------------------- */

.loading-mask {
    & {
        background-color: rgba(#FFF,0.5);
    }
}

/* ----------------------------------------------------------
  Back to top
---------------------------------------------------------- */

#back-to-top {
    & {
        @extend %bexley-button;
        visibility: hidden;
        z-index: 3;
        position: fixed;
        right: $desktop-padding;
        bottom: $desktop-padding;
        width: 3em;
        height: 3em;
        padding: 0;
        min-width: 0;
        font-size: 14px!important;
        line-height: 3em;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s easee min-width;
    }

    &:before {
        @extend .icon;
        @extend .icon_down;
        z-index: 1;
        position: relative;
        top: -3px;
        margin-right: 0;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    span {
        display: none;
    }

    [data-backtotopvisible="1"] & {
        visibility: visible;
        opacity: 0.999;
    }
}

@media ($mobile_only) {
    #back-to-top {
        right: $mobile-padding/2;
        bottom: $mobile-padding/2;
        font-size: 10px!important;
    }

    #back-to-top:before {
        top: 0;
    }
}

/* ----------------------------------------------------------
  Cookies
---------------------------------------------------------- */

body.page-has-cookie-notice {
    padding-bottom: 80px;
}

@media ($mobile_only) {
    body.page-has-cookie-notice {
        padding-bottom: 250px;
    }
}

/* Core
-------------------------- */

.cookie-notice {
    display: none;
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #000;
}

.cookie-notice .cookie-notice-text {
    font: 14px Arial;
    color: #fff;
}

.cookie-notice .cookie-notice-link {
    text-decoration: underline;
    font: 14px Arial;
    color: #fff;
}

.cookie-notice .cookie-notice-link:hover {
    text-decoration: none;
}

.cookie-notice .close-cookie-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.cookie-notice .close-cookie-image:before,
.cookie-notice .close-cookie-image:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cookie-notice .close-cookie-image:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Style
-------------------------- */

.cookie-notice {
    & {
        top: auto;
        right: 0;
        bottom: 0;
        width: auto;
        padding: 15px;
        text-align: center;
        background-color: $color-hl;
    }

    .cookie-notice-text {
        z-index: 1;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0 auto;
        padding-right: 13em;
        max-width: $content_width_wide;
        min-height: 3.8em;
        text-align: left;
        font-family: $font-main;
        font-size: $font-size;
        line-height: 1.3;
        letter-spacing: 0.05em;
        color: #fff;
    }

    .close-cookie {
        @extend %bexley-button;
        @extend %bexley-button--fourth;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 2em;
        min-width: 0;
    }

    .cookie-notice-link {
        margin: 0 0.3em;
        font: inherit;
    }

    .cookie-notice-link:not(:hover) {
        color: inherit;
    }

    .close-cookie-image {
        display: none;
    }
}

@media ($mobile_only) {
    .cookie-notice .cookie-notice-text {
        padding-right: 0;
    }

    .cookie-notice .close-cookie {
        display: block;
        z-index: 1;
        position: relative;
        width: 100%;
        margin-top: 1em;
    }
}

/* ----------------------------------------------------------
  Messages
---------------------------------------------------------- */

.page-main > [data-bind*="scope"][data-bind*="messages"] {
    & {
        @extend %responsive-padding-lr;
        margin: auto;
        max-width: $content_width_wide;
    }

    body.account & {
        @extend %responsive-padding-tb;
        padding-bottom: 0;
    }
}

/* ----------------------------------------------------------
  Datepicker
---------------------------------------------------------- */

.ui-datepicker .ui-datepicker-title {
    & {
        margin: 0 2em;
    }

    .ui-datepicker-month {
        width: 45%;
    }

    .ui-datepicker-year {
        width: 49%;
    }
}

/* ----------------------------------------------------------
  Flowing button
---------------------------------------------------------- */

$flowing_button_width: 100px;
$flowing_button_mobile_width: 75px;

/* Button
-------------------------- */

.flowing-button {
    & {
        z-index: 9;
        position: fixed;
        top: 50%;
        left: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 80vw;
        margin: auto;
        margin-left: 0-$flowing_button_width;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        pointer-events: none;
    }

    .has--opened-minicart &,
    .has--opened-main-menu & {
        display: none;
    }

    &.is-visible {
        left: 20vw;
        margin-left: 0;
    }
}

@media ($mobile_only) {
    .flowing-button {
        width: 100vw;
        margin-left: 0-$flowing_button_mobile_width;
    }

    .flowing-button.is-visible {
        left: 0;
    }
}

/* Content
-------------------------- */

.flowing-button-content {
    & {
        @extend %responsive-padding;
        border: 1px solid $color-border;
        text-align: center;
        background-color: $color-bg;
        pointer-events: initial;
    }

    p {
        margin: 2em 0;
        font-size: 1.3em;
    }
}

@media ($mobile_only) {
    .flowing-button-content [class*="button"] {
        padding: 1em;
        min-width: 1em;
        line-height: 1;
        white-space: normal;
    }
}

/* Grid
-------------------------- */

.flowing-button-grid {
    & {
        display: table;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        table-layout: fixed;
    }

    & > * {
        display: table-cell;
        vertical-align: top;
    }
}

.flowing-button-cta {
    & {
        display: block;
        z-index: 1;
        position: relative;
        left: 1px;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: $flowing_button_width;
        padding: $mobile-padding/2;
        border: 1px solid $color-border;
        border-width: 1px 0 1px 1px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        background-color: $color-hl;
        cursor: pointer;
        pointer-events: initial;
    }

    &:focus,
    &:hover {
        color: #fff;
        background-color: $color-hl-light;
    }

    i[class*="icon"] {
        display: block;
        margin-bottom: 0.33em;
        font-size: 3em;
    }

    i[class*="icon"]:before {
        width: 0.6em;
    }
}

.flowing-button-grid__hidden {
    width: auto;
}

@media ($mobile_only) {
    .flowing-button-cta {
        width: $flowing_button_mobile_width;
        font-size: 10px;
    }
}

/* ----------------------------------------------------------
  Tooltips
---------------------------------------------------------- */

.bxl-tooltip {
    & {
        z-index: 1;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .tooltip-toggle {
        cursor: pointer;
    }

    .tooltip-content {
        display: none;
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 100%;
        margin-right: -0.5em;
        margin-bottom: 1em;
        padding: 0.5em;
        min-width: 100px;
        border: 1px solid $color-hl;
        text-align: left;
        font-size: 11px;
        line-height: 1.1;
        background-color: #FFF;
    }

    .tooltip-content:after {
        content: '';
        z-index: 1;
        position: absolute;
        right: 0.5em;
        bottom: 0;
        width: 1em;
        height: 1em;
        margin-bottom: -1px;
        border: inherit;
        border-width: 0 0 1px 1px;
        background-color: inherit;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: bottom right;
        transform-origin: bottom right;
    }
}

.field-tooltip #tooltip-label {
    display: none;
}

//
//  SEO friendly hidden
//  _____________________________________________

@mixin visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px; padding: 0; border: 0;
}

._hidden,
.visually-hidden {
    @include visually-hidden;
}

// lazysizes
img.lazyload:not([src]) {
    visibility: hidden;
}

.golden-border-container {
    border: 1px solid #b49770;
    padding: 15px;
    background-color: #fff;
}

.message.warning {
    color: $color-hl;
}
