@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

.product-info-main {
    & {
        position: relative;
    }

    .product-store-credit-discount,
    .cart-rule,
    .product.alert {
        display: none;
    }
}

@media($mobile_only) {
    .product-info-main {
        padding-top: 90%;
    }

    .catalog-product-view {
        .page-main {
            position: relative;
            z-index: 2;
        }
    }
}

@media($tiny_only) {
    .product-info-main {
        padding-top: 100%;
    }
}

@media screen and (max-width: 359px) {
    .catalog-product-view .product-info-main {
        padding-top: 110%;
    }
}

/* ----------------------------------------------------------
  Media
---------------------------------------------------------- */

.product.media {

    & {
        position: relative;
    }

    .action-skip-wrapper {
        display: none;
    }
}

.media .fotorama__thumb-border {
    border-color: $color-hl;
    border-width: 0 0 3px!important;
}

.media .fotorama__nav--thumbs {
    max-height: 460px;
}

.media .fotorama__stage__frame .fotorama__img {
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media($mobile_only) {
    .media .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
        display: none!important;
    }

    .fotorama__wrap.fotorama__wrap--css3.fotorama__wrap--slide .fotorama__stage__shaft {
        width: 100%!important;
        max-width: 100%!important;
    }

    .fotorama__wrap.fotorama__wrap--css3.fotorama__wrap--slide .fotorama__stage {
        left: 0!important;
    }
}

/* ----------------------------------------------------------
  Essential
---------------------------------------------------------- */

.product-info-main {
    & {
        padding-bottom: $desktop-padding;
    }

    .banner-title {
        @extend %project--title;
        text-transform  : none;
        font-size       : 20px;
        line-height     : 30px;
        letter-spacing  : normal;
    }

    .product-view-main-description {
        margin-bottom: $mobile-padding;
        font-weight: 300;
    }

    .product-view-main-description {
        div {
            display: inline;
        }

        .short-description {
            display: inline;
            margin-bottom: 0;
            font-size: 13px;
            font-weight: inherit;
        }
    }

    .product-view-main-description * {
        content: '- ';
    }

    .product-info-price .price-box {
        margin-bottom: $mobile-padding;
        font-size: 36px;
    }
}

@media($mobile_only) {
    .product-info-main .product-info-price .price-box {
        font-size: 26px;
    }

    .product-info-main {
        .banner-title {
            @extend .project--title;
            font-size       : 16px;
            line-height     : 20px;
        }
    }

}

// Payment info
.product-info-payment {
    margin-left: 0;

    img {
        height: 34px;
        width: auto;
        vertical-align: initial;
    }
}

.catalog-product-view {
    .sales-label {
        & + .product-info-payment {
            display: block;
            width: 100%;
            margin-left: 0;
            margin-top: 10px;
        }
    }
}

/* Batch sales
-------------------------- */

.product-info-main .product.batch-sale {
    & {
        margin-top          : $mobile-padding;
        margin-bottom       : $mobile-padding;
        background-color    : #DBDBDB;
        padding             : 5px;
        max-width           : 420px;
    }

    .label {
        margin-bottom: $line-height-em;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 0.1em;
    }

    .bx-parsed-marketing-condition {
        p {
            font-size      : 16px;
            font-weight    : 500;
            letter-spacing : 0;

            strong {
                color       : $color-red;
                font-size   : 22px;
                font-weight : 500;
            }
        }
    }

    // à terme, à supprimer
    .text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
    }

    // à terme, à supprimer
    .text span {
        font-size: 22px;
    }
}


/* Colors
-------------------------- */

.product-colors-view {
    & {
        margin-top: $mobile-padding;
        margin-bottom: 0;
    }

    .label {
        margin-bottom: $line-height-em;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 0.1em;
    }

    a {
        display: inline-block;
        z-index: 1;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        vertical-align: top;
    }

    [data-loadmore="1"] {
        .hide_display_more {
            display: none;
        }
    }

    a.more {
        width               : 70px;
        padding-bottom      : 0;
        text-align          : center;
        font-size           : 82px;
        font-weight         : 300;
        line-height         : 70px;
        color               : #000;
    }

    a.more:hover {
        color: #fff;
        background-color: $color-hl;
    }

    a.more:before {
        display: none;
    }

    a img {
        width: 70px;
        height: 70px;
        max-width: 70px;
        object-fit: cover;
    }

    .color-value,
    a:before {
        visibility: hidden;
        z-index: 1;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease,visibility 0.3s ease;
        transition: opacity 0.3s ease,visibility 0.3s ease;
    }

    a:hover:before,
    a.current:before,
    a:hover .color-value,
    a.current .color-value {
        visibility: visible;
        opacity: 0.999;
    }

    a:before {
        content: '';
        top: 78px;
        height: 3px;
        background-color: $color-hl;
    }

    .color-value {
        z-index: 1;
        position: absolute;
        top: 86px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        color: $color-hl;
    }
}

@media($mobile_only) {

    .product-colors-view {
        [data-loadmore] {

            overflow: scroll;
            display: flex;

            a {
                flex-shrink: 0;

                .product-image-container {
                    width: auto;
                }
            }

            .hide_display_more {
                display: inline-block;
            }
        }

        a.more {
            display: none;
        }
    }


}

/* Seller
-------------------------- */

.product-info-main .bxl-seller-advice {
    & {
        margin-top          : $mobile-padding;
        font-weight         : 300;
        background-color    : #DBDBDB;
        padding             : 5px;
        text-align          : center;
        width               : 425px;
        box-sizing          : border-box;
    }

    p {
        display: inline;
        margin: 0;
    }

    .label {
        text-transform: uppercase;
    }
}

@media ($desktop_excluded) {
    .product-info-main .bxl-seller-advice {
        width               : 385px;
    }
}

@media ($mobile_only) {
    .product-info-main .bxl-seller-advice {
        width       : auto;
        margin-top  : 0;
    }
}

/* ----------------------------------------------------------
  Wishlist
---------------------------------------------------------- */

.product-info-main {

    .product-addto-links {
        display: none;
    }

    .action.towishlist {
        display: flex;
        align-items: center;
        font-size: 25px;
        color: $color-hl;

        &:before {
            font-size: 18px;
        }

        span {
            margin-left: 10px;
            font-size: 13px;
            text-decoration: underline;
            line-height: 17px;
            font-weight: 500;
        }
    }

    .product-options-bottom {
        z-index: 1;
        position: relative;

        &.product-options-bottom--has-wishlist {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .box-tocart {
                margin-top: 20px;

                .actions {
                    margin-bottom: 0;
                }
            }
        }

        #add-to-wishlist-container {
            display: flex;
            align-items: center;
            width: 172px;
            height: 49px;

            // Dirty fix to handle space between whishlist button and store availability
            @media screen and (max-width: 1196px) {
                margin-bottom: -20px;
            }
        }
    }

    .action.towishlist:before {
        @extend .icon;
        @extend .icon_heart;
    }

    .action.towishlist.added:before {
        @extend .icon_heart_f;
    }

    .aftercart_button {

        & {
            display         : flex;
            margin-top      : 20px;
        }

        .stock-by-shop-checkinventory {
            margin-top      : 0;
            margin-right    : 20px;
        }
    }
}

@media($mobile_only) {
    .product-info-main  {

        .product-options-bottom {
            z-index: 1;
            position: relative;

            &.product-options-bottom--has-wishlist {
                display: block;

                .box-tocart {
                    margin-right: 0;
                }
            }
        }

        .aftercart_button {
            flex-direction : column;

            #add-to-wishlist-container {
                margin-top    : 15px;
                margin-bottom : 10px;
            }
        }
    }
}

/* ----------------------------------------------------------
  Cart
---------------------------------------------------------- */

/* Loader
-------------------------- */

.product-info-main .product-add-form {
    & {
        position: relative;
    }

    .box-tocart {

        & {
            width: 430px;
        }

        .fieldset {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .action {
            &.tocart {
                height          : 55px;
                font-size       : 14px;
                line-height     : 55px;
                min-width       : 300px;
            }
        }
    }

    #product-alert-form {
        position : relative;
        padding  : 40px 20px 20px;

        [data-js-selector=close-button] {
            position : absolute;
            right    : 10px;
            top      : 10px;
            cursor   : pointer;

            &:before {
                font-family: 'icons';
            }
        }
    }
}

@media ($desktop_excluded) {
    .product-info-main .product-add-form {
        .box-tocart {
            & {
                width: 100%;
            }

            .fieldset {
                justify-content: flex-start;
            }

            .actions {
                flex-grow: 1;
            }

            .action {
                &.tocart {
                    min-width   : auto;
                }
            }
        }
    }
}

@media ($mobile_only) {
    .product-forms-container {

        &._is_sticky {
            position        : fixed;
            bottom          : 0;
            background      : #f8f8f8;
            padding         : 0 15px 10px 15px;
            left            : 0;
            right           : 0;
            z-index         : 2;
            box-shadow      : 0 0 3px 0 rgba(0,0,0,0.35);

            .box-tocart .soon-qty-updater-wrap {
                background: #fff;
            }

            #product-options-wrapper {
                margin-top: 10px;
                padding-right: 0;

                .fieldset {
                    flex-grow: 1;

                    .field.configurable {
                        margin-right: 0;
                    }

                    .control {
                        width: 100%;

                        select {
                            max-width: none;
                        }
                    }
                }

                .size-guide {
                    margin-left: 15px;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .soon-qty-updater-wrap .dec, .soon-qty-updater-wrap .inc {
        width : 35px;
    }
}

@media screen and (max-width: 320px) {
    .soon-qty-updater-wrap .dec, .soon-qty-updater-wrap .inc {
        width : 23px;
    }

    .soon-qty-updater-wrap input[type="number"] {
        width : 20px;
    }
}

@media screen and (min-width: 710px) and (max-width: 768px) {

    body:not(.page-product-giftcard) #product_addtocart_form {
        display         : flex;
        align-items     : center;

        #product-options-wrapper, .product-options-bottom {
            width: 50%;
        }
    }
}

/* Sizes
-------------------------- */

.product-info-main .product-options-wrapper {
    & {
        margin-top  : $mobile-padding;
        display     : flex;
        align-items : center;
        max-width   : 430px;
    }

    .field {
        &.configurable {
            .label {
                display: none;
            }
        }
    }



    .size-guide a {
        text-decoration: underline;
        letter-spacing: 0;
    }
}

@media($desktop_only) {
    .product-info-main .product-options-wrapper {
        .fieldset {
            flex-grow : 1;

            .control {
                width : 100%;
            }
        }

        .size-guide {
            flex-shrink : 0;
        }
    }
}

@media($mobile_only) {
    .product-info-main .product-options-wrapper {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        padding-right: $mobile-padding/2;
        padding-bottom: $mobile-padding;
    }

    .product-info-main .product-options-wrapper .size-guide {
        bottom: $mobile-padding;
        left: 0;
    }

    .product-info-main .product-options-bottom.product-options-bottom--has-wishlist .box-tocart {
        margin-top: 0;
    }
}

/* Qty & sizes
-------------------------- */

.product-options-wrapper .field.configurable,
.box-tocart .field.qty {
    & {
        margin-right: 15px;
    }

    .label,
    .control {
        display: inline-block;
        vertical-align: middle;
    }

    select {
        min-width: 290px;
    }

    .super-attribute-select[data-validate="{required:true}"]:not([aria-required="true"]) {
        opacity: 0.3;
        cursor: wait;
        pointer-events: none;
    }

    .label {
        display: none;
    }
}

@media($mobile_only) {
    .box-tocart {
        z-index: 1;
        position: relative;
    }

    .box-tocart .soon-qty-updater-wrap {
        font-size: 14px;
    }

    /*
    .product-options-wrapper + .product-options-bottom .box-tocart .field.qty {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 100%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        padding-bottom: $mobile-padding;
        padding-left: $mobile-padding/2;
    }
    */
}

@media ($desktop_excluded) {
    .product-options-wrapper .field.configurable select {
        min-width: auto;
        max-width: 200px;
    }
}

/* Store credit
-------------------------- */

.product-store-credit-discount {
    display: none!important;
}

/* Action
-------------------------- */

.box-tocart .actions {
    margin-bottom: $mobile-padding;
}

@media($mobile_only) {
    .box-tocart .actions {
        text-align: left;
    }

    .box-tocart .actions button {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        max-width: 400px;
    }
}

/* Alert
-------------------------- */

#product-alert-form {
    & {
        padding: $mobile-padding;
        background-color: $color-bg;
    }

    div[class*="control"] {
        margin-bottom: 1em;
    }

    button:not(:hover) {
        background-color: $color-main;
    }
}

/* Info TNT
-------------------------- */

.product-info-main {
    .tnt-additional-description {
        margin-top: 10px;

        span {
            // Truck icon
            &:before {
                @extend .icon;
                @extend .icon_camion;
                font-size: 15px;
                margin-right: 10px;
            }
        }
    }
}

/* ----------------------------------------------------------
  Description
---------------------------------------------------------- */

.product-data-items {
    & {
        @extend %responsive-margin-tb;
        @extend .desktop-grid-table;
        font-size: 15px;
        font-weight: 300;
    }

    .data-item > .title {
        @extend %project--h4;
        margin-bottom: 2em;
    }

    ul,
    li,
    p {
        font: inherit;
    }

    li {
        z-index: 1;
        position: relative;
        margin-bottom: 0.5em;
        padding-left: 1em;
    }

    li:before {
        content: '•';
        z-index: 1;
        position: absolute;
        top: -0.2em;
        left: 0;
        font-size: 1.5em;
        color: $color-hl;
    }
}

@media($mobile_only) {
    .product-data-items {
        font-size: 12px;
    }

    .product-data-items .data-item > .title {
        margin-bottom: 1em;
    }
}

/* ----------------------------------------------------------
  Similar
---------------------------------------------------------- */
body:not(.checkout-cart-index) {
    .block.upsell,
    .block.related {
        & {
            @extend %responsive-margin-tb;
        }

        [role="heading"] {
            @extend %project--h2;
            text-align : center;
        }

        [role="label"] {
            @extend %bexley-subtitle-lvl2;
            margin-bottom : 0;
        }

        .block-content {
            margin-top : 2em;
        }
    }
}

body.checkout-cart-index {
    .block.upsell,
    .block.related {
        & {
            @extend %responsive-margin-tb;
        }

        [role="heading"] {
            letter-spacing : .05em;
            text-transform : uppercase;
            text-align     : left;
            margin-bottom  : 10px;
            font-size      : 20px;
            font-weight    : 700;
        }

        [role="label"] {
            margin-bottom : 0;
        }

        .block-content {
            margin-top : 1rem;
        }
    }
}

/* ----------------------------------------------------------
  Delivery and returns
---------------------------------------------------------- */

.delivery-and-returns {
    padding-left  : 20px;
    padding-right : 20px;

    & {
        @extend %responsive-padding-tb;
        text-align: center;
        background-color: $color-bg;
        box-shadow: 20vw 0 0 0 $color-bg,-20vw 0 0 0 $color-bg;
        margin-bottom: 40px;
    }

    & > :first-child {
        @extend %responsive-margin-tb;
        margin-top: 0;
    }

    .content {
        display: inline-block;
        z-index: 1;
        position: relative;
        padding-left: 70px;
        text-align: left;
        vertical-align: top;
    }

    .content p {
        line-height: 1.8;
    }

    .content img:first-child {
        z-index: 1;
        position: absolute;
        top: 0.3em;
        left: 0;
    }
}

/* ----------------------------------------------------------
  Card
---------------------------------------------------------- */

/* Reset
-------------------------- */

.page-product-giftcard .product-info-tnt_express-wrapper,
#product_addtocart_form label[for="aw_gc_amount"] {
    display: none;
}

/* Popin
-------------------------- */

.custom-modal-giftcard .modal-inner-wrap {
    & {
        width: 450px;
        max-width: 80vw;
    }

    .modal-content {
        padding-top: 0;
        border-bottom: 0;
    }

    .modal-footer {
        display: none;
    }
}

/* Preview
-------------------------- */

.preview-giftcard table {
    tr + tr th,
    tr + tr td {
        padding-top: 5px;
        border-top: 1px solid $color-border;
    }

    th,
    td {
        padding-bottom: 5px;
    }

    th {
        text-align: left;
    }

    td {
        text-align: right;
        font-weight: bold;
    }
}

#bxl-preview-giftcard-title:not(:empty) {
    margin-top: 1em;
}

/* ----------------------------------------------------------
  Stock Modal
---------------------------------------------------------- */

.modal-popup.modal-stock-by-shop:not(._show) {
    #stores-map {
        display: none;
    }
}

/* ----------------------------------------------------------
  Tabs product details
---------------------------------------------------------- */

.product_argu {
    &__container {
        margin-top: 40px;

        .content {
            margin-top: 42px;
        }

        .item.content:nth-child(1n+3) {
            display: none;
        }
    }

    &__tabs {
        display         : inline-flex;

        .title {
            &:last-child {
                h3 {
                    &:before {
                        right: 45px;
                    }
                }
            }
        }

        h3 {
            font-size       : 17px;
            letter-spacing  : 0.05em;
            color           : #848484;
            font-weight     : 400;
            text-transform  : uppercase;
            padding         : 10px 0 ;
            margin          : 0;
            padding-right   : 45px;
            position        : relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 2px;
                background: #848484;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .item {
            &:last-child {
                h3 {
                    margin-right: 0;
                }
            }
        }

        .active {
            h3 {
                font-weight     : 800;
                color           : $color-hl;
                position        : relative;

                &:after {
                    content     : '';
                    display     : block;
                    position    : absolute;
                    height      : 4px;
                    background  : $color-hl;
                    left        : 0;
                    right       : 45px;
                    bottom      : -3px;
                }
            }
        }
    }
}

@media($mobile_only) {
    .product_argu {
        &__container {
            margin-top: 0;
            margin-bottom: 50px;

            .content {
                margin-top: 22px;
            }
        }

        &__tabs {
            max-width           : calc(100vw - 40px);
            overflow            : auto;
            overflow-y          : hidden;
            scroll-snap-type    : x mandatory;

            .title {
                &:last-child {
                    h3 {
                        &:before {
                            right: 20px;
                        }
                    }
                }
            }


            h3 {
                white-space: nowrap;
                font-size: 16px !important;
                padding-right: 20px;
                letter-spacing: normal;


                &:before {
                    bottom: 1px;
                }
            }

            .title {
                scroll-snap-align: start;
            }

            .active {
                h3 {
                    &:after {
                        bottom      : 0;
                        right       : 20px;
                    }
                }
            }
        }
    }
}

@media("max-width: 399px") {
    .product_argu {

        &__tabs {
            h3 {
                font-size       : 13px;
                padding-right    : 13px;
                letter-spacing  : 0.02em;
            }

            .active {
                h3 {
                    &:after {
                        right       : 13px;
                    }
                }
            }
        }
    }
}

@media("max-width: 361px") {
    .product_argu {

        &__tabs {
            h3 {
                padding-right    : 10px;
                letter-spacing  : -0.01em;
            }

            .active {
                h3 {
                    &:after {
                        right       : 10px;
                    }
                }
            }
        }
    }
}

/* iPhone 4 */
@media("max-width: 340px") {
    .product_argu {

        &__tabs {
            h3 {
                font-size   : 10px;
            }
        }
    }
}