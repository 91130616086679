.ab-modal-513020 {
  top        : 50% !important;
  left       : 0;
  right      : 0;
  margin     : auto;
  transform  : translateY(-50%);
  position   : fixed;
  width      : 750px;
  height     : fit-content;
  z-index    : -10;
  background : white;
  padding    : 10px;
  overflow   : hidden;

  .ab-slider {
    padding-bottom : 15px;
  }

  .slick-slide .product.photo {
    width  : 80%;
    margin : auto;
  }

  .product-items.list {
    margin : 0;

    .product-item {
      padding : 0;
    }
  }

  .header {
    background    : #f8f8f8 !important;
    width         : 110% !important;
    margin-left   : -5% !important;
    margin-top    : -20px !important;
    margin-bottom : 15px;
    padding       : 20px 0 0 !important;

    img {
      margin  : 10px auto;
      display : block;
      height  : 30px;
      width   : auto;
    }

    h3 {
      font-size      : 14px;
      margin-bottom  : 0;
      font-weight    : bold;
      padding-bottom : 15px;
    }

    h4 {
      text-transform : uppercase;
      font-family    : $font-second;
      font-weight    : 500;
      letter-spacing : .25em;
      background     : white;
      padding-top    : 20px;
    }

    h4.modal-title {
      border-top : 1px solid grey !important;
    }

    p {
      background : white
    }

    h3, h4, p {
      text-align : center;
      position   : relative;
    }

  }

  .modal-title {
    margin-bottom : 0 !important;
    font-size     : 16px;
  }

  .batch-sale-price {
    font-size : 11px;
  }

  .b-holder {
    display         : flex;
    width           : 100%;
    justify-content : center;
    margin          : 30px 0 20px;

    > button {
      padding          : 0 1em;
      text-transform   : uppercase;
      font-size        : 12px;
      font-weight      : 500;
      line-height      : 4.16;
      letter-spacing   : .15em;
      color            : #fff;
      background-color : $color-hl;
      transition       : all .3s ease;
      border-color     : $color-hl;
      min-width        : 20em;
      border           : 0;
    }
  }

  .product-item-name {
    display : none !important;
  }

  .product-item-link {
    font-size : 0 !important;
  }

  .price-box {
    margin-top    : -10px !important;
    margin-bottom : 10px !important;
  }

  .product.details.product-item-details {
    width        : 80%;
    display      : block;
    margin       : 10px auto 0;
    padding-left : 10px;
  }

  .bexley-button {
    line-height   : 3;
    margin        : 0 auto;
    min-width     : unset;
    display       : none !important;
    width         : 180px;
    margin-bottom : 15px;
  }

  .slick-arrow {
    position   : absolute;
    height     : 300px;
    display    : block;
    top        : 0;
    z-index    : 10;
    background : none;
    border     : none;
    font-size  : 30px;
    padding    : 0px;
    color      : $color-hl;
  }

  .slick-next {
    right : 0;
  }

  button:focus {
    outline : 0;
  }

  .continue-shopping {
    background : white !important;
    border     : $color-hl 1px solid !important;
    color      : $color-hl !important;
  }

  .panier {
    margin-left : 25px
  }

  .ab-modal-exit {
    position : absolute;
    top      : 15px;
    right    : 15px;
    height   : 25px;

    &:hover {
      cursor : pointer;
    }
  }
}

.ab-modal-bg {
  position   : fixed;
  display    : none;
  top        : 0;
  left       : 0;
  right      : 0;
  bottom     : 0;
  background : RGB(0, 0, 0);
  opacity    : .5;
  z-index    : 7
}

.ab-slider {
  .slick-next,
  .slick-prev {
    font-size : 0;

    &:after {
      font-size : 30px
    }
  }

  .slick-next:after {
    content : ">";
  }

  .slick-prev:after {
    content : "<";
  }

  .product-colors {
    display : flex
  }
}

@media only screen and (max-width : 700px) {
  .ab-modal-513020 {
    width : 90%;

    .b-holder {
      display        : flex;
      flex-direction : column;
      margin         : 0 !important;

      > button {
        margin : 10px 5px;
      }
    }

    .slick-slide .product.photo {
      width  : 80%;
      margin : auto;

      img {
        width  : auto;
        height : 150px;
        margin : 0 auto;
      }
    }
  }
}

@media screen and (device-aspect-ratio : 40/71) {
  .ab-modal-513020 {
    .b-holder {
      margin         : 5px 0;
      flex-direction : column;

      > button {
        margin : 5px 5px
      }
    }

    .ab-slider {
      button {
        margin : 5px 5px;
        height : 100% !important
      }

      .sales-label {
        font-size : 0.5em
      }

      &.product-items .product-item .product-colors {
        margin-top : 10px;

        a {
          width : 6%;
        }
      }
    }

    .header {
      img {
        margin : 0 auto
      }

      > h3 {
        margin        : 0 !important;
        border-bottom : 1px solid grey !important;
        padding       : 5px 10px 5px;
        font-size     : 12px;
      }
    }

    .slick-slide .product.photo img {
      width  : auto;
      height : 100px
    }
  }
}

.ab-slider .batch-sale-price span {
  font-size   : 15px;
  font-weight : bold
}
