@charset "UTF-8";

/* ----------------------------------------------------------
  Product view
---------------------------------------------------------- */

.catalog-product-view .column.main:after {
    @extend .clearfix !optional;
}

@media($mobile_excluded) {
    .catalog-product-view .product.media,
    .catalog-product-view .product-info-main {
        float: right;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .catalog-product-view .product.media {
        width: 60%;
        padding-right: $desktop-padding/2;
    }

    .catalog-product-view .product-info-main {
        width: 40%;
        padding-left: $desktop-padding/2;
    }

    .catalog-product-view .product.media + * {
        clear: both;
    }
}

@media($desktop_only) {
    .catalog-product-view .product.media {
        padding-right: $tablet-padding/2;
    }

    .catalog-product-view .product-info-main {
        padding-left: $tablet-padding/2;
    }
}

@media($mobile_only) {
    .catalog-product-view .column.main {
        z-index: 1;
        position: relative;
    }

    .product-info-main {
        padding-top: 100%;
    }

    .product-info-main + .product.media {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
