@charset "UTF-8";

/* ----------------------------------------------------------
  Page print
---------------------------------------------------------- */

.page-print {
    .nav-toggle {
        display: none;
    }

    a.logo {
        display: block;
        padding-bottom: $mobile-padding;
        text-align: center;
    }

    a.logo:after {
        display: none;
    }

    #tarteaucitronRoot {
        display: none;
    }

    .table-wrapper {
        margin-bottom: $desktop-padding;
    }
}
