@charset "UTF-8";

/* ----------------------------------------------------------
  Header
---------------------------------------------------------- */

.bexley-header-wrapper {
    & {
        z-index: 3;
    }
    /*
    @include resp($has_desktop_menu) {
        height: 220px;
    }*/
}

.bexley-header {
    @extend %responsive-centered-container;
    z-index: 4;
    /*top: 0;
    right: 0;
    left: 0;*/
    background-color: #fff;
}

@media($has_mobile_menu) {
    body {
        padding-top: $mobile_menu_height;
    }

    .bexley-header-wrapper {
        z-index: 5;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: $mobile_menu_height!important;
        box-shadow: 0 4px 0 0 #F8F8F6;
    }
}

@media($has_desktop_menu) {
    .bexley-header {
        z-index: 3;
        /*position: absolute;*/
        border-bottom: 1px solid $color-bg;

        &-wrapper {
            height: 240px;
        }
    }

    .has-floating-menu-possible:not(.soon-storelocator-store-index) .bexley-header {
        position: fixed;
        opacity: 0;
        -webkit-transform: translate3d(0,-50px,0);
        transform: translate3d(0,-50px,0);
        pointer-events: none;
    }

    .has-floating-menu-possible.has-floating-menu:not(.soon-storelocator-store-index)  .bexley-header {
        opacity: 0.999;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        pointer-events: auto;
        top: 0;
    }
}

/* ----------------------------------------------------------
  Top Header
---------------------------------------------------------- */

.page-header {
    position : relative;
    z-index  : 10;

    > .panel.wrapper {
        & {
            @extend %responsive-centered-container;
            @extend %responsive-padding-lr-inv;
            width: auto;
            background-color: $color-hl;
        }

        .panel.header {
            @extend %responsive-centered-container-child;
            z-index: 1;
            position: relative;
            padding-right: 100px;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 45px;
            color: #fff;

            a {
                color : #fff;

                &:hover {
                    color: $color-main;
                }

                &:not(:hover),
                &:focus {
                    color: #fff;
                }
            }
        }

        ul,
        li {
            font: inherit;
        }

        .header.links {
            @extend %justify-items;

            li {
                display        : inline-block;
                vertical-align : top;

                &[data-aw-store-credit-block-name] {
                    display : none !important;
                }
            }
        }

        li.custom-links a,
        li.contact a,
        li.storelocator a {
            z-index: 1;
            position: relative;
            padding-left: 2em;
        }

        li.custom-links i[class*="icon"],
        li.contact a:before,
        li.storelocator i[class*="icon"] {
            z-index: 1;
            position: absolute;
            top: -2px;
            left: 0;
            font-size: 1.5em;
        }

        li.contact {
            a:before {
                @extend .icon;
                @extend .icon_mail;
            }
            span {
                display: none;
            }
        }

        li.custom-links {
            a {
                padding-left : 3em;

                & + a {
                    margin-left : 3em;
                }
            }

            i[class*="icon"] {
                top: -0.1em;
                font-size: 1.8em;
            }

            i[class*="icon_time"] {
                left: 0.5em;
            }
        }

        li.faciliti {
            position: absolute;
            right: 145px;
            font-weight: normal;

            @media ($desktop_excluded) {
                display: none !important;
            }
        }
    }
}

@media($has_desktop_menu) {
    .has-floating-menu-possible:not(.soon-storelocator-store-index) .page-header > .panel.wrapper {
        display: none;
    }

    .page-header > .panel.wrapper  {
        .panel.header {
            display: flex;
            padding-right: 0;
            flex-direction: row-reverse;
        }

        .header.links {
            flex-grow: 1;
        }

        .vmb-wrapper {
            .switcher-language {
                position: static;
                margin-left: 10px;
            }
        }
    }
}

.page-header > .panel.wrapper .header.links .custom-links {
    overflow: auto;
    white-space: nowrap;
}

@media ($mobile_only) {
    .page-header > .panel.wrapper .panel.header {
        padding-right: 0;
    }

    .page-header > .panel.wrapper .header.links {
        text-align: right;
    }

    .page-header > .panel.wrapper .header.links .storelocator {
        display: none;
    }

    .page-header > .panel.wrapper .header.links .custom-links {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 30px;
        left: 0;
    }

    .page-header > .panel.wrapper .header.links .custom-links a {
        padding-left: 2.2em;
    }

    .page-header > .panel.wrapper .header.links .custom-links i[class*="icon_camion"] {
        left: -0.5em;
    }

    .page-header > .panel.wrapper .header.links .custom-links i[class*="icon_time"] {
        left: 0;
    }
}

/* Lang
-------------------------- */

.switcher-language {
    & {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
    }

    li {
        display: inline;
    }

    li + li:before {
        content: ' | ';
        margin: 0 0.3em;
    }

    li > span {
        font-weight: 500;
    }

    li > a {
        font-weight: 300;
    }
}

@media($mobile_only) {
    .page-header > .panel.wrapper .switcher-language,
    .page-header > .panel.wrapper .header.links {
        display: block;
        overflow: auto;
        font-size: 10px;
        line-height: 30px;
        white-space: nowrap;
    }
}

/* ----------------------------------------------------------
  Main
---------------------------------------------------------- */

.header.content {
    & {
        @extend %responsive-centered-container-child;
        z-index: 3;
        position: relative;
        height: 80px;
        margin-top: 35px;
        margin-bottom: 20px;
        text-align: right;
    }

    .nav-toggle {
        display: none;
    }
}

@media ($has_desktop_menu) {
    .has-floating-menu-possible:not(.soon-storelocator-store-index) .header.content {
        height: 65px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

/* Search
-------------------------- */

.header.content .block-search {
    & {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 34%;
    }

    .input-text,
    .input-text-search {
        @extend %project--field;
        display: block;
        width: 100%;
        height: 35px;
        padding: 5px 39px 3px 1em;
        border: 1px solid $color-hl;
        font-family: $font-main;
        font-size: 14px;
        font-weight: 300;
        line-height: 35px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.09);
    }

    .action.search,
    .action-search {
        @extend %cssc-button;
        background-color: $color-hl;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        width: 39px;
        height : 39px;
        padding-left: 10px;
        text-align: center;
        font-size: 15px;
        line-height: 35px;
    }

    .action.search,
    .action-search {

        span {
            display: none;
        }
    }

    .action.search,
    .action-search {

        &:before {
            @extend .icon;
            @extend .icon_search;
            z-index: 1;
            position: relative;
            top: -2px;
            color : #fff;
        }

        &:disabled {
            color: buttontext;
        }
    }
}

@media($mobile_only) {
    .header.content .block-search {
        & {
            width: 100%;
        }
    }
}

#search_mini_form {
    .search-autocomplete {
        display: none;
        text-align: left;
    }

    & > [v-if] {
        display: none;
    }
}

.header-search-trigger {
    display: none;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 40px;
    font-size: 20px;
}

@media ($has_mobile_menu) {
    .header-search-trigger {
        display: block;
    }

    .header.content .block-search {
        display: none;
        top: 30px;
        left: 0;
    }

    .has--opened-search .header.content .block-search {
        display: block;
    }
}

/* Doofinder
-------------------------- */

.df-branding {
    display: none!important;
}

.df-classic {
    font: inherit!important;
}

.df-card__title {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}

.df-card__pricing {
    & {
        text-align: left;
    }

    .df-card__price {
        font-weight: normal;
    }

    .df-card__price:first-child {
        margin-left: 0;
    }
}

/* Logo
-------------------------- */

.header.content .logo img {
    @extend .absolute-cover;
    width: auto;
    height: 80px;
}

.has-floating-menu,
.has-floating-menu-possible {
    .header.content .logo img {
        height: 65px;
    }
}

/* Side
-------------------------- */

.header.content {
    .header.links,
    .header.links li,
    .minicart-wrapper {
        display: inline-block;
        margin-bottom: 0;
        margin-left: 1em;
        font-size: inherit;
        line-height: 3;
        vertical-align: 0;
    }

    li.customer a:before {
        @extend .icon;
        @extend .icon_userthin;
        display: none;
    }

    .header.links > li > a,
    .action.showcart {
        margin-left: 0.5em;
        text-transform: uppercase;
        font-family: $font-second;
        font-weight: 600;
    }
}

.header.content .action.showcart .counter-number,
.link.wishlist .counter.qty {
    z-index: 1;
    position: absolute;
    top: -0.9em;
    left: 1em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 99px;
    text-align: center;
    font-size: 9px;
    font-weight: normal;
    line-height: 1.7;
    color: #fff;
    background-color: $color-main;
}

/* Correction bug sur FF */
.header.content .action.showcart .counter-number {
    top: 0;
    left: 0;
}

.header.content .action.showcart .counter.qty{
    position: absolute;
    top: -0.9em;
    left: 1em;
}

.header.content .header.links {
    position: relative;

    .link.wishlist {
        position: absolute;
        left: -3.5em;
    }
}

/* Wishlist
-------------------------- */

.link.wishlist {
    & {
        z-index: 1;
        position: relative;
    }

    i {
        z-index: 1;
        position: relative;
        top: -0.1em;
        font-size: 2em;
    }

    .counter.qty {
        top: 0.7em;
        left: 2.7em;
    }

    .counter.qty:empty {
        display: none;
    }
}

@media($mobile_only) {
    .link.wishlist .counter.qty {
        top: 0.3em;
        left: 2em;
    }
}

/* Minicart
-------------------------- */

.header.content .action.showcart {
    & {
        z-index: 1;
        position: relative;
        padding-left: 2.5em;
        text-transform: uppercase;
    }

    &:before {
        @extend .icon;
        @extend .icon_cart;
        z-index: 1;
        position: absolute;
        top: -0.4em;
        left: 0;
        font-size: 2em;
    }
}

/* Hide minicart until JS is ready */

.minicart-wrapper > .block-minicart {
    display: none;
}

#menu_navigation {
    z-index: 2;
}

/* Responsive
-------------------------- */

@media($desktop_excluded) {
    .header.content .action.showcart .text {
        display: none;
    }
}

@media ($has_mobile_menu) and ($desktop_excluded) {
    .header.content {
        height: 50px;
        margin-top: 15px;
        padding-bottom: 5px;
    }

    .header.content .logo img {
        bottom: auto;
        width: 200px;
        height: 40px;
    }
}

@media($has_mobile_menu) {
    .header.content .header.links li.customer a:before {
        display: inline-block;
        z-index: 1;
        position: relative;
        top: -0.2em;
        font-size: 2em;
    }

    .header.content .header.links li.customer span {
        display: none;
    }
}

@media($mobile_only) {
    .header.content {
        height: 35px;
        margin-top: 10px;
        padding-bottom: 5px;
        font-size: 10px;
    }

    .header.content .logo img {
        width: auto;
        height: 36px;
        top: -4px;
    }

    .header.content .header.links,
    .header.content .header.links li,
    .header.content .minicart-wrapper {
        margin-left: 0.5em;
    }
}

/* ----------------------------------------------------------
  Navigation
---------------------------------------------------------- */

%csscommon-navtoggle-bar {
    width: 4em;
    height: 2px;
    border-radius: 0;
}

a.nav-sections-item-switch {
    & {
        display: none;
        z-index: 10;
        position: absolute;
        top: 65px;
        left: $tablet-padding;
        width: 4em;
        height: 4em;
        color: $color-main;
    }

    span:before {
        margin-top: -8px;
    }

    span:after {
        margin-top: 8px;
    }

    @include resp($has_mobile_menu) {
        display: block;
    }

    @include resp($mobile_only) {
        top: 42px;
        left: $mobile-padding;
    }
}

.rootmenu {
    z-index: 5 !important;
    > .rootmenu-list.mobile-sub > li {

        &.faciliti {
            padding-bottom : 15px;
            font-weight    : normal;
            background     : #fff;
            margin-top     : 20px !important;

            @media($desktop_only){
                display: none !important;
            }

            > a {
                border          : 0 !important;
                color           : $color-main !important;
                text-decoration : underline;
            }

            &:hover > a {
                background: unset !important;
            }
        }

        a {
            text-transform: uppercase;
        }
    }
}

.navigation[data-action="navigation"] {
    & {
        position: static;
    }

    & > ul {
        @extend %justify-items;
        margin: auto;
        max-width: $content_width_wide;
    }

    li.level0 > a {
        text-transform: uppercase;
        font-weight: bold;
    }
}

@media($has_mobile_menu) {
    #navigation-before {
        z-index: 1;
        position: relative;
        border-right: $mobile-padding solid transparent;
        border-left: $mobile-padding solid transparent;
        text-transform: uppercase;
        line-height: 48px;
        background-color: $color-bg;
    }

    #header-storelocator-link i[class*="icon"] {
        font-size: 18px;
    }

    #navigation-before .switcher-language {
        top: 14px;
    }

    .navigation[data-action="navigation"] > ul {
        display: block;
    }

    #menu_navigation[data-action="navigation"] {
        visibility: hidden;
        z-index: 9;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        top: 75px;
        margin: auto;
        overflow: auto;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease,visibility 0.3s ease;
        transition: opacity 0.3s ease,visibility 0.3s ease;
    }

    .has--opened-main-menu #menu_navigation[data-action="navigation"] {
        visibility: visible;
        opacity: 0.999;
    }

    #menu_navigation[data-action="navigation"] li.level0 {
        padding: $mobile-padding/2 $mobile-padding;
    }
}

@media($has_desktop_menu) {
    .navigation[data-action="navigation"] li.level0:first-child > a {
        padding-left: 0;
    }

    .navigation[data-action="navigation"] li.level0:last-child > a {
        padding-right: 0;
    }

    .navigation[data-action="navigation"] li.level0 > a {
        padding-top: 10px;
        padding-bottom: 20px;
        box-shadow: inset 0 -6px 0 -3px #FFF;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .navigation[data-action="navigation"] li.level0.link-lookbook > a {
        color: $color-hl;
    }

    .navigation[data-action="navigation"] li.level0:hover > a,
    .navigation[data-action="navigation"] li.level0 > a:hover {
        color: $color-hl;
        box-shadow: inset 0 -6px 0 -3px $color-hl;
    }
}

@media($has_desktop_menu) and ($large_excluded) {
    .navigation[data-action="navigation"] li.level0 > a {
        padding-right: 0.3em;
        padding-left: 0.3em;
        font-size: 11px;
    }
}

@media($desktop_only){
    header.page-header .minicart-wrapper{
        &:hover{
            .mage-dropdown-dialog{
                display: block !important;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

/* Submenu
-------------------------- */

.sub-menu-content,
.sub-menu-content a,
.main-menu-content a {
    & {
        display: block;
        z-index: 1;
        position: relative;
        overflow: hidden;
    }

    span {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        margin: auto;
        padding: $mobile-padding;
        text-align: center;
        text-shadow: 0 0 5px rgba(#000,0.9);
        text-transform: uppercase;
        font-family: $font-second;
        font-size: 16px;
        letter-spacing: 0.1em;
        color: #fff;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media($has_desktop_menu) {
    .navigation[data-action="navigation"] li.level0 > .block-submenu {
        padding: $desktop-padding;
        border-top: 1px solid $color-bg;
        box-shadow: 0 6px 8px rgba(0,0,0,0.05);
    }

    .navigation[data-action="navigation"] .block-submenu li.level1,
    .navigation[data-action="navigation"] li.level0 > .block-submenu > * {
        display: inline-block;
        vertical-align: top;
    }

    .navigation[data-action="navigation"] li.level0 > .block-submenu > *,
    .navigation[data-action="navigation"] .block-submenu li.level1 {
        width: 50%;
        max-width: $desktop-mid/2;
        text-align: left;
    }

    .navigation[data-action="navigation"] li.level0 > .block-submenu > *:only-child {
        width: 100%;
        max-width: $desktop-mid;
    }

    .navigation[data-action="navigation"] li.level0 > .block-submenu > *:only-child > * {
        width: 25%;
        max-width: $desktop-mid/4;
    }

    .navigation[data-action="navigation"] * + li.sub-menu-image,
    .navigation[data-action="navigation"] * + li.level1 {
        padding-right: 1em;
    }

    .navigation[data-action="navigation"] .main-menu-content img {
        max-height: 220px;
    }

    .block-additional-content.has-main-menu-content.has-sub-menu-content > * {
        display: inline-block;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 50%;
        padding: 0 $mobile-padding/2;
        vertical-align: top;
    }
}

@media($has_mobile_menu) {
    .navigation[data-action="navigation"] {
        position: absolute;
        position: fixed;
        top: 79px;
        bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .navigation[data-action="navigation"] .main-menu-content,
    .navigation[data-action="navigation"] .block-additional-content {
        display: none;
    }
}

/* Sublinks
-------------------------- */

li.level0 {
    ul.level0 {
        margin-bottom: 0;
    }

    .toggle {
        @extend .buttonreset;
        z-index: 1;
        position: absolute;
        top: 8px;
        right: $mobile-padding;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    &.is-opened .toggle {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@media ($has_desktop_menu) {
    li.level0 > .toggle {
        display: none;
    }
}

@media ($has_mobile_menu) {
    li.level0 {
        z-index: 1;
        position: relative;
        border-bottom: 1px solid $color-border;
    }

    ul.level0 {
        display: none;
    }

    .is-opened > .block-submenu ul.level0 {
        display: block;
    }
}

li.level1 {
    & {
        margin-bottom: 1em;
    }

    & > a {
        display: block;
        text-transform: capitalize;
        font-family: $font-main;
        font-size: 15px;
        font-weight: 500;
    }

    @include resp($has_mobile_menu) {
        margin-top: 1em;
        margin-bottom: 0;
        padding-left: 1em;
    }
}

@media ($has_mobile_menu) {
    li.level1 > a {
        font-size: 15px;
    }

    li.level2 > a {
        font-size: 13px;
    }
}

li.level2 {
    & {
        margin-top: 1em;
    }

    @include resp($has_mobile_menu) {
        margin-top: 0.5em;
        padding-left: 1em;
    }

    & > a {
        font-weight: 300;
    }

    &.active > a {
        font-weight: bold;
        color: $color-hl;
    }
}


.header.content .frznocache {
    display: inline-block;
}
