@charset "UTF-8";

/* ----------------------------------------------------------
  Layout
---------------------------------------------------------- */

/* Header
-------------------------- */

.custom-modal-arrival .modal-header {
    text-align: center;
}

/* Global
-------------------------- */

#arrival-popin {
    & {
        @extend %responsive-padding-tb-inv;
        width: 800px;
        margin-bottom: 0;
        max-width: 100%;
    }

    p a {
        text-decoration: underline;
    }
}

/* ----------------------------------------------------------
  Content
---------------------------------------------------------- */

/* Top
-------------------------- */

.arrival-top-content {
    & {
        font-weight: 300;
    }

    img:only-child {
        display: block;
        width: 100%;
        margin-bottom: $fieldset-margin;
    }

    .cadeau {
        margin-top: $fieldset-margin;
        font-size: 1.2em;
        color: $color-hl;
    }

    .inscription,
    .cadeau {
        margin-bottom: $fieldset-margin;
        text-align: center;
    }

    .inscription {
        font-weight: bold;
    }
}

/* Bottom
-------------------------- */

.arrival-bottom-content {
    p {
        font-size: 12px;
        letter-spacing: 0;
    }
}

/* ----------------------------------------------------------
  Form
---------------------------------------------------------- */

/* Layout
-------------------------- */

.arrival-form {
    @extend %responsive-padding;
    background-color: $color-bg;
}

.arrival-form__content {
    margin: auto;
    max-width: 520px;
}

@media($mobile_only) {
    .arrival-form {
        margin-right: $mobile-padding-neg;
        margin-left: $mobile-padding-neg;
    }
}

/* Buttons
-------------------------- */

.arrival-buttons {
    padding: $fieldset-margin 0;
    text-align: center;
}

.arrival-buttons button {
    width: 100%;
    margin: auto;
    max-width: 220px;
    min-width: 100px;
}
